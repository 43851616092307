import { Spinner, SpinnerSize, Stack, Text } from '@fluentui/react'
import { useState } from 'react'
import { useStyles } from '../assets/styles'
import { Icons, IconsView } from '../shared/utils/icons'

interface IProps {
    icon: Icons,
    text: string,
    isExporting: boolean,
    onClick: () => void,
    iconTint?: string
}
const ExportRow = (props: IProps) => {

    const styles = useStyles()
    const [hover, setHover] = useState<boolean>(false)

    const onHover = () => {
        setHover(true)
    }

    const onHoverLeave = () => {
        setHover(false)
    }

    const onClick = () => {
        props.onClick()
    }

    return (
        <Stack>
            <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
            <Stack horizontal verticalAlign={'center'} style={{backgroundColor: hover ? styles.prColors.cellSeperatorColor.color() : styles.prColors.menuBackgroundColor.color(), cursor: 'pointer'}} onMouseLeave={onHoverLeave} onMouseOver={onHover} onClick={onClick}>
                <Stack style={{width: 48, height: 48, marginLeft: 0, marginTop: 3}} verticalAlign={'center'} horizontalAlign={'center'}>
                    <div style={{position: 'relative'}}>
                        {IconsView.iconView(props.icon, props.iconTint)}
                    </div>
                </Stack>
                <Text style={{color: styles.prColors.textColor.color(), fontSize: '16px', marginLeft: 16}}>{props.text}</Text>
                <Spinner size={SpinnerSize.medium} style={{marginLeft: 16, display: props.isExporting ? 'block' : 'none'}}/>
            </Stack>
            <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
        </Stack>
    )
}
export default ExportRow