import React from 'react'
import {IconButton, IContextualMenuProps} from '@fluentui/react'
import { Styles } from '../../assets/styles'
import { Mode, useLightSwitch } from 'use-light-switch'
import { ReactComponent as DocumentAdd24Regular } from './../../assets/icons/ic_fluent_document_add_24_regular.svg'
import { ReactComponent as Add24Filled } from './../../assets/icons/ic_fluent_add_24_filled.svg'
import { ReactComponent as ImageAdd24Regular } from './../../assets/icons/ic_fluent_image_add_24_regular.svg'
import { ReactComponent as MoreVertical24Filled } from './../../assets/icons/ic_fluent_more_vertical_24_filled.svg'
import { ReactComponent as Edit24Regular } from './../../assets/icons/ic_fluent_edit_24_regular.svg'
import { ReactComponent as Delete24Regular } from './../../assets/icons/ic_fluent_delete_24_regular.svg'
import { ReactComponent as Clipboard24Regular } from './../../assets/icons/ic_fluent_clipboard_24_regular.svg'
import { ReactComponent as ArrowDownload24Regular } from './../../assets/icons/ic_fluent_arrow_download_24_regular.svg'
import { ReactComponent as AddCircle24Regular } from './../../assets/icons/ic_fluent_add_circle_24_regular.svg'
import { ReactComponent as TextAligntLeft24Regular } from './../../assets/icons/ic_fluent_text_align_left_24_regular.svg'
import { ReactComponent as Link24Regular } from './../../assets/icons/ic_fluent_link_24_regular.svg'
import { ReactComponent as Image24Regular } from './../../assets/icons/ic_fluent_image_24_regular.svg'
import { ReactComponent as Document24Regular } from './../../assets/icons/ic_fluent_document_24_regular.svg'
import { ReactComponent as Open24Filled } from './../../assets/icons/ic_fluent_open_24_filled.svg'
import { ReactComponent as Export20Filled } from './../../assets/icons/ic_fluent_arrow_export_20_filled.svg'
import { ReactComponent as TextBulletAdd24Filled } from './../../assets/icons/ic_fluent_text_bullet_list_add_24_filled.svg'
import { ReactComponent as TextAdd24Filled } from './../../assets/icons/ic_fluent_text_add_24_filled.svg'
import { ReactComponent as Tag24Regular } from './../../assets/icons/ic_fluent_tag_24_regular.svg'
import { ReactComponent as Checkmark24Filled } from './../../assets/icons/ic_fluent_checkmark_24_filled.svg'
import { ReactComponent as Dismiss24Filled } from './../../assets/icons/ic_fluent_dismiss_24_filled.svg'
import { ReactComponent as Copy24Regular } from './../../assets/icons/ic_fluent_copy_24_regular.svg'
import { ReactComponent as Save24Regular } from './../../assets/icons/ic_fluent_save_24_regular.svg'
import { ReactComponent as CheckmarkCircle24Regular } from './../../assets/icons/ic_fluent_checkmark_circle_24_regular.svg'
import { ReactComponent as Send24Filled } from './../../assets/icons/ic_fluent_send_24_filled.svg'
import { ReactComponent as Chat24Regular } from './../../assets/icons/ic_fluent_chat_24_regular.svg'
import { ReactComponent as Color24Filled } from './../../assets/icons/ic_fluent_color_24_filled.svg'
import { ReactComponent as DocumentPdf24Regular } from './../../assets/icons/ic_fluent_document_pdf_24_regular.svg'
import { ReactComponent as MusicNote24Regular } from './../../assets/icons/ic_fluent_music_note_24_regular.svg'
import { ReactComponent as VideoClip24Regular } from './../../assets/icons/ic_fluent_video_clip_24_regular.svg'
import { ReactComponent as DataTreeMap24Regular } from './../../assets/icons/ic_fluent_data_treemap_24_regular.svg'
import { ReactComponent as TextBulletList24Regular } from './../../assets/icons/ic_fluent_text_bullet_list_24_regular.svg'
import { ReactComponent as More24Regular } from './../../assets/icons/ic_fluent_more_24_regular.svg'
import { ReactComponent as ArrowRotateLeft24Regular } from './../../assets/icons/ic_fluent_arrow_rotate_counterclockwise_24_filled.svg'
import { ReactComponent as ArrowRotateRight24Regular } from './../../assets/icons/ic_fluent_arrow_rotate_clockwise_24_filled.svg'
import { ReactComponent as Crop24Regular } from './../../assets/icons/ic_fluent_crop_24_regular.svg'
import { ReactComponent as Toolbox24Regular } from './../../assets/icons/ic_fluent_toolbox_24_regular.svg'
import { ReactComponent as EyeShow24Filled } from './../../assets/icons/ic_fluent_eye_show_24_filled.svg'
import { ReactComponent as EyeShow24Regular } from './../../assets/icons/ic_fluent_eye_show_24_regular.svg'
import { ReactComponent as EyeHide24Regular } from './../../assets/icons/ic_fluent_eye_hide_24_regular.svg'
import { ReactComponent as AlertOn24Filled } from './../../assets/icons/ic_fluent_alert_on_24_filled.svg'
import { ReactComponent as AlertSnooze24Regular } from './../../assets/icons/ic_fluent_alert_snooze_24_regular.svg'
import { ReactComponent as LinkEdit24Regular } from './../../assets/icons/ic_fluent_link_edit_24_regular.svg'
import { ReactComponent as AirplaneTakeOff24Regular } from './../../assets/icons/ic_fluent_airplane_take_off_24_regular.svg'
import { ReactComponent as Braces24Regular } from './../../assets/icons/ic_fluent_braces_24_regular.svg'
import { ReactComponent as Comma24Regular } from './../../assets/icons/ic_fluent_comma_24_regular.svg'
import { ReactComponent as Textbox24Regular } from './../../assets/icons/ic_fluent_textbox_24_regular.svg'
import { ReactComponent as FolderZip24Regular } from './../../assets/icons/ic_fluent_folder_zip_24_regular.svg'
import { ReactComponent as CopyAdd24Regular } from './../../assets/icons/ic_fluent_copy_add_24_regular.svg'
import { ReactComponent as ClipboardCheckmark24Filled } from './../../assets/icons/ic_fluent_clipboard_checkmark_24_filled.svg'
import { ReactComponent as Pin24Regular } from './../../assets/icons/ic_fluent_pin_24_regular.svg'
import { ReactComponent as Pin24Filled } from './../../assets/icons/ic_fluent_pin_24_filled.svg'
import { ReactComponent as PinOff24Regular } from './../../assets/icons/ic_fluent_pin_off_24_regular.svg'
import { ReactComponent as TextCaseTitle24Regular } from './../../assets/icons/ic_fluent_text_case_title_24_regular.svg'
import { ReactComponent as Play24Filled } from './../../assets/icons/ic_fluent_play_24_filled.svg'
import { ReactComponent as Prohibited24Filled } from './../../assets/icons/ic_fluent_prohibited_24_filled.svg'
import { ReactComponent as TwitterSvg } from './../../assets/icons/twitter_svg.svg'
import { ReactComponent as WhatsAppSvg } from './../../assets/icons/whatsapp_svg.svg'
import { IItem, ItemType } from '../stores/types'
import { useUtils } from './utils'

interface IProps {
    icon: Icons
    color?: string
    style?: React.CSSProperties,
    menuProps?: IContextualMenuProps,
    hoverColor?: string,
    needBg?: boolean,
    onClick?: () => void
}

export enum Icons {
    DocumentAdd24Regular,
    Add24Filled,
    ImageAdd24Regular,
    MoreVertical24Filled,
    Edit24Regular,
    Delete24Regular,
    Clipboard24Regular,
    ArrowDownload24Regular,
    AddCircle24Regular,
    TextAligntLeft24Regular,
    Link24Regular,
    Image24Regular,
    Document24Regular,
    Open24Filled,
    Export20Filled,
    TextBulletAdd24Filled,
    TextAdd24Filled,
    Tag24Regular,
    Checkmark24Filled,
    Dismiss24Filled,
    Copy24Regular,
    Save24Regular,
    CheckmarkCircle24Regular,
    Send24Filled,
    Chat24Regular,
    Color24Filled,
    DocumentPdf24Regular,
    MusicNote24Regular,
    VideoClip24Regular,
    DataTreeMap24Regular,
    TextBulletList24Regular,
    More24Regular,
    ArrowRotateLeft24Regular,
    ArrowRotateRight24Regular,
    Crop24Regular,
    Toolbox24Regular,
    EyeShow24Filled,
    EyeShow24Regular,
    EyeHide24Regular,
    AlertOn24Filled,
    AlertSnooze24Regular,
    LinkEdit24Regular,
    AirplaneTakeOff24Regular,
    Braces24Regular,
    Comma24Regular,
    Textbox24Regular,
    FolderZip24Regular,
    CopyAdd24Regular,
    ClipboardCheckmark24Filled,
    Pin24Regular,
    Pin24Filled,
    PinOff24Regular,
    TwitterSvg,
    WhatsAppSvg,
    TextCaseTitle24Regular,
    Play24Filled,
    Prohibited24Filled
}

export class IconsView {
    public static iconView(icon: Icons, color?: string, size?: number): JSX.Element | null {
        const fill = color != undefined ? color : new Styles(true).colors.themePrimary
        switch (icon) {
            case Icons.DocumentAdd24Regular:
                return <DocumentAdd24Regular title={''} fill={fill} />
            case Icons.Add24Filled:
                return <Add24Filled title={''} fill={fill}/>
            case Icons.ImageAdd24Regular:
                return <ImageAdd24Regular title={''} fill={fill}/>
            case Icons.MoreVertical24Filled:
                return <MoreVertical24Filled title={''} fill={fill}/>
            case Icons.Edit24Regular:
                return <Edit24Regular title={''} fill={fill}/>
            case Icons.Delete24Regular:
                return <Delete24Regular title={''} fill={fill}/>
            case Icons.Clipboard24Regular:
                return <Clipboard24Regular title={''} fill={fill}/>
            case Icons.ArrowDownload24Regular:
                return <ArrowDownload24Regular title={''} fill={fill}/>
            case Icons.AddCircle24Regular:
                return <AddCircle24Regular title={''} fill={fill}/>
            case Icons.TextAligntLeft24Regular:
                return <TextAligntLeft24Regular title={''} fill={fill}/>
            case Icons.TextCaseTitle24Regular:
                return <TextCaseTitle24Regular title={''} fill={fill} style={{width: size, height: size}}/>
            case Icons.Link24Regular:
                return <Link24Regular title={''} fill={fill} style={{width: size, height: size}}/>
            case Icons.Image24Regular:
                return <Image24Regular title={''} fill={fill} style={{width: size, height: size}}/>
            case Icons.Document24Regular:
                return <Document24Regular title={''} fill={fill} style={{width: size, height: size}}/>
            case Icons.Prohibited24Filled:
                return <Prohibited24Filled title={''} fill={fill} style={{width: size, height: size}}/>
            case Icons.Open24Filled:
                return <Open24Filled title={''} fill={fill}/>
            case Icons.Export20Filled:
                return <Export20Filled title={''} fill={fill}/>
            case Icons.TextBulletAdd24Filled:
                return <TextBulletAdd24Filled title={''} fill={fill}/>
            case Icons.TextAdd24Filled:
                return <TextAdd24Filled title={''} fill={fill}/>
            case Icons.Tag24Regular:
                return <Tag24Regular title={''} fill={fill}/>
            case Icons.Checkmark24Filled:
                return <Checkmark24Filled title={''} fill={fill}/>
            case Icons.Dismiss24Filled:
                return <Dismiss24Filled title={''} fill={fill}/>
            case Icons.Copy24Regular:
                return <Copy24Regular title={''} fill={fill}/>
            case Icons.Save24Regular:
                return <Save24Regular title={''} fill={fill}/>
            case Icons.CheckmarkCircle24Regular:
                return <CheckmarkCircle24Regular title={''} fill={fill}/>
            case Icons.Send24Filled:
                return <Send24Filled title={''} fill={fill}/>
            case Icons.Chat24Regular:
                return <Chat24Regular title={''} fill={fill}/>
            case Icons.Color24Filled:
                return <Color24Filled title={''} fill={fill}/>
            case Icons.DocumentPdf24Regular:
                return <DocumentPdf24Regular title={''} fill={fill}/>
            case Icons.MusicNote24Regular:
                return <MusicNote24Regular title={''} fill={fill}/>
            case Icons.VideoClip24Regular:
                return <VideoClip24Regular title={''} fill={fill}/>
            case Icons.DataTreeMap24Regular:
                return <DataTreeMap24Regular title={''} fill={fill}/>
            case Icons.TextBulletList24Regular:
                return <TextBulletList24Regular title={''} fill={fill}/>
            case Icons.More24Regular:
                return <More24Regular title={''} fill={fill}/>
            case Icons.ArrowRotateLeft24Regular:
                return <ArrowRotateLeft24Regular title={''} fill={fill}/>
            case Icons.ArrowRotateRight24Regular:
                return <ArrowRotateRight24Regular title={''} fill={fill}/>
            case Icons.Crop24Regular:
                return <Crop24Regular title={''} fill={fill} style={{width: 36, height: 36}}/>
            case Icons.Toolbox24Regular:
                return <Toolbox24Regular title={''} fill={fill}/>
            case Icons.EyeShow24Filled:
                return <EyeShow24Filled title={''} fill={fill}/>
            case Icons.EyeShow24Regular:
                return <EyeShow24Regular title={''} fill={fill}/>
            case Icons.EyeHide24Regular:
                return <EyeHide24Regular title={''} fill={fill}/>
            case Icons.AlertOn24Filled:
                return <AlertOn24Filled title={''} fill={fill}/>
            case Icons.AlertSnooze24Regular:
                return <AlertSnooze24Regular title={''} fill={fill}/>
            case Icons.LinkEdit24Regular:
                return <LinkEdit24Regular title={''} fill={fill}/>
            case Icons.AirplaneTakeOff24Regular:
                return <AirplaneTakeOff24Regular title={''} fill={fill}/>
            case Icons.Braces24Regular:
                return <Braces24Regular title={''} fill={fill}/>
            case Icons.Comma24Regular:
                return <Comma24Regular title={''} fill={fill}/>
            case Icons.Textbox24Regular:
                return <Textbox24Regular title={''} fill={fill}/>
            case Icons.FolderZip24Regular:
                return <FolderZip24Regular title={''} fill={fill}/>
            case Icons.CopyAdd24Regular:
                return <CopyAdd24Regular title={''} fill={fill}/>
            case Icons.ClipboardCheckmark24Filled:
                return <ClipboardCheckmark24Filled title={''} fill={fill} style={{width: 36, height: 36}}/>
            case Icons.Pin24Regular:
                return <Pin24Regular title={''} fill={fill}/>
            case Icons.PinOff24Regular:
                return <PinOff24Regular title={''} fill={fill}/>
            case Icons.Pin24Filled:
                return <Pin24Filled title={''} fill={fill} style={{width: size, height: size}}/>
            case Icons.Play24Filled:
                return <Play24Filled title={''} fill={fill} style={{width: size, height: size}}/>
            case Icons.TwitterSvg:
                return <TwitterSvg title={''} fill={fill}/>
            case Icons.WhatsAppSvg:
                return <WhatsAppSvg title={''} fill={fill}/>
            default:
                break
        }
        return null
    }

    public static getIconName(item: IItem): Icons {
        const { isValidHex } = useUtils()

        switch (item.type) {
            case ItemType.Text:
                if (isValidHex(item)) {
                    return Icons.Color24Filled
                }
                return Icons.TextCaseTitle24Regular
            case ItemType.Link:
                return Icons.Link24Regular
            case ItemType.Image:
                return Icons.Image24Regular
            case ItemType.File: {
                const split = item.content.split('.')
                if (split.length > 0) {
                    const ext = split[split.length - 1]
                    switch (ext) {
                        case 'm4a':
                            return Icons.MusicNote24Regular
                        case 'mp3':
                            return Icons.MusicNote24Regular
                        case 'mp4':
                            return Icons.VideoClip24Regular
                        case 'pdf':
                            return Icons.DocumentPdf24Regular
                        default:
                            break
                    }
                }
                return Icons.Document24Regular
            }
        }
        return Icons.TextAligntLeft24Regular
    } 
}

export const PRButton = (props: IProps) => {

    const mode = useLightSwitch()
    const useDark = mode === Mode.Dark
    const styles = new Styles(useDark)

    const getIcon = () => {
        return IconsView.iconView(props.icon, props.color)
    }

    const onClickAction = () => {
        if (props.onClick != undefined) {
            props.onClick()
        }
    }

    return (
        <IconButton 
            title={''}
            style={props.style}
            onClick={onClickAction}
            menuIconProps={props.menuProps != undefined ? {iconName: ''} : undefined} 
            menuProps={props.menuProps}
            styles={{
                root: {backgroundColor: props.needBg != undefined && props.needBg ? styles.prColors.menuBackgroundColor.color() : '#00000000'},
                rootHovered: {backgroundColor: props.hoverColor != undefined ? props.hoverColor : styles.prColors.cellHoverColor.color()}, 
                rootExpanded: {backgroundColor: '#00000000'},
                rootPressed: {backgroundColor: '#00000000'}
                }}>
            {getIcon()}
        </IconButton>
    )
}