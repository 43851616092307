import moment from 'moment';
import { useUtils } from '../utils/utils';
import { IUser, ItemType, IItem, IFeedback, IGroup, ITag } from '../stores/types';

export const useModelFactory = () => {
    const { currentTimestamp, fireId } = useUtils()

    const createUser = (uid: string, email: string): IUser => {
        return {
            uid,
            email,
            lastLogin: currentTimestamp()
        }
    }

    const createGroup = (owner: string, name: string): IGroup => {
        return {
            uid: fireId(),
            name,
            owner,
            created: currentTimestamp(),
            updated: currentTimestamp()
        }
    }
     
    const createItem = (owner: string, content: string, type: ItemType): IItem => {
        return {
            uid: fireId(),
            owner,
            type: type,
            content,
            tags: [],
            created: currentTimestamp(),
            updated: currentTimestamp()
        }
    }

    const createTag = (owner: string, name: string, color: string): ITag => {
        return {
            uid: fireId(),
            name,
            owner,
            color,
            created: currentTimestamp(),
            updated: currentTimestamp()
        }
    }

    const createFeedback = (email: string, name: string, message: string, userId: string, premium: boolean): IFeedback => {
        return {
            uid: fireId(),
            email,
            name,
            message,
            date: moment(new Date(currentTimestamp() * 1000)).format('MMMM D, yyyy h:mm a'),
            created: currentTimestamp(),
            type: 'web',
            userId,
            premium
        }
    }

    return { createUser, createGroup, createItem, createTag, createFeedback }
}