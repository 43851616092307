import { Panel, Stack, PanelType } from '@fluentui/react'
import { PRButton, Icons } from '../shared/utils/icons'
import { useStyles } from '../assets/styles'
import MoreRow from '../components/moreRow'
import { TranslateFunction } from '../shared/utils/localization'
import { ReactNode } from 'react'
import { IItem, ItemType } from '../shared/stores/types'

interface IProps {
    isOpen: boolean,
    item: IItem | null,
    onMoreClick?: (item: IItem, more: PRMore) => void,
    onDismiss: () => void
}

export enum PRMore {
    Copy,
    Edit,
    Tag,
    Delete,
    OpenLink,
    View,
    Download,
    CopyMulti,
    TextTranform,
    ShortenLink,
    ShareTwitter,
    ShareWhatsApp,
    PinToTop,
    Unpin
}

export class MoreUtils {
    static image(more: PRMore): Icons {
        switch (more) {
            case PRMore.Copy:
                return Icons.Copy24Regular
            case PRMore.CopyMulti:
                return Icons.CopyAdd24Regular
            case PRMore.Tag:
                return Icons.Tag24Regular
            case PRMore.Delete:
                return Icons.Delete24Regular
            case PRMore.OpenLink:
                return Icons.Open24Filled
            case PRMore.View:
                return Icons.Open24Filled
            case PRMore.Download:
                return Icons.ArrowDownload24Regular
            case PRMore.Edit:
                return Icons.Edit24Regular
            case PRMore.TextTranform:
                return Icons.Edit24Regular
            case PRMore.ShortenLink:
                return Icons.LinkEdit24Regular
            case PRMore.ShareTwitter:
                return Icons.TwitterSvg
            case PRMore.ShareWhatsApp:
                return Icons.WhatsAppSvg
            case PRMore.PinToTop:
                return Icons.Pin24Regular
            case PRMore.Unpin:
                return Icons.PinOff24Regular
        }
    } 

    static text(more: PRMore, isPremium: boolean, t: TranslateFunction): string {
        const extra = isPremium ? '' : ' 💎'
        switch (more) {
            case PRMore.Copy:
                return t('menu_popup_copy')
            case PRMore.CopyMulti:
                return t('menu_popup_copy_multiple')
            case PRMore.Tag:
                return t('menu_popup_tag')
            case PRMore.Delete:
                return t('menu_popup_delete')
            case PRMore.OpenLink:
                return t('menu_popup_open_link')
            case PRMore.View:
                return t('menu_popup_view_image')
            case PRMore.Download:
                return t('menu_popup_save_file')
            case PRMore.Edit:
                return t('menu_popup_edit')
            case PRMore.TextTranform:
                return t('menu_popup_text_transform') + extra
            case PRMore.ShortenLink:
                return t('menu_popup_shorten_link') + extra
            case PRMore.ShareTwitter:
                return t('share_to_twitter')
            case PRMore.ShareWhatsApp:
                return t('share_to_whatsapp')
            case PRMore.PinToTop:
                return t('menu_popup_pin')
            case PRMore.Unpin:
                return t('menu_popup_unpin')
        }
    }

    static moreList(item: IItem): PRMore[] {
        const dynamicPin = item.pinned == true ? PRMore.Unpin : PRMore.PinToTop
        switch (item.type) {
            case ItemType.Text:
                return [PRMore.Copy, PRMore.CopyMulti, PRMore.Edit, PRMore.Tag, dynamicPin, PRMore.Delete]
            case ItemType.Link:
                return [PRMore.OpenLink, PRMore.CopyMulti, PRMore.Copy, PRMore.Tag, dynamicPin, PRMore.ShortenLink, PRMore.Delete]
            case ItemType.Image:
                return [PRMore.View, PRMore.Download, PRMore.Copy, PRMore.Tag, dynamicPin, PRMore.Delete]
            case ItemType.File:
                return [PRMore.Download, PRMore.Copy, PRMore.Tag, dynamicPin, PRMore.Delete]
            default:
                return []
        }
    }

    static moreShareList(type: ItemType): PRMore[] {
        switch (type) {
            case ItemType.Text:
            case ItemType.Link:
                return [PRMore.ShareTwitter, PRMore.ShareWhatsApp]
            default:
                return []
        }
    }
}

const MorePanel = (props: IProps) => {

    const styles = useStyles()

    const onClick = (more: PRMore) => {
        const item = props.item
        if (item != null && props.onMoreClick != null) {
            props.onDismiss()
            props.onMoreClick(item, more)
        }
    }

    const renderItems = () => {
        const item = props.item
        if (item != null) {
            const comps: ReactNode[] = []
            const list = MoreUtils.moreList(item)
            for (const more of list) {
                comps.push(
                    <MoreRow key={more} more={more} onClick={onClick}/>
                )
            }

            const comps2: ReactNode[] = []
            const list2 = MoreUtils.moreShareList(item.type)
            for (const more of list2) {
                comps2.push(
                    <MoreRow key={more} more={more} onClick={onClick}/>
                )
            }

            const height = comps2.length == 0 ? 0 : 1

            return (
                <Stack>
                    <Stack.Item>
                        <Stack>
                            {comps}
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack>
                            <div style={{marginTop: 8, marginBottom: 8, height: height, width: '100%', backgroundColor: styles.prColors.cellSeperatorColor.color()}}></div>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack>
                            {comps2}
                        </Stack>
                    </Stack.Item>
                </Stack>
            )
        }
        return null
    }

    return (
        <Panel isLightDismiss type={PanelType.custom} customWidth={'400px'} isOpen={props.isOpen} onDismiss={props.onDismiss} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.backgroundColor.color()}, main: {backgroundColor: styles.prColors.backgroundColor.color()}, content: {paddingLeft: '16px', paddingRight: '16px'}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton onClick={props.onDismiss} style={{width: 44, height: 44}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <div/>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    {renderItems()}
                </Stack.Item>
            </Stack>
        </Panel>
    )
}

export default MorePanel