import { Panel, PanelType, Stack, Text, Toggle, Image, DialogType } from '@fluentui/react'
import React, { useState, useEffect } from 'react'
import { useStyles } from '../assets/styles'
import { Icons, PRButton } from '../shared/utils/icons'
import { linkPreviewDisabled, setLinkPreviewDisabled, setSingleTapEnabled, singleTapEnabled } from '../shared/utils/settings'
import SettingsRow from '../components/settingsRow'
import twitterIcon from '../assets/twitter.png'
import facebookIcon from '../assets/facebook.png'
import PRDialog, { IDialogButtonType } from '../components/prDialog'
import { useTranslation } from 'react-i18next'
import { usePremiumDialogState, usePremiumState, useRootDisplayState } from '../shared/stores/stores'
import { RootDisplayType } from '../shared/stores/types'

interface IProps {
    isOpen: boolean,
    onDismiss: () => void
}

const SettingsPanel = (props: IProps) => {

    const { t } = useTranslation()
    const styles = useStyles()

    const [linkPreviewDisabledState, setLinkPreviewDisabledState] = useState<boolean>(false)
    const [singleTapEnabledState, setSingleTapEnabledState] = useState<boolean>(false)
    const [premiumHover, setPremiumHover] = useState<boolean>(false)
    const [hideDeleteAccountDialog, setHideDeleteAccountDialog] = useState<boolean>(true)
    const { isPremium } = usePremiumState()
    const { showPremiumDialog } = usePremiumDialogState()
    const { setRootDisplayType } = useRootDisplayState()

    useEffect(() => {
        setLinkPreviewDisabledState(linkPreviewDisabled())
        setSingleTapEnabledState(singleTapEnabled())
    }, [])

    const onCloseClick = () => {
        props.onDismiss()
    }

    const onLinkPreviewChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined) => {
        if (checked !== undefined) {
            setLinkPreviewDisabled(!checked)
            setLinkPreviewDisabledState(!checked)
        }
    }

    const onSingleTapChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined) => {
        if (checked !== undefined) {
            setSingleTapEnabled(checked)
            setSingleTapEnabledState(checked)
        }
    }

    const onTwitter = () => {
        window.open('https://www.twitter.com/dmyst_app', '_blank') 
    }

    const onFacebook = () => {
        window.open('https://www.facebook.com/dmystapp', '_blank') 
    }

    const onPremiumHover = () => {
        setPremiumHover(true)
    }

    const onPremiumHoverExit = () => {
        setPremiumHover(false)
    }

    const onPremiumClick = () => {
        showPremiumDialog('')
    }

    const closeDeleteAccountDialog = () => {
        setHideDeleteAccountDialog(true)
    }

    const deleteAccount = () => {
        props.onDismiss()
        setHideDeleteAccountDialog(false)
        setRootDisplayType(RootDisplayType.delete)
    }

    const onDelete = () => {
        setHideDeleteAccountDialog(false)
    }

    const buttonSize = 44
    const versionTextStyle = {color: styles.prColors.subTextColor.color(), marginTop: '24px', fontSize: 14, marginLeft: 24}

    const premiumTitle = isPremium ? `${t('settings_premium')} 💎` : `${t('premium_go_premium')} 💎` 
    const premiumDescription = isPremium ? t('premium_membership_active') : t('premium_tagline')

    return (
        <Panel isLightDismiss type={PanelType.customNear} customWidth={'400px'} onLightDismissClick={onCloseClick} isOpen={props.isOpen} onDismiss={onCloseClick} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.backgroundColor.color()}, main: {backgroundColor: styles.prColors.backgroundColor.color()}, content: {paddingLeft: '0px', paddingRight: '0px'}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton style={{width: buttonSize, height: buttonSize, visibility: 'hidden'}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <PRButton onClick={onCloseClick} style={{width: buttonSize, height: buttonSize, marginRight: 12}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text style={{color: styles.prColors.textColor.color(), fontSize: '14px', fontWeight: 600, marginLeft: 16, marginTop: 16}}>{t('settings_general')}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginTop: '16px', backgroundColor: styles.prColors.menuBackgroundColor.color()}}>
                        <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
                        <Stack horizontal horizontalAlign={'space-between'} style={{marginLeft: 24, marginRight: 24, marginTop: 12, marginBottom: 6}}>
                            <Text style={{color: styles.prColors.textColor.color(), fontSize: '16px'}}>{t('settings_enable_link_previews')}</Text>
                            <Toggle checked={!linkPreviewDisabledState} onChange={onLinkPreviewChange} />
                        </Stack>
                        <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
                        <Stack horizontal verticalAlign={'center'} horizontalAlign={'space-between'} style={{marginLeft: 24, marginRight: 24, marginTop: 12, marginBottom: 12}}>
                            <Stack>
                                <Text style={{color: styles.prColors.textColor.color(), fontSize: '16px'}}>{t('settings_one_click')}</Text>
                                <Text style={{color: styles.prColors.subTextColor.color(), fontSize: '14px', marginTop: 2}}>{t('settings_one_click_sub')}</Text>
                            </Stack>
                            <Toggle checked={singleTapEnabledState} onChange={onSingleTapChange} style={{marginLeft: 8}}/>
                        </Stack>
                        <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontalAlign={'center'} style={{marginTop: 24, marginBottom: 36}}>
                        <Text style={{color: styles.prColors.subTextColor.color(), fontSize: 14}}>{t('settings_no_sync_desc')}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text style={{color: styles.prColors.textColor.color(), fontSize: '14px', fontWeight: 600, marginLeft: 16, marginTop: 16}}>Premium</Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginTop: '16px', marginBottom: '32px', backgroundColor: premiumHover ? styles.prColors.cellSeperatorColor.color() : styles.prColors.menuBackgroundColor.color(), cursor: 'pointer'}} onMouseOver={onPremiumHover} onMouseLeave={onPremiumHoverExit} onClick={onPremiumClick}>
                        <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
                        <Stack style={{marginLeft: 24, marginRight: 24, marginTop: 12, marginBottom: 14}} tokens={{childrenGap: 5}}>
                            <Text style={{color: styles.prColors.textColor.color(), fontSize: '16px'}}>{premiumTitle}</Text>
                            <Text style={{color: styles.prColors.subTextColor.color(), fontSize: '14px'}}>{premiumDescription}</Text>
                        </Stack>
                        <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text style={{color: styles.prColors.textColor.color(), fontSize: '14px', fontWeight: 600, marginLeft: 16, marginTop: 16}}>{t('settings_social')}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginTop: 16}}>
                        <SettingsRow text={t('settings_follow_twitter')} onClick={onTwitter} imageComp={
                            <Image src={twitterIcon} style={{width: 24, height: 24, marginRight: '10px', filter: ''}}/>
                            } />
                        <SettingsRow text={t('settings_facebook_page')} onClick={onFacebook} imageComp={
                            <Image src={facebookIcon} style={{width: 24, height: 24, marginRight: '10px', filter: ''}}/>
                            } />
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginTop: 64}}>
                        <SettingsRow text={t('settings_delete_account')} onClick={onDelete} textColor={styles.prColors.errorColor.color()}/>
                        <Text style={versionTextStyle}>
                            {`${t('settings_version')} 1.29`}
                        </Text>
                    </Stack>
                </Stack.Item>
            </Stack>

            <PRDialog hidden={hideDeleteAccountDialog} type={DialogType.normal} title={t('settings_delete_account')} subText={t('settings_delete_desc')} onDismiss={closeDeleteAccountDialog} cancelButton={{text: t('general_cancel'), onClick: closeDeleteAccountDialog}} actionButton={{text: t('settings_delete_account'), onClick: deleteAccount, type: IDialogButtonType.Destructive}}>
            </PRDialog> 
        </Panel>
    )
}
export default SettingsPanel