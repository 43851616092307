import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/sansation_regular.ttf'
import 'normalize.css'
import { I18nextProvider } from 'react-i18next';
import i18n from './shared/utils/localization'; // Import the i18n setup
import { createRoot } from 'react-dom/client';
import { initializeIcons } from '@fluentui/react';

const cc = document.getElementById('root');
const root = createRoot(cc!); // createRoot(container!) if you use TypeScript
root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nextProvider>
);

initializeIcons()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
