import { DialogType, Stack, TextField } from '@fluentui/react'
import PRDialog from '../components/prDialog'
import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import { ToastType, useStyles } from '../assets/styles';
import { container } from 'tsyringe';
import { AuthService } from '../shared/services/authService';
import { FirestoreService } from '../shared/services/firestoreService';
import { usePremiumState, useToast } from '../shared/stores/stores';
import { useModelFactory } from '../shared/viewModel/useModelFactory';


export interface FeedbackDialogProps {
    onDismiss: () => void;
}

export const FeedbackDialog = (props: FeedbackDialogProps) => {
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const [text, setText] = useState('')
    const [name, setName] = useState('')
    const styles = useStyles()
    const authService = container.resolve(AuthService)
    const firestoreService = container.resolve(FirestoreService)
    const { createFeedback } = useModelFactory()
    const { isPremium } = usePremiumState()
    const { showToast } = useToast()

    const textDidChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (value != null) {
            setText(value)
        }
    }


    const emailDidChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (value != undefined) {
            setEmail(value)
        }
    }

    const nameDidChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (value != undefined) {
            setName(value)
        }
    }

    const sendFeedback = () => {
        props.onDismiss()
        const userId = authService.userId
        if (userId != null) {
            const feedback = createFeedback(email, name, text, userId, isPremium)
            firestoreService.sendFeedback(feedback)
            showToast({message: t('feedback_sent'), type: ToastType.Announcement})
        }
    }
    
    return (
        <PRDialog hidden={false} type={DialogType.normal} title={t('feedback_feedback')} subText='' onDismiss={props.onDismiss} cancelButton={{text: t('general_cancel'), onClick: props.onDismiss}} actionButton={{text: t('general_send'), onClick: sendFeedback}}>
            <Stack tokens={{childrenGap: '10px'}}>
                <TextField placeholder={`${t('feedback_email')} (${t('feedback_optional')})`} value={email} resizable={false} onChange={emailDidChange} styles={{field: {backgroundColor: styles.prColors.dialogBackgroundColor.color(), color: styles.prColors.textColor.color()}}} style={{fontSize: '16px', lineHeight: '20px'}}/>
                <TextField placeholder={`Name (${t('feedback_optional')})`} value={name} resizable={false} onChange={nameDidChange} styles={{field: {backgroundColor: styles.prColors.dialogBackgroundColor.color(), color: styles.prColors.textColor.color()}}} style={{fontSize: '16px', lineHeight: '20px'}}/>
                <TextField placeholder={t('feedback_placeholder')} value={text} multiline resizable={false} onChange={textDidChange} styles={{field: {backgroundColor: styles.prColors.dialogBackgroundColor.color(), color: styles.prColors.textColor.color()}}} style={{width: 300, height: '220px', padding: '8px', fontSize: '16px', lineHeight: '20px'}}/>
            </Stack>
        </PRDialog>
    )
}