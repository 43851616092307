import { DialogType } from '@fluentui/react'
import PRDialog, { IDialogButtonType } from '../components/prDialog'
import { useTranslation } from 'react-i18next'
import { useRootDisplayState } from '../shared/stores/stores'
import { RootDisplayType } from '../shared/stores/types'

export interface LogoutConfirmDialogProps {
    onDismiss: () => void
}

export const LogoutConfirmDialog = (props: LogoutConfirmDialogProps) => {
    const { t } = useTranslation()
    const { setRootDisplayType } = useRootDisplayState()
    const logout = () => {
        props.onDismiss()
        setRootDisplayType(RootDisplayType.logout)
    }
    
    return (
        <PRDialog hidden={false} type={DialogType.normal} title={t('menu_panel_logout')} subText={t('menu_panel_logout_warning')} onDismiss={props.onDismiss} cancelButton={{text: t('general_cancel'), onClick: props.onDismiss}} actionButton={{text: t('menu_panel_logout'), onClick: logout, type: IDialogButtonType.Destructive}}>
        </PRDialog> 
    )
}