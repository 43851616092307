import { Panel, Stack, Text, PanelType } from '@fluentui/react'
import { PRButton, Icons } from '../shared/utils/icons'
import { ToastType, useStyles } from '../assets/styles'
import ExportRow from '../components/exportRow'
import JSZip from 'jszip'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Toaster } from 'react-hot-toast'
import CounterView from '../components/counterView'
import { useImageCache, useItemsState, usePremiumDialogState, usePremiumState, useSelectedGroupState, useToast } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { ItemType } from '../shared/stores/types'
import { useUtils } from '../shared/utils/utils'
import { PREMIUM_ITEM_LIMIT, REGULAR_ITEM_LIMIT } from '../shared/utils/constants'
import { AnalyticsService, PREvent } from '../shared/services/analyticsService'

interface IProps {
    isOpen: boolean,
    onDismiss: () => void
    onBulkDeleteClick?: () => void
}

const ExportPanel = (props: IProps) => {

    const analyticsService = container.resolve(AnalyticsService)
    const { t } = useTranslation()
    const styles = useStyles()
    const [jsonExporting, setJsonExporting] = useState(false)
    const [csvExporting, setCsvExporting] = useState(false)
    const [plainExporting, setPlainExporting] = useState(false)
    const [fileExporting, setFileExporting] = useState(false)
    const { items } = useItemsState()
    const { selectedGroup } = useSelectedGroupState()
    const { cache } = useImageCache()
    const { showToast } = useToast()
    const { isPremium } = usePremiumState()
    const { showPremiumDialog } = usePremiumDialogState()
    const { delay } = useUtils()

    const exportObject = (): any | null => {

        const text = []
        const links = []

        const filteredItems = items.filter(item => item.type == ItemType.Text || item.type == ItemType.Link)
        for (const item of filteredItems) {
            if (item.type == ItemType.Text) {
                text.push({
                    content: item.content
                })
            } else if (item.type == ItemType.Link) {
                links.push({
                    content: item.content
                })
            }
        }

        if (text.length === 0 && links.length === 0) {
            return null
        }

        const jsonData = {
            exportedData: {
                text,
                links
            }
        }
        return jsonData
    }

    const save = (blob: Blob, fileName: string) => {
        // Create a link element
        const link = document.createElement('a');

        // Set the download attribute with the desired file name
        link.download = fileName

        // Create a URL for the Blob and set it as the link's href
        link.href = URL.createObjectURL(blob);

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);

        analyticsService.log(PREvent.export)
    }

    const onExportJson = async () => {
        if (selectedGroup == null) {
            return
        }

        const jsonData = exportObject()
        if (jsonData == null) {
            showToast({message: t('toast_nothing_export'), type: ToastType.Regular})
            return
        }

        setJsonExporting(true)
        await delay(1000)

        const jsonString = JSON.stringify(jsonData, null, 2); // Use the third parameter for indentation (optional)

        const blob = new Blob([jsonString], { type: 'application/json' });
        const fileName = `${selectedGroup.name.replaceAll(' ', '_')}.json`;
        save(blob, fileName)
        setJsonExporting(false)
    }

    const onExportCsv = async () => {
        if (selectedGroup == null) {
            return
        }

        const jsonData = exportObject()
        if (jsonData == null) {
            showToast({message: t('toast_nothing_export'), type: ToastType.Regular})
            return
        }

        setCsvExporting(true)
        await delay(1000)

        const data: string[][] = [['Type', 'Content']];

        for (const item of jsonData.exportedData.text) {
            data.push(['text', item.content]);
        }

        for (const item of jsonData.exportedData.links) {
            data.push(['link', item.content]);
        }

        const csvString: string = data
            .map((row: string[]) =>
                row
                    .map((value: string) => {
                        // Check if the value contains a comma and needs to be quoted
                        const needsQuoting = value.includes(',') || value.includes('\n') || value.includes('"');

                        // Quote the value if necessary
                        const quotedValue = needsQuoting ? `"${value.replace(/"/g, '""')}"` : value;

                        return quotedValue;
                    })
                    .join(',')
            )
            .join('\n');

        const blob = new Blob([csvString], { type: 'text/csv' });
        const fileName = `${selectedGroup.name.replaceAll(' ', '_')}.csv`;
        save(blob, fileName)
        setCsvExporting(false)
    }

    const onExportPlain = async () => {
        if (selectedGroup == null) {
            return
        }

        const jsonData = exportObject()
        if (jsonData == null) {
            showToast({message: t('toast_nothing_export'), type: ToastType.Regular})
            return
        }
        setPlainExporting(true)
        await delay(1000)

        let finalTextString = '';

        for (const item of jsonData.exportedData.text) {
            finalTextString += `${item.content}\n`;
        }

        for (const item of jsonData.exportedData.links) {
            finalTextString += `${item.content}\n`;
        }

        const blob = new Blob([finalTextString], { type: 'text/plain' });
        const fileName = `${selectedGroup.name.replaceAll(' ', '_')}.txt`;
        save(blob, fileName)
        setPlainExporting(false)
    }

    const onExportFiles = async () => {
        if (selectedGroup == null) {
            return
        }

        interface IZipFileInfo {
            downloadUrl: string,
            content: string
        }

        const fileLinks: IZipFileInfo[] = []
        const validItems = items.filter(item => item.type == ItemType.Image || item.type == ItemType.File)
        
        for (const item of validItems) {
            const foundItem = cache.find(itemImage => itemImage.uid == item.uid)
            if (foundItem != null) {
                fileLinks.push({
                    downloadUrl: foundItem.downloadUrl,
                    content: item.content
                })
            }
        }

        if (validItems.length === 0) {
            setFileExporting(false)
            showToast({message: t('toast_nothing_export'), type: ToastType.Regular})
            return
        }


        setFileExporting(true)
        await delay(1000)
        
        const fetchPromises = fileLinks.map(async fileInfo => {
            const response = await fetch(fileInfo.downloadUrl)
            const arrayBuffer = await response.arrayBuffer();
            return { name: fileInfo.content, data: arrayBuffer };
        })
        
        const fileContents = await Promise.all(fetchPromises)

        // Create a new instance of JSZip
        const zip = new JSZip();

        // Add files to the zip
        fileContents.forEach((image) => {
            zip.file(image.name, image.data, { binary: true });
          });

        // Generate the zip file
        const generatedZip = await zip.generateAsync({ type: 'blob' });
        const fileName = `${selectedGroup.name.replaceAll(' ', '_')}.zip`;
        save(generatedZip, fileName)
        setFileExporting(false)
    }

    const onBulkDelete = () => {
        if (props.onBulkDeleteClick != null) {
            props.onBulkDeleteClick()
        }
    }

    const infoText = () => {
        let text = ''
        if (isPremium) {
            text = t('limit_items_per_group', { 0: PREMIUM_ITEM_LIMIT })
        } else {
            text = t('limit_items_per_group', { 0: REGULAR_ITEM_LIMIT })
            text += '\n'
            text += t('limit_suggest_premium', { 0: PREMIUM_ITEM_LIMIT })
        }
        text += '\n\n'
        text += t('limit_export_delete_suggest')
        return text
    }

    const onPremiumClick = () => {
        showPremiumDialog(t('premium_tagline'))
    }

    return (
        <Panel isLightDismiss type={PanelType.custom} customWidth={'400px'} isOpen={props.isOpen} onDismiss={props.onDismiss} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.backgroundColor.color()}, main: {backgroundColor: styles.prColors.backgroundColor.color()}, content: {paddingLeft: 0, paddingRight: 0}}}>
            <Toaster position={'bottom-center'} toastOptions={{style: {marginBottom: '80px', minWidth: '320px', minHeight: '44px'}}} />
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton onClick={props.onDismiss} style={{width: 44, height: 44, marginLeft: 8, marginBottom: 16}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <div/>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontalAlign={'center'} style={{marginBottom: 36}}>
                        <div style={{position: 'relative', cursor: isPremium ? undefined : 'pointer'}} onClick={isPremium ? undefined : onPremiumClick}>
                            <CounterView itemCount={items.length} fontSize={48} isDialog={true}  />
                            <Text style={{position: 'absolute', fontSize: 32, right: -12, top: -12, color: '#ff0000', display: isPremium ? 'none': 'block'}}>💎</Text>
                        </div>
                        <Text style={{fontSize: 16, marginTop: 16, marginLeft: 16, marginRight: 16, textAlign: 'center', color: styles.prColors.textColor.color(), whiteSpace: 'pre-wrap'}}>{infoText()}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text style={{color: styles.prColors.textColor.color(), fontSize: '14px', fontWeight: 600, marginLeft: 16, marginTop: 16}}>{t('export_clean_up')}</Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginTop: 16, marginBottom: 36}}>
                        <ExportRow icon={Icons.Delete24Regular} iconTint={styles.prColors.errorColor.color()} text={t('export_bulk_delete')} onClick={onBulkDelete} isExporting={false} />
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text style={{color: styles.prColors.textColor.color(), fontSize: '14px', fontWeight: 600, marginLeft: 16, marginTop: 16}}><Trans i18nKey={'export_export_text_link'} values={{0: items.filter(i => i.type === ItemType.Text || i.type === ItemType.Link).length}}></Trans></Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginTop: 16, marginBottom: 36}}>
                        <ExportRow icon={Icons.Braces24Regular} text={'JSON'} onClick={onExportJson} isExporting={jsonExporting}/>
                        <ExportRow icon={Icons.Comma24Regular} text={'CSV'} onClick={onExportCsv} isExporting={csvExporting}/>
                        <ExportRow icon={Icons.Textbox24Regular} text={'Plain Text'} onClick={onExportPlain} isExporting={plainExporting}/>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Text style={{color: styles.prColors.textColor.color(), fontSize: '14px', fontWeight: 600, marginLeft: 16, marginTop: 16}}><Trans i18nKey={'export_export_images_files'} values={{0: items.filter(i => i.type === ItemType.Image || i.type === ItemType.File).length}}></Trans></Text>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginTop: 16}}>
                        <ExportRow icon={Icons.FolderZip24Regular} text={'Zip'} onClick={onExportFiles} isExporting={fileExporting}/>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Panel>
    )
}

export default ExportPanel