import { Panel, Stack, PanelType, List, DialogType} from '@fluentui/react'
import { PRButton, Icons } from '../shared/utils/icons'
import { ToastType, useStyles } from '../assets/styles'
import { ReactNode, useEffect, useState } from 'react'
import ItemRow from '../components/itemRow'
import React from 'react'
import { t } from 'i18next'
import PRDialog, { IDialogButtonType } from '../components/prDialog'
import { IImageCache, useImageCache, useItemsState, useToast } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { IItem, ItemType } from '../shared/stores/types'
import { AnalyticsService, PREvent } from '../shared/services/analyticsService'
import { FirestoreService } from '../shared/services/firestoreService'

interface IProps {
    isOpen: boolean,
    startingItem: IItem | null,
    onDismiss: () => void
}

const MultiCopyPanel = (props: IProps) => {

    const styles = useStyles()
    const { items: stateItems } = useItemsState()
    const { cache: imageCache } = useImageCache()
    const { showToast } = useToast()
    const [selectedItems, setSelectedItems] = useState<IItem[]>([])
    const [showConfirm, setShowConfirm] = useState(false)
    const deleteMode = props.startingItem == null
    const items = deleteMode ? stateItems : stateItems.filter(item => item.type === ItemType.Text || item.type === ItemType.Link)
    const firestoreService = container.resolve(FirestoreService)
    const analyticsService = container.resolve(AnalyticsService)
    const listRef = React.createRef<List>()

    useEffect(() => {
        if (props.startingItem != null) {
            setSelectedItems([props.startingItem])
        } else {
            setSelectedItems([])
        }
    }, [props.isOpen])

    const onRenderItemCell = (item?: IItem): ReactNode => {
        if (item !== undefined) {
            let imageItem: IImageCache | undefined = undefined
            const filtered = imageCache.filter( imageItem => imageItem.uid === item.uid)
            if (filtered.length > 0) {
                imageItem = filtered[0]
            }
            return (
                <ItemRow item={item} itemImage={imageItem} onClick={onItemClick} selectedBgColor={deleteMode ? styles.prColors.selectedDeleteBgColor.color() : styles.prColors.selectedBgColor.color()} selectedHoverBgColor={deleteMode ? styles.prColors.selectedHoverDeleteBgColor.color() : styles.prColors.selectedHoverBgColor.color()} isSelectable={true} isSelected={selectedItems.find(i => i.uid == item.uid) != null}/>
            );
        }
        return null;
    }

    const onItemClick = (item: IItem) => {
        const index = selectedItems.indexOf(item, 0)
        if (index >= 0) {
            const newList = selectedItems.filter(i => i.uid !== item.uid)
            setSelectedItems(newList)
        } else {
            setSelectedItems([
                ...selectedItems,
                item
            ])
        }
        listRef.current?.forceUpdate()
    }

    const onCopy = () => {
        if (selectedItems.length === 0) {
            return
        }
        analyticsService.log(PREvent.multiCopy)
        let str = ''
        for (const item of selectedItems) {
            str += item.content
            str += '\n'
        }
        navigator.clipboard.writeText(str)
        showToast({message: t('toast_copied_clipboard'), type: ToastType.Announcement})
        props.onDismiss()
    }

    const onDelete = () => {
        if (selectedItems.length === 0) {
            return
        }
        setShowConfirm(true)
    }

    const onDeleteReal = () => {
        analyticsService.log(PREvent.bulkDelete)
        setShowConfirm(false)
        firestoreService.bulkDelete(selectedItems)
        props.onDismiss()
    }

    return (
        <Panel isLightDismiss type={PanelType.custom} customWidth={'400px'} isOpen={props.isOpen} onDismiss={props.onDismiss} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.backgroundColor.color()}, main: {backgroundColor: styles.prColors.backgroundColor.color()}, content: {paddingLeft: 0, paddingRight: 0}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton onClick={props.onDismiss} style={{width: 44, height: 44, marginLeft: 8, marginBottom: 16}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <div/>
                        {deleteMode ? 
                            <PRButton onClick={onDelete} style={{width: 44, height: 44, marginRight: 8, marginBottom: 16}} icon={Icons.Delete24Regular} color={styles.prColors.errorColor.color()}/> :
                            <PRButton onClick={onCopy} style={{width: 44, height: 44, marginRight: 8, marginBottom: 16}} icon={Icons.Copy24Regular} color={styles.prColors.greenColor.color()}/>
                            }
                    </Stack>
                </Stack.Item>
                    <List ref={listRef} style={{borderWidth: 0, paddingBottom: '100px'}} items={items} onRenderCell={onRenderItemCell}/>
                <Stack.Item>
                    
                </Stack.Item>
            </Stack>

            <PRDialog hidden={!showConfirm} type={DialogType.normal} title={t('bulk_delete_delete_items')} subText={t('bulk_delete_message')} onDismiss={() => setShowConfirm(false)} cancelButton={{text: t('general_cancel'), onClick: () => setShowConfirm(false)}} actionButton={{text: t('menu_popup_delete'), onClick: onDeleteReal, type: IDialogButtonType.Destructive}}>
            </PRDialog> 
        </Panel>
    )
}

export default MultiCopyPanel