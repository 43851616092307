import { Stack, Text } from '@fluentui/react'
import { ReactNode, useState } from 'react'
import { Mode, useLightSwitch } from 'use-light-switch'
import { Styles } from '../assets/styles'

interface IProps {
    text: string
    onClick: () => void,
    imageComp?: ReactNode,
    textColor?: string
}
const SettingsRow = (props: IProps) => {

    const mode = useLightSwitch()
    const useDark = mode === Mode.Dark
    const styles = new Styles(useDark)
    const [hover, setHover] = useState<boolean>(false)

    const onHover = () => {
        setHover(true)
    }

    const onHoverLeave = () => {
        setHover(false)
    }

    const onClick = () => {
        props.onClick()
    }

    const textColor = props.textColor !== undefined ? props.textColor : styles.prColors.textColor.color()

    return (
        <Stack style={{backgroundColor: hover ? styles.prColors.cellSeperatorColor.color() : styles.prColors.menuBackgroundColor.color(), cursor: 'pointer'}} 
        onMouseLeave={onHoverLeave} onMouseOver={onHover} onClick={onClick}>
            <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
            <Stack horizontal verticalAlign={'center'} style={{marginLeft: 24, marginRight: 24, marginTop: 12, marginBottom: 14}}>
                {props.imageComp !== undefined ? props.imageComp : null}
                <Text style={{color: textColor, fontSize: '16px'}}>{props.text}</Text>
            </Stack>
            <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
        </Stack>
    )
}
export default SettingsRow