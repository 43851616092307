import {container, singleton} from 'tsyringe';
import { FirebaseService } from './firebaseService';
import { AuthService } from './authService';
import { CollectionReference, DocumentData, DocumentReference, DocumentSnapshot, Firestore, QuerySnapshot, Unsubscribe, collection, deleteDoc, deleteField, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, orderBy, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { browserName } from 'react-device-detect';
import { CollectionKey, ItemType, IItem, IDevice, IQuick, IFeedback, IGroup, ITag } from '../stores/types';
import { MissingUserIdError } from '../utils/errors';
import { deviceId } from '../utils/settings';
import { useUtils } from '../utils/utils';
import i18n from 'i18next';
import { ConsoleLoggerService } from './consoleLoggerService';

@singleton()
export class FirestoreService {

    private readonly firebaseService: FirebaseService
    private readonly authService: AuthService
    private readonly firestore: Firestore
    private readonly consoleLogger: ConsoleLoggerService

    constructor() {
        this.firebaseService = container.resolve(FirebaseService)
        this.authService = container.resolve(AuthService)
        this.consoleLogger = container.resolve(ConsoleLoggerService)
        this.firestore = getFirestore(this.firebaseService.firebaseApp)
    }

    private subcollection(key: CollectionKey): CollectionReference {
        const userId = this.authService.userId
        if (userId == null) {
            throw new MissingUserIdError()
        }
        return collection(this.firestore, key, userId, 'values')
    }

    public observe<T>(key: CollectionKey, field: string, equalTo: unknown, listener: (list: T[]) => void): Unsubscribe {
        const colRef = this.subcollection(key)
        const q = query(colRef, where(field, '==', equalTo))
        return onSnapshot(q, (value: QuerySnapshot<DocumentData>) => {
            const list: T[] = []
            value.forEach((doc) => {
                const item = doc.data() as T;
                list.push(item);
            })
            listener(list)
        }, (error) => {
            this.consoleLogger.error('Observe snapshot failed', error)
        })
    }

    public observeRecent(itemType: ItemType, limitVal: number, listener: (list: IItem[]) => void): Unsubscribe {
        const colRef = this.subcollection(CollectionKey.Items)
        const q = query(colRef, where('type', '==', itemType), orderBy('created', 'desc'), limit(limitVal))
        return onSnapshot(q, (value: QuerySnapshot<DocumentData>) => {
            const list: IItem[] = []
            value.forEach((doc) => {
                const item = doc.data() as IItem;
                if (item.localDeleted != true) {
                    list.push(item);
                }
            })
            listener(list)
        }, (error) => {
            this.consoleLogger.error('Observe recent snapshot failed', error)
        })
    }

    public observeStorageSize(listener: (size: number) => void): Unsubscribe {
        const userId = this.authService.userId
        if (userId == null) {
            throw new MissingUserIdError()
        }
        const storageDoc = doc(this.firestore, CollectionKey.UserStorage, userId)
        return onSnapshot(storageDoc, (value: DocumentSnapshot<DocumentData>) => {
            if (value.data() !== undefined) {
                const storageSizeData = (value.data() as Record<string, unknown>)
                if (storageSizeData != null && 'storageSize' in storageSizeData) {
                    const storageSize = storageSizeData.storageSize
                    if (typeof storageSize === 'number') {
                        listener(storageSize)   
                    } 
                }
            }
        }, (error) => {
            this.consoleLogger.error('Observe storage size snapshot failed', error)
        })
    }

    public observePushGroup(listener: (list: string[]) => void): Unsubscribe {
        const userId = this.authService.userId
        if (userId == null) {
            throw new MissingUserIdError()
        }
        const pushGroupsRef = doc(this.firestore, CollectionKey.PushGroups, userId)
        return onSnapshot(pushGroupsRef, (value: DocumentSnapshot<DocumentData>) => {
            let groups: string[] = []
    
            if (value.data() !== undefined) {
                groups = Object.keys(value.data() as any)
            }
            listener(groups)
        }, (error) => {
            this.consoleLogger.error('Observe push group snapshot failed', error)
        })
    }

    public async doesUserExist(userId: string): Promise<boolean> {
        const docUser = doc(this.firestore, CollectionKey.Users, userId)
        const result = await getDoc(docUser)
        return result.exists()
    }

    public async add(key: CollectionKey, uid: string, data: Record<string, unknown>) {
        let ref: DocumentReference
        if (key === CollectionKey.Users || key === CollectionKey.Feedback || key === CollectionKey.PushGroups) {
            ref = doc(this.firestore, key, uid)
        }
        else {
            ref = doc(this.firestore, this.subcollection(key).path, uid)
        }
        await setDoc(ref, data)
    }

    public async addGroup(group: IGroup) {
        await this.add(CollectionKey.Groups, group.uid, group)
    }

    public async query<T>(key: CollectionKey, field: string, equalTo: unknown): Promise<T[]> {
        const colRef = this.subcollection(key)
        const q = query(colRef, where(field, '==', equalTo))
        const result = await getDocs(q)

        const array: T[] = []
        result.forEach(doc => {
            const user = doc.data() as T
            array.push(user)
        })
        return array
    }

    public async deleteGroup(group: IGroup) {
        const ref = doc(this.firestore, this.subcollection(CollectionKey.Groups).path, group.uid)
        await deleteDoc(ref)
    }

    public async addItem(item: IItem) {
        await this.add(CollectionKey.Items, item.uid, item)
    }

    public async deleteItem(item: IItem) {
        item.localDeleted = true
        await this.addItem(item)
    }

    public async deleteItemForReal(item: IItem) {
        const ref = doc(this.firestore, this.subcollection(CollectionKey.Items).path, item.uid)
        await deleteDoc(ref)
    }

    public async addTag(tag: ITag) {
        await this.add(CollectionKey.Tags, tag.uid, tag)
    }

    public async deleteTag(tag: ITag) {
        const ref = doc(this.firestore, this.subcollection(CollectionKey.Tags).path, tag.uid)
        await deleteDoc(ref)
    }

    public async bulkDelete(items: IItem[]) {
        const batch = writeBatch(this.firestore)
        for (const item of items) {
            const ref = doc(this.firestore, this.subcollection(CollectionKey.Items).path, item.uid)
            batch.delete(ref)
        }
        await batch.commit()
    }

    public async deleteDevice() {
        const docDevice = doc(this.firestore, this.subcollection(CollectionKey.Devices).path, deviceId())
        await deleteDoc(docDevice)
    }

    public async deleteUser() {
        const userId = this.authService.userId
        if (userId == null) {
            throw new MissingUserIdError()
        }
        const docUser = doc(this.firestore, CollectionKey.Users, userId)
        await deleteDoc(docUser)
    }

    public async addPushGroup(groupId: string) {
        const userId = this.authService.userId
        if (userId == null) {
            throw new MissingUserIdError()
        }
        const docRef = doc(this.firestore, CollectionKey.PushGroups, userId)
        await setDoc(docRef, {[groupId]: true}, {merge: true})
    }

    public async removePushGroup(groupId: string) {
        const userId = this.authService.userId
        if (userId == null) {
            throw new MissingUserIdError()
        }
        const docRef = doc(this.firestore, CollectionKey.PushGroups, userId)
        await updateDoc(docRef, {[groupId]: deleteField()})
    }

    public async isMessageSupported(): Promise<boolean> {
        return await isSupported()
    }

    public async requestTokenAndSend() {
        try {
            const { currentTimestamp } = useUtils()
            let vapidKey = 'BJnC4VHFxFcrXGJg5HHcNxYDaYUXxs9K-AFeUC8rZUXyBXbtZRT2mm-pMG-ecg84RDw3QtHu8qP3EeHrKysI7n4'
            if (process.env.REACT_APP_FIREBASE_SERVER === 'production') {
                vapidKey = 'BOTOuv18zoQx0MG35YpBBpME7-j5Pj4q7sNMVHG3QxBaiKFfGt8Elms74qpAofSQwJyG79y3IzVRFrv4WbKzDhw'
            }
            const messaging = getMessaging(this.firebaseService.firebaseApp)
            const token = await getToken(messaging, {vapidKey})
            let name = 'Browser'
            const detectedName = browserName
            if (detectedName.length > 0) {
                name = detectedName
            }
            const device = {
                uid: deviceId(), 
                deviceType: 'web',
                name,
                token,
                timestamp: currentTimestamp()
            } as IDevice
            await this.add(CollectionKey.Devices, deviceId(), device)
            onMessage(messaging, async (payload) => {
                const data = payload.data
                if (data != null && data.title != null && data.body != null) {
                    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(reg => {
                        if (reg !== undefined) {
                            const actions = []
                            const category = data.click_action
                            if (category != null && category === 'link_category') {
                                actions.push({action: '2', title: i18n.t('menu_popup_open_link')})
                            }

                            let notifData = {}
                            const itemId = data.itemId
                            if (itemId != null) {
                                // base64 encoding wasn't working anymore after react-script update
                                // just send itemId through instead, seems harmless
                                const base64 = itemId
                                const host = window.location.origin + '/dashboard/' + base64
                                notifData = { url: host }
                            }

                            reg.showNotification(data.title, { body: data.body, actions, data: notifData})
                        }
                    })
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    public generateQuickSend(): string {
        const { fireId } = useUtils()
        const genKey = fireId()
        const docRef = doc(this.firestore, CollectionKey.Quick, genKey)
        const quick: IQuick = { type: ItemType.Empty, content: '', timestamp: 0 }
        setDoc(docRef, quick)
        return genKey
    }

    public observeUser(userId: string, listener: (userIsAlive: boolean) => void): Unsubscribe {
        const colDoc = doc(this.firestore, CollectionKey.Users, userId)
        return onSnapshot(colDoc, (value: DocumentSnapshot<DocumentData>) => {
            if (value != null) {
                listener(value.exists())
            }
        }, (error) => {
            this.consoleLogger.error('Observe user snapshot failed', error)
        })
    }
    
    public observeQuick(key: string, listener: (value: DocumentSnapshot<DocumentData>) => void): Unsubscribe {
        const colDoc = doc(this.firestore, CollectionKey.Quick, key)
        return onSnapshot(colDoc, listener)
    }

    public deleteQuick(key: string) {
        const colDoc = doc(this.firestore, CollectionKey.Quick, key)
        deleteDoc(colDoc)
    }

    public async sendFeedback(data: IFeedback) {
        const colDoc = doc(this.firestore, CollectionKey.Feedback, data.uid)
        await setDoc(colDoc, data)
    }
}