import {useState, useEffect, CSSProperties} from 'react'
import { Text, Stack, Image, PrimaryButton } from '@fluentui/react'
import { Styles } from '../assets/styles'
import app_logo from '../assets/app_logo.png';
import {isMobile} from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useTranslation } from 'react-i18next'
import { container } from 'tsyringe'
import { IItem, CollectionKey, ItemType } from '../shared/stores/types';
import { FirestoreService } from '../shared/services/firestoreService';
import { StorageService } from '../shared/services/storageService';
import { ConsoleLoggerService } from '../shared/services/consoleLoggerService';

interface IProps {
    token: string
}

const SingleItem = (props: IProps) => {

    const { t } = useTranslation();
    const [copyBtnText, setCopyBtnText] = useState(t('button_copy_to_clipboard'))
    const [item, setItem] = useState<IItem | null>(null)
    const [failed, setFailed] = useState(false)
    const [imageUrl, setImageUrl] = useState<string | null>(null)
    const styles = new Styles(false)

    const consoleLogger = container.resolve(ConsoleLoggerService)
    const firestoreService = container.resolve(FirestoreService)
    const storageService = container.resolve(StorageService)

    useEffect(() => {
        getItem()
    }, [])

    const getItem = async () => {
        const decode = props.token
        const items = await firestoreService.query(CollectionKey.Items, 'uid', decode) as IItem[]
        if (items.length > 0) {
            const item = items[0]
            setItem(item)

            if (item.type === ItemType.Image || item.type === ItemType.File) {
                try {
                    const url = await storageService.getItemDownloadUrl(item)
                    setImageUrl(url)
                } catch (e) {
                    consoleLogger.error('Fetch download url failed', e)
                    setFailed(true)
                }
            }
        }
        else {
            setFailed(true)
        }
    }

    const renderDefaultAction = () => {
        if (item != null && (item.type === ItemType.Text || item.type === ItemType.Link)) {
            return (
                <PrimaryButton text={copyBtnText} onClick={onCopyClick}/>
            )
        }
        return null
    }

    const renderOtherActions = () => {
        if (item != null) {
            if (item.type === ItemType.Link) {
                return <PrimaryButton text={t('menu_popup_open_link')} onClick={onOpenLink}/>
            }
            else if ((item.type === ItemType.Image || item.type === ItemType.File) && imageUrl != null) {
                return <PrimaryButton text={t('general_download')} onClick={onDownload}/>
            }
        }

        return null
    }

    const onDownload = () => {
        if (imageUrl != null && item != null) {
            const link = document.createElement('a');
            link.download = item.content
            link.target = '_blank'
            link.href = imageUrl
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const onOpenLink = () => {
        if (item != null) {
            let link = item.content
            if (!link.startsWith('http')) {
                link = 'http://' + link
            }
            window.open(link, '_blank') 
        }
    }

    const onCopyClick = () => {
        navigator.clipboard.writeText(item != null ? item.content : '')
        setCopyBtnText(t('toast_copied_clipboard'))
        setTimeout(() => {
            setCopyBtnText(t('menu_popup_copy'))
        }, 3000)
    }

    const renderMessage = () => {
        if (!failed && item == null) {
            return <Text style={stepsStyle}>{`${t('general_loading')}...`}</Text>
        }
        else if (failed) {
            return <Text style={stepsStyle}>Oops, your notification content could not be found</Text>
        }
        return null
    }

    const renderImage = () => {
        if (item != null && item.type === ItemType.Image && imageUrl != null) {
            return (
                <LazyLoadImage
                        key={item.uid}
                        style={{objectFit: 'scale-down', imageOrientation: 'from-image', marginTop: 12, marginBottom: 24}}
                        src={imageUrl}
                        width={200}
                        height={200}
                        draggable={false} />
            )
        }
        return null
    }

    const stepsStyle: CSSProperties = {color: styles.prColors.subTextColor.color(), fontSize: '20px', textAlign: 'start'}
    const noteStyle: CSSProperties = {color: styles.prColors.subTextColor.color(), fontSize: '14px', textAlign: 'start', marginTop: '24px'}

    const renderContent = () => {
        if (item == null) {
            return null
        }
        return (

            <Stack horizontalAlign={'start'} style={{marginTop: '0px', borderRadius: '8px', width: '100%', padding: '5px', backgroundImage: 'linear-gradient(45deg, rgba(62,140,255,1) 30%, rgba(129,49,244,1) 70%, rgba(129,49,244,1))'}}>
                <Stack horizontalAlign={'center'} style={{backgroundColor: '#ffffff', width: '100%', padding: '16px', borderRadius: '4px'}}>
                    <Text className={'multi-3line-ellipsis'} style={{color: styles.prColors.subTextColor.color(), fontSize: isMobile ? '16px' : '20px', textAlign: 'center', marginBottom: '16px'}}>
                        {item.content}
                    </Text>

                    {renderImage()}

                    <Stack horizontal={isMobile ? false : true} tokens={{childrenGap: '16px'}}>
                        {renderDefaultAction()}
                        {renderOtherActions()}
                    </Stack>
                </Stack>
            </Stack>

        )
    }

    return (
        <Stack horizontal style={{height: '100vh', backgroundSize: 'auto, cover, cover', backgroundImage: 'url("https://dmystapp.com/welcome/assets/css/images/overlay2.png"), url("https://dmystapp.com/welcome/assets/css/images/overlay3.svg"), linear-gradient(45deg, rgba(62,140,255,1) 30%, rgba(129,49,244,1) 70%, rgba(129,49,244,1))'}}>
            <Stack horizontal verticalAlign={'center'} style={{height: '100%', width: '100%'}}>
                <Stack horizontalAlign={'center'} style={{justifyContent: 'center', width: '100%', marginBottom: '80px'}}>
                    <Stack horizontal verticalAlign={'center'} style={{marginBottom: '32px'}}>
                        <Image src={app_logo} width={32} height={32}/>
                        <Text style={{color: 'white', fontSize: '32px', fontWeight: 600, marginLeft: '10px'}}>Dmyst Notification</Text>
                    </Stack>

                    <Stack horizontalAlign={'start'} style={{backgroundColor: styles.prColors.backgroundColor.color(), maxWidth: '700px', padding: '40px', borderRadius: '8px', boxShadow: '3px 3px 3px 2px #00000033', minWidth: '400px'}}>
                        {renderMessage()}
                        {renderContent()}
                        <Text style={noteStyle}>{t('notification_why_seeing')}<p>{t('notification_why_seeing_desc')}</p> </Text>
                    </Stack>

                </Stack>
            </Stack>
        </Stack>
    )
}

export default SingleItem