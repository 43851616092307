import { INavLink } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { IPremiumInfo } from '../stores/types';

export const useSideBarActions = () => {
    const { t } = useTranslation()

    const settingsNavLink = (): INavLink => {
        return {
            name: t('settings_settings'),
            url: '',
            key: 'settings'
        }
    }

    const logoutNavLink = (): INavLink => {
        return {
            name: t('menu_panel_logout'),
            url: '',
            key: 'logout'
        }
    }

    const deleteAccountNavLink = () => {
        return {
            name: t('settings_delete_account'),
            url: '',
            key: 'deleteAccount'
        }
    }

    const recentNavLink = (): INavLink => {
        return {
            name: t('menu_panel_recent'),
            icon: 'Clock',
            url: '',
            key: 'recent'
        }
    }

    const feedbackNavLink = (): INavLink => {
        return {
            name: t('menu_panel_give_feedback'),
            url: '',
            key: 'giveFeedback'
        }
    }

    const nonPremiumInfo = (): IPremiumInfo => {
        return {
            title: `${t('premium_go_premium')} 💎`,
            description: t('premium_tagline')
        }
    }

    const premiumInfo = (): IPremiumInfo => {
        return {
            title: `${t('settings_premium')} 💎`,
            description: t('premium_membership_active')
        }
    }

    return { settingsNavLink, logoutNavLink, deleteAccountNavLink, recentNavLink, feedbackNavLink, nonPremiumInfo, premiumInfo }
}