import { PrimaryButton, Stack } from '@fluentui/react'
import { useEffect, useState } from 'react'
import Viewer from 'react-viewer'
import { useStyles } from '../assets/styles'
import { Icons, PRButton } from '../shared/utils/icons'
import { IPasteImage } from './pasteListener'

interface IProps {
    onClose: () => void,
    onSave?: (pasteImage: IPasteImage | null) => void,
    visible: boolean,
    src: string | null,
    pasteSrc?: IPasteImage | null
}

const ImageViewerHandler = (props: IProps) => {

    const styles = useStyles()

    const [isPasted, setIsPasted] = useState(false)

    useEffect(() => {
        if (props.pasteSrc != null) {
            setIsPasted(true)
        }
        else {
            setIsPasted(false)
        }
    }, [props.pasteSrc])

    const onPasteSave = () => {
        if (props.pasteSrc != null && props.onSave != null) {
            props.onSave(props.pasteSrc)
        }
    }

    return (
        <div>
            <Viewer visible={props.visible} images={[{src: props.src != null ? props.src : ''}]}
            noToolbar={true} zoomSpeed={0.2} noNavbar={true} minScale={0.5} noClose={true}/>

            <div style={{position: 'absolute', top: 0, right: 0, zIndex: 9999, height: 60, display: props.visible ? 'block' : 'none'}}>
                <Stack horizontal verticalAlign={'center'} style={{position: 'relative'}}>
                    <PrimaryButton style={{width: 60, height: 44, fontSize: 18, marginRight: 8, display: isPasted ? 'block' : 'none'}} text={'Save'} onClick={onPasteSave}/>
                    <PRButton style={{width: 60, height: 60}} icon={Icons.Dismiss24Filled} color={styles.prColors.textColor.color()} onClick={props.onClose}/>
                </Stack>
            </div>

        </div>

    )
}

export default ImageViewerHandler