import React, { useState } from 'react'
import { Stack, Text, Icon } from '@fluentui/react'
import { Styles } from '../assets/styles'
import BadgeView from '../components/badgeView'
import ToolHint from '../components/toolHint'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { PRButton, Icons, IconsView } from '../shared/utils/icons'
import { Mode, useLightSwitch } from 'use-light-switch'
import { linkPreviewDisabled, singleTapEnabled } from '../shared/utils/settings'
import { useTranslation } from 'react-i18next'
import { IImageCache, useImageCache, useTagsState } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { IItem, ILinkPreview, ItemType } from '../shared/stores/types'
import moment from 'moment'
import { useUtils } from '../shared/utils/utils'
import { AnalyticsService, PREvent } from '../shared/services/analyticsService'
import { useItemActions } from '../shared/viewModel/useItemsAction'

interface IProps {
    item: IItem
    itemImage?: IImageCache
    linkPreview?: ILinkPreview
    isSelectable?: boolean
    isSelected?: boolean
    selectedBgColor?: string
    selectedHoverBgColor?: string
    onClick?: (item: IItem) => void
    onCopyClick?: (item: IItem) => void
    onEdit?: (item: IItem) => void
    onDelete?: (item: IItem) => void
    onTag?: (item: IItem) => void
    onImageClick?: (imageUrl: string) => void
    onMoreClick?: (item: IItem) => void
}

const ItemRow = (props: IProps) => {

    const { t } = useTranslation();
    const mode = useLightSwitch()
    const useDark = mode === Mode.Dark
    const styles = new Styles(useDark)
    const [hover, setHover] = useState(false)
    const { tags: cloudTags } = useTagsState()
    const analyticsService = container.resolve(AnalyticsService)
    const { textForItemType, isVideo } = useItemActions()
    const { setImageCacheError } = useImageCache()
    const { isValidHex } = useUtils()

    const onDeleteClick = () => {
        if (props.onDelete != null) {
            props.onDelete(props.item);
        }
    }

    const onCopyClick = () => {
        if (props.onCopyClick != null) {
            props.onCopyClick(props.item);
        }
    }

    const onClick = () => {
        if (props.onClick != null) {
            props.onClick(props.item);
        }
    }

    // const onPlayClick = () => {
    //     let url = props.itemImage?.downloadUrl 
    //     if (url != null) {
    //         let audio = new Audio(url)
    //         audio.play()
    //     }
    // }

    const onDownloadCLick = () => {
        if (props.isSelectable == true && props.onClick != null) {
            props.onClick(props.item)
            return
        }
        if (props.itemImage?.downloadUrl != null) {
            const link = document.createElement('a');
            link.download = props.item.content
            link.target = '_blank'
            link.href = props.itemImage?.downloadUrl
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            analyticsService.log(PREvent.savedItem)
        }
    }

    const onOpenLinkClick = () => {
        let link = props.item.content
        if (!link.startsWith('http')) {
            link = 'http://' + link
        }
        window.open(link, '_blank') 
        analyticsService.log(PREvent.openLink)
    }

    const onEditClick = () => {
        if (props.onEdit != null) {
            props.onEdit(props.item)
        }
    }

    const onTagClick = () => {
        if (props.onTag != null) {
            props.onTag(props.item)
        }
    }

    const onMoreClick = () => {
        if (props.onMoreClick != null) {
            props.onMoreClick(props.item)
        }
    }

    const iconTintColor = () => {
        if (props.item.type === ItemType.Text && isValidHex(props.item)) {
            return props.item.content
        }
        return styles.colors.iconColor
    }

    const tagsContent = () => {
        const previousTags = cloudTags
        let doesOneExist = false
        const content = props.item.tags.map((tagUid: string) => {
            for (const tag of previousTags) {
                if (tag.uid === tagUid) {
                    doesOneExist = true
                    return (
                        <BadgeView tag={tag} isSmall={true} key={tag.uid}/>
                    )
                }
            }
            return null
        })
        if (content.length === 0) {
            return null
        }
        if (!doesOneExist) {
            return null
        }
        return (
            <Stack horizontal tokens={{childrenGap: 6}}>
                {content}
            </Stack>
        )
    }

    // const imageLoaded = (event: React.SyntheticEvent<HTMLImageElement>) => {
    //     const maxImageSize = 100
    //     const imageHeight = event.currentTarget.naturalHeight
    //     const imageWidth = event.currentTarget.naturalWidth

    //     let height = 0
    //     let width = 0

    //     if (imageHeight > maxImageSize && imageWidth > maxImageSize) {
    //         if (imageWidth > imageHeight) {
    //             height = maxImageSize
    //             width = (height * imageWidth)/imageHeight
    //         }
    //         else {
    //             width = maxImageSize
    //             height = (width * imageHeight)/imageWidth
    //         }
    //         setSize([width, height])
    //     }
    //     else {
    //         setSize([100, 100])
    //     }
    //     setHasPlaceholder(false)
    // }

    const onItemImageClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (props.isSelectable == true && props.onClick != null) {
            props.onClick(props.item)
        } else {
            onImageClickAlt()
        }
        
    }

    const onImageClickAlt = () => {
        if (props.onImageClick != null && props.itemImage != null) {
            props.onImageClick(props.itemImage.downloadUrl)
        }
    }

    const renderImageView = (itemImage: IImageCache | undefined, itemType: ItemType, width: number, height: number, onClick: (e: React.MouseEvent) => void) => {
        let src = itemType == ItemType.Image ? itemImage?.downloadUrl : itemImage?.videoThumbnailUrl
        if (itemImage?.hasError == true) {
            src = undefined
        }
        const show = (itemImage?.downloadUrl || itemImage?.videoThumbnailUrl) && itemImage.hasError !== true ? 1 : 0
        return (
            <div style={{position: 'relative', width: width, height: height, backgroundColor: styles.prColors.linkPreviewBgColor.color(), borderRadius: 10}}>
                {itemImage == null ?  
                <Icon iconName={'ProgressRingDots'} style={{top: '50%', left: '50%', width: '24px', position: 'absolute', fontSize: 24, transform: 'translate(-50%, -50%)'}}/> : null}
                <LazyLoadImage
                    key={props.item.uid}
                    wrapperProps={{style: {borderWidth: 2, borderColor: '#ff0000'}}}
                    style={{objectFit: 'cover', borderRadius: 10, opacity: show, imageOrientation: 'from-image', top: 0, left: 0, position: 'absolute'}}
                    src={src}
                    width={width}
                    height={height}
                    onClick={onClick}
                    draggable={false} 
                    onError={() => {
                        setImageCacheError(props.item.uid)
                    }}
                />
                { itemImage?.hasError === true && <div style={{top: '50%', left: '50%', width: 28, position: 'absolute', transform: 'translate(-50%, -50%)'}}>
                    {IconsView.iconView(Icons.Prohibited24Filled, styles.prColors.iconDisabledColor.color(), 28)}    
                </div> }
                <div style={{top: '50%', left: '50%', width: 36, position: 'absolute', transform: 'translate(-50%, -50%)'}}>
                    {itemType == ItemType.File ? IconsView.iconView(Icons.Play24Filled, '#ffffff', 36) : undefined}    
                </div>
            </div>
        )
    }

    const imageContent = () => {
        if (props.item.type === ItemType.Image) {
            return renderImageView(props.itemImage, ItemType.Image, 120, 120, onItemImageClick)
        }
        else if (props.item.type == ItemType.File) {
            if (isVideo(props.item)) {
                return renderImageView(props.itemImage, ItemType.File, 200, 120, onItemImageClick)
            }
            return (
                <Stack horizontal verticalAlign={'center'} style={{alignSelf: 'flex-start', backgroundColor: styles.prColors.linkPreviewBgColor.color(), borderRadius: 10, paddingLeft: 20, paddingRight: 20, paddingTop: 12, paddingBottom: 12, maxWidth: '500px'}}>
                    <Text className={'multi-1line-ellipsis'} style={{color: styles.prColors.textColor.color(), fontSize: 16, fontWeight: 600}}>{props.item.content}</Text>
                </Stack>
            )
        }
        return null
    }

    const timeAgo = (date: Date): string => {
        const now = moment();
        const inputDate = moment(date);
        const diffInSeconds = now.diff(inputDate, 'seconds');
        const diffInMinutes = now.diff(inputDate, 'minutes');
        const diffInHours = now.diff(inputDate, 'hours');
        const diffInDays = now.diff(inputDate, 'days');
    
        if (diffInDays >= 2) {
            return inputDate.format('MMM D');
        } else if (diffInDays >= 1) {
            return 'Yesterday';
        } else if (diffInHours >= 1) {
            return `${diffInHours}h`;
        } else if (diffInMinutes >= 1) {
            return `${diffInMinutes}m`;
        } else {
            return `${Math.max(diffInSeconds, 1)}s`;
        }
    }

    const cellContent = () => {
        return (
        <Stack style={{display: 'stretch', paddingTop: 16, paddingLeft: 16, paddingRight: 16, paddingBottom: 19}} tokens={{childrenGap: 12}}>
            <Stack horizontal horizontalAlign={'space-between'}>
                <Stack horizontal verticalAlign={'center'} tokens={{childrenGap: 4}} style={{height: 18}}>
                    {IconsView.iconView(IconsView.getIconName(props.item), iconTintColor(), 18)}
                    <Text style={{fontSize: 14, color: styles.prColors.textColor.color()}}>{textForItemType(props.item)}</Text>
                    {tagsContent()}
                </Stack>
                <Stack horizontal verticalAlign={'center'} tokens={{childrenGap: 4}}>
                    <Text style={{fontSize: 14, fontWeight: 300, color: styles.prColors.textColor.color()}}>{timeAgo(new Date(props.item.created * 1000))}</Text>
                    {props.item.pinned == true ? IconsView.iconView(Icons.Pin24Filled, styles.prColors.pinColor.color(), 18) : undefined}
                </Stack>
                
            </Stack>

            {props.item.type !== ItemType.File && props.item.type !== ItemType.Image ?
            <pre style={{marginBottom: 0, whiteSpace: 'pre-wrap', wordBreak: props.item.type === ItemType.Link ? 'break-all' : 'break-word'}}>
                <Text className={props.item.type === ItemType.Link ? 'multi-1line-ellipsis' : undefined} style={{color: props.item.type === ItemType.Link ? styles.prColors.primaryColor.color() : styles.prColors.textColor.color(), fontSize: 16}}>
                    {props.item.content}
                </Text>
            </pre> : null}
            {linkPreviewView()}
            {imageContent()}
        </Stack>)
    }

    const handleCell = () => {
        if (props.item.type === ItemType.Image) {
            return (
                <div onClick={singleTapEnabled() ? onDownloadCLick : onItemImageClick}>
                    {cellContent()}
                </div>
            )
        }
        else if (props.item.type === ItemType.File) {
            return (
                <div onClick={onDownloadCLick}>
                    {cellContent()}
                </div>
            )
        }
        else if (props.item.type === ItemType.Link) {
            let clickAction = singleTapEnabled() ? onClick : onOpenLinkClick
            if (props.isSelectable == true) {
                clickAction = onClick
            }
            return (
                <div onClick={clickAction}>
                    {cellContent()}
                </div>
            )
        }
        else if (props.item.type === ItemType.Text) {
            return (
                <div onClick={onClick}>
                    {cellContent()}
                </div>
            )
        }
    }

    const copyToolTipText = () => {
        if (props.item.type === ItemType.File || props.item.type === ItemType.Image) {
            return t('menu_popup_copy_file_name')
        }
        return t('menu_popup_copy')
    }

    const linkPreviewView = () => {
        if (linkPreviewDisabled()) {
            return null
        }

        const linkPreview = props.linkPreview
        if (linkPreview != undefined && linkPreview.title.length > 0) {
            return (
                <Stack horizontal verticalAlign={'center'} style={{backgroundColor: styles.prColors.linkPreviewBgColor.color(), marginTop: 8, alignSelf: 'flex-start', borderColor: styles.prColors.fileBorderColor.color(), borderRadius: 10, paddingLeft: 20, paddingRight: 20, paddingTop: 12, paddingBottom: 12, maxWidth: '500px'}}>
                    <Stack>
                        <Text className={'multi-1line-ellipsis'} style={{color: styles.prColors.textColor.color(), fontSize: '16px', marginBottom: '4px', fontWeight: 600}}>{linkPreview.title}</Text>
                        <Text className={'multi-1line-ellipsis'} style={{color: styles.prColors.subTextColor.color(), fontSize: '14px'}}>{linkPreview.description}</Text>
                    </Stack>
                </Stack>
            )
        }
        return null
    }

    const imageFileAction = () => {
        if (props.item.type === ItemType.Image && singleTapEnabled()) {
            return (
                <ToolHint text={t('menu_popup_view_image')}>
                    <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.Image24Regular} onClick={onImageClickAlt}/> 
                </ToolHint>
            )
        }
        return (
            <ToolHint text={t('general_download')}>
                <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.ArrowDownload24Regular} onClick={onDownloadCLick}/> 
            </ToolHint>
        )
    }

    const renderSelectedBg = () => {
        if (props.isSelected && props.selectedBgColor != null) {
            return { backgroundColor: props.selectedBgColor }
        }

        return undefined
    }

    const renderHoverBg = () => {
        if (props.isSelected && props.selectedBgColor != null) {
            return { backgroundColor: props.selectedHoverBgColor }
        }

        return { backgroundColor: styles.prColors.cellHoverColor.color() }
    }

    const buttonSize: number = 44

    return (
        <div style={hover ? renderHoverBg() : renderSelectedBg()} onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>
            <div style={{position: 'relative', cursor: 'pointer'}}>
                {handleCell()}
                <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 3, backgroundColor: styles.prColors.cellSeperatorColor.color()}}></div>
                <div style={hover && props.isSelectable == null ? undefined : { display: 'none' }}>
                    <div style={{position: 'absolute', top: -5, right: 20}}>
                        <div style={{backgroundColor: styles.prColors.backgroundColor.color(), overflow: 'hidden', borderWidth: 1, borderStyle: 'solid', borderColor: styles.prColors.textBorderColor.color(), borderRadius: 10}}>
                            <Stack horizontal tokens={{childrenGap: 2}}>
                                {props.item.type === ItemType.Link ? (
                                <ToolHint text={t('menu_popup_open_link')}>
                                    <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.Open24Filled} onClick={onOpenLinkClick}/> 
                                </ToolHint>
                                ) : undefined}
                                {props.item.type === ItemType.Image || props.item.type === ItemType.File ? imageFileAction() : undefined}
                                {singleTapEnabled() && props.item.type === ItemType.Text ? (
                                <ToolHint text={t('menu_popup_edit')}>
                                    <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.Edit24Regular} onClick={onEditClick}/>  
                                </ToolHint>
                                ) : 
                                (
                                <ToolHint text={copyToolTipText()}>
                                    <PRButton style={{width: buttonSize, height: buttonSize}} onClick={onCopyClick} icon={Icons.Copy24Regular}/>  
                                </ToolHint>
                                )}
                                <ToolHint text={t('menu_popup_tag')}>
                                    <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.Tag24Regular} onClick={onTagClick}/> 
                                </ToolHint>
                                <ToolHint text={t('menu_popup_delete')}>
                                    <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.Delete24Regular} color={styles.prColors.errorColor.color()} onClick={onDeleteClick}/> 
                                </ToolHint>
                                <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.More24Regular} onClick={onMoreClick}/>
                            </Stack> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemRow