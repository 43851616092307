import { TooltipHost, Text, ICalloutProps } from '@fluentui/react'
import React from 'react'

interface IProps {
    text: string,
    children?: React.ReactNode
}

const ToolHint = (props: IProps) => {
    
    const calloutProps: ICalloutProps = {styles: {container: {lineHeight: '1.3em'}}}

    return (
        <TooltipHost calloutProps={calloutProps} content={<Text>{props.text}</Text>} id={props.text}>
            {props.children}
        </TooltipHost>
    )
}
export default ToolHint