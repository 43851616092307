import { useUtils } from './utils'

export const clearSettings = () => {
    localStorage.clear()
}

export const linkPreviewDisabled = (): boolean => {
    const setting = localStorage.getItem('linkPreviewKey')
    if (setting == null) {
        return false
    }
    return setting !== '1'
}

export const setLinkPreviewDisabled = (disabled: boolean) => {
    localStorage.setItem('linkPreviewKey', disabled ? '0' : '1')
}

export const singleTapEnabled = (): boolean => {
    const setting = localStorage.getItem('singleTapKey')
    if (setting == null) {
        return false
    }
    return setting === '1'
}

export const setSingleTapEnabled = (enabled: boolean) => {
    localStorage.setItem('singleTapKey', enabled ? '1' : '0')
}

export const deviceId = (): string => {
    const deviceId = localStorage.getItem('deviceIdKey')
    if (deviceId == null) {
        const { fireId } = useUtils()
        const newDeviceId = fireId()
        localStorage.setItem('deviceIdKey', newDeviceId)
        return newDeviceId
    }
    return deviceId
}

export const setLastSelectedGroupId = (groupId: string | null) => {
    if (groupId != null) {
        localStorage.setItem('proRevPrevGroupId', groupId)
    } else {
        localStorage.setItem('proRevPrevGroupId', '')
    }
}

export const lastSelectedGroupId = (): string | null => {
    const groupId = localStorage.getItem('proRevPrevGroupId')
    if (groupId != null && groupId.length > 0) {
        return groupId
    }
    return null
}