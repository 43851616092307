import { create } from 'zustand';
import { DeleteToastState, IGroup, IItem, ILinkPreview, ITag, RootDisplayType, ToastSetup } from './types';

export interface RootDisplayState {
    rootDisplayType: RootDisplayType
    setRootDisplayType: (type: RootDisplayType) => void
}

export const useRootDisplayState = create<RootDisplayState>(set => ({
    rootDisplayType: RootDisplayType.login,
    setRootDisplayType: (type: RootDisplayType) => set(state => ({ rootDisplayType: type }))
}))

export interface GroupsState {
    groups: IGroup[]
    setGroups: (groups: IGroup[]) => void
    resetGroups: () => void
}

export const useGroupsState = create<GroupsState>(set => ({
    groups: [],
    setGroups: (groups: IGroup[]) => set(state => ({ 
        groups: groups.sort((group1, group2) => group1.name.localeCompare(group2.name))
     })),
     resetGroups: () => set(state => ({ 
        groups: []
     }))
}))

export interface ItemsState {
    items: IItem[]
    setItems: (items: IItem[]) => void
    resetItems: () => void
}

export const useItemsState = create<ItemsState>(set => ({
    items: [],
    setItems: (items: IItem[]) => set(state => {
        const filteredItems = items.filter(i => i.localDeleted != true)
        filteredItems.sort((item1, item2) => item2.created - item1.created)
        const pinnedItems = filteredItems.filter(i => i.pinned == true)
        for (const pinnedItem of pinnedItems) {
            const index = filteredItems.indexOf(pinnedItem)
            if (index >= 0) {
                filteredItems.splice(index, 1)
                filteredItems.unshift(pinnedItem)
            }
        }

        return { items: filteredItems }
    }),
    resetItems: () => set(state => ({ 
        items: []
    }))
}))

export interface TagsState {
    tags: ITag[]
    setTags: (tags: ITag[]) => void
    resetTags: () => void
}

export const useTagsState = create<TagsState>(set => ({
    tags: [],
    setTags: (tags: ITag[]) => set(state => ({ 
        tags
     })),
     resetTags: () => set(state => ({ 
        tags: []
     }))
}))

export interface PushGroupsState {
    pushGroups: string[]
    setPushGroups: (pushGroups: string[]) => void
    resetPushGroups: () => void
}

export const usePushGroupsState = create<PushGroupsState>(set => ({
    pushGroups: [],
    setPushGroups: (pushGroups: string[]) => set(state => ({ 
        pushGroups
     })),
     resetPushGroups: () => set(state => ({ 
        pushGroups: []
     }))
}))

export interface SelectedGroupState {
    selectedGroup: IGroup | null
    setSelectedGroup: (group: IGroup | null) => void
    resetSelectedGroup: () => void
}

export const useSelectedGroupState = create<SelectedGroupState>(set => ({
    selectedGroup: null,
    setSelectedGroup: (selectedGroup: IGroup | null) => set(state => ({ 
        selectedGroup
     })),
     resetSelectedGroup: () => set(state => ({ 
        selectedGroup: null
     }))
}))

export interface ToastState {
    toastSetup: ToastSetup | null
    deleteToastState: DeleteToastState
    showToast: (toastSetup: ToastSetup | null) => void
    setDeleteToastState: (deleteToastState: DeleteToastState) => void
    resetToast: () => void
}

export const useToast = create<ToastState>(set => ({
    toastSetup: null,
    deleteToastState: DeleteToastState.None,
    showToast: (toastSetup: ToastSetup | null) => set(state => ({ 
        toastSetup
     })),
     setDeleteToastState: (deleteToastState: DeleteToastState) => set(state => ({ 
        deleteToastState
      })),
      resetToast: () => set(state => ({ 
         toastSetup: null, deleteToastState: DeleteToastState.None
      }))
}))

export interface IImageCache {
    uid: string
    downloadUrl: string
    videoThumbnailUrl?: string
    hasError?: boolean
}

export interface ImageCacheState {
    cache: IImageCache[]
    addImageCache: (imageCache: IImageCache) => void
    setImageCacheError: (uid: string) => void
    clearImageCache: () => void
}

export const useImageCache = create<ImageCacheState>(set => ({
    cache: [],
    addImageCache: (imageCache: IImageCache) => set(state => ({ 
        cache: [...state.cache, imageCache]
     })),
     setImageCacheError: (uid: string) => set(state => {
        const imageCache = state.cache.find(i => i.uid === uid)
        if (imageCache != null) {
            imageCache.hasError = true
        }
        return { cache: state.cache }
     }),
    clearImageCache: () => set(state => ({ cache: [] }))
}))

export interface PremiumState {
    isPremium: boolean
    setPremium: (isPremium: boolean) => void
    resetPremium: () => void
}

export const usePremiumState = create<PremiumState>(set => ({
    isPremium: false,
    setPremium: (isPremium: boolean) => set(state => ({ 
        isPremium
     })),
     resetPremium: () => set(state => ({ 
        isPremium: false
     }))
}))

export interface StorageUploadingState {
    isUploading: boolean
    setUploading: (isUploading: boolean) => void
    resetUploading: () => void
}

export const useStorageUploadingState = create<StorageUploadingState>(set => ({
    isUploading: false,
    setUploading: (isUploading: boolean) => set(state => ({ 
        isUploading
     })),
     resetUploading: () => set(state => ({ 
        isUploading: false
     }))
}))

export interface StorageSizeState {
    storageSize: number
    setStorageSize: (storageSize: number) => void
    resetStorageSize: () => void
}

export const useStorageSizeState = create<StorageSizeState>(set => ({
    storageSize: 0,
    setStorageSize: (storageSize: number) => set(state => ({ 
        storageSize
     })),
     resetStorageSize: () => set(state => ({ 
        storageSize: 0
     }))
}))

export interface LinkPreviewCacheState {
    cache: ILinkPreview[]
    addLinkPreview: (linkPreview: ILinkPreview) => void
    clearLinkPreviewCache: () => void
}

export const useLinkPreviewCacheState = create<LinkPreviewCacheState>(set => ({
    cache: [],
    addLinkPreview: (linkPreview: ILinkPreview) => set(state => ({ 
        cache: [...state.cache, linkPreview]
     })),
     clearLinkPreviewCache: () => set(state => ({ cache: [] }))
}))

export interface PremiumDialogState2 {
    show: boolean
    message: string | null
    showPremiumDialog: (message: string | null) => void
    hidePremiumDialog: () => void
}

export const usePremiumDialogState = create<PremiumDialogState2>(set => ({
    show: false,
    message: '',
    showPremiumDialog: (message: string | null) => set(state => ({ 
        show: true, message
     })),
     hidePremiumDialog: () => set(state => ({ 
         show: false, message: ''
      }))
}))

export interface RecentSelectedState {
    isRecentSelected: boolean
    setIsRecentSelected: (isRecentSelected: boolean) => void
}

export const useRecentSelectedState = create<RecentSelectedState>(set => ({
    isRecentSelected: false,
    setIsRecentSelected: (isRecentSelected: boolean) => set(state => ({ 
        isRecentSelected
     }))
}))

export interface StorageLimitReachedState {
    showStorageLimitReached: boolean
    setShowStorageLimitReached: (showStorageLimitReached: boolean) => void
}

export const useStorageLimitReachedState = create<StorageLimitReachedState>(set => ({
    showStorageLimitReached: false,
    setShowStorageLimitReached: (showStorageLimitReached: boolean) => set(state => ({ 
        showStorageLimitReached
     }))
}))