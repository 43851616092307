import React from 'react'
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from '@fluentui/react'
import { Styles } from '../assets/styles'
import { Mode, useLightSwitch } from 'use-light-switch'

export enum IDialogButtonType {
    Primary,
    Destructive
}

export interface IDialogButton {
    text: string,
    onClick: () => void
    type?: IDialogButtonType
}

interface IProps {
    children?: React.ReactNode,
    type: DialogType,
    title: string,
    subText: string,
    hidden: boolean,
    onDismiss: () => void,
    customFooter?: React.ReactNode,
    cancelButton?: IDialogButton,
    actionButton?: IDialogButton
}

const PRDialog = (props: IProps) => {

    const mode = useLightSwitch()
    const useDark = mode === Mode.Dark
    const styles = new Styles(useDark)

    const renderFooter = () => {
        if (props.customFooter !== undefined) {
            return props.customFooter
        }

        if (props.actionButton !== undefined || props.cancelButton !== undefined) {
            const defaultFooter = (
                <DialogFooter>
                    {renderCancelButton()}
                    {renderActionButton()}
                </DialogFooter>)
            return defaultFooter
        }
    }

    const renderActionButton = () => {
        if (props.actionButton !== undefined) {
            const actionButtonStyle = props.actionButton.type === IDialogButtonType.Destructive ? {root: {backgroundColor: styles.colors.softRed, borderColor: 'transparent'}, rootHovered: {backgroundColor: '#cc3429', borderColor: 'transparent'}} : undefined
            return <PrimaryButton styles={actionButtonStyle} onClick={props.actionButton.onClick} text={props.actionButton.text} />
        }
        return null
    }

    const renderCancelButton = () => {
        if (props.cancelButton !== undefined) {
            return <DefaultButton onClick={props.cancelButton.onClick} text={props.cancelButton.text} styles={{rootHovered: {backgroundColor: styles.prColors.cellHoverColor.color()}, root: {backgroundColor: styles.prColors.backgroundColor.color()}, label: {color: styles.prColors.textColor.color()}, rootPressed: {backgroundColor: styles.prColors.backgroundColor.color()}}} />
        }
        return null
    }

    return (
        props.hidden ? null :
        <Dialog styles={{main: {borderRadius: '10px', backgroundColor: styles.prColors.dialogBackgroundColor.color()}}} modalProps={{isBlocking: false, isDarkOverlay: useDark}} hidden={false} dialogContentProps={{
            styles: {
                subText: {color: styles.prColors.textColor.color()},
                title: {color: styles.prColors.textColor.color()}
            },
            type: props.type, 
            title: props.title, 
            subText: props.subText 
        }} 
            onDismiss={props.onDismiss}>
            {props.children}
            {renderFooter()}
        </Dialog>
    )
}

export default PRDialog