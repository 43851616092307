export class MissingUserIdError extends Error {
    constructor() {
        super('Missing user id')
    }
}

export class MissingSelectedGroupError extends Error {
    constructor() {
        super('Missing selected group')
    }
}