import React, { useState, useEffect, ReactNode } from 'react'
import { Panel, Stack, TextField, ITextField, Text, PanelType } from '@fluentui/react'
import { PRButton, Icons, IconsView } from '../shared/utils/icons'
import { useStyles } from '../assets/styles'
import BadgeView from '../components/badgeView'
import { useTranslation } from 'react-i18next';
import { usePremiumState, useTagsState } from '../shared/stores/stores';
import { container } from 'tsyringe';
import { AuthService } from '../shared/services/authService'
import { FirestoreService } from '../shared/services/firestoreService'
import { IItem, ITag } from '../shared/stores/types'
import { useModelFactory } from '../shared/viewModel/useModelFactory'

interface IProps {
    item: IItem | null,
    isOpen: boolean,
    onTagWillAdd: () => void,
    onDismiss: (didHitDone: boolean) => void
}

const TagPanel = (props: IProps) => {

    const { t } = useTranslation()
    const styles = useStyles()

    const maxTags = 6
    const [activeTags, setActiveTags] = useState<ITag[]>([])
    const [deleteTags, setDeleteTags] = useState<ITag[]>([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [showConfirm, setShowConfirm] = useState(false)
    const [tagValue, setTagValue] = useState('')
    const { isPremium } = usePremiumState()
    const { createTag } = useModelFactory()
    const { tags: cloudTags } = useTagsState()

    const firestoreService = container.resolve(FirestoreService)
    const authService = container.resolve(AuthService)
    const colours = ['#0078D4', '#34495e', '#16a085', '#2ecc71', '#9b59b6', '#f1c40f', '#e67e22', '#e74c3c']
    const textRef = React.createRef<ITextField>()

    useEffect(() => {
        if (props.item == null || !props.isOpen) {
            return
        }
        const previousTags = cloudTags
        const tags: ITag[] = []
        if (props.item != null) {
            for (const tagUid of props.item.tags) {
                const filtered = previousTags.filter( tag => tag.uid === tagUid)
                if (filtered.length > 0) {
                    tags.push(filtered[0])
                }
            }
        }
        setActiveTags(tags)
        setDeleteTags([])
        setSelectedIndex(0)
        setTagValue('')
        setShowConfirm(tags.length > 0)
    }, [props.isOpen])

    const textDidChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (value !== undefined) {
            if (value.length > 40) {
                return
            }
            setTagValue(value)
        }
    }

    const onKeyDownAction = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            addTag()
        }
    }

    const addTag = () => {
        const userId = authService.userId

        if (tagValue.trim().length > 0 && userId != null && activeTags.length < maxTags) {
            if (activeTags.length >= 1 && !isPremium) {
                props.onTagWillAdd()
                return
            }
            const tags: ITag[] = []
            const tag = createTag(userId, tagValue.trim(), colours[selectedIndex])
            for (const tagObj of activeTags) {
                tags.push(tagObj)
            }
            tags.push(tag)
            setActiveTags(tags)
            setTagValue('')
            setShowConfirm(true)
        }
    }

    const onCloseClick = () => {
        props.onDismiss(false)
    }

    const onCompleteClick = () => {
        if (props.item != null) {

            for (const tag of deleteTags) {
                firestoreService.deleteTag(tag)
            }

            const tagUids: string[] = []
            for (const tag of activeTags) {
                tagUids.push(tag.uid)
                firestoreService.addTag(tag)
            }
            const item = props.item
            item.tags = tagUids
            firestoreService.addItem(item)
        }
        props.onDismiss(true)
    }

    const onActiveTagClick = (tag: ITag) => {
        const tags: ITag[] = []
        const removeTags: ITag[] = []
        for (const tagObj of activeTags) {
            if (tagObj.uid === tag.uid) {
                removeTags.push(tagObj)
                continue
            }
            tags.push(tagObj)
        }
        setActiveTags(tags)
        setDeleteTags(removeTags)
    }

    const activeTagsContent = () => {
        const tagsContent = activeTags.map( (tag: ITag) => {
            return <BadgeView key={tag.uid} tag={tag} isSmall={false} onClick={onActiveTagClick} />
        })

        if (tagsContent.length > 0) {
            return (
                <Stack horizontalAlign={'stretch'} tokens={{childrenGap: '10px'}}>
                    {tagsContent}
                </Stack>
            )
        }
        return null
    }

    const renderColors = () => {
        const elements: ReactNode[] = []
        const size = 38
        let track = 0

        for (const colour of colours) {
            const noChange = track
            elements.push(
            <Stack key={colour} horizontalAlign={'center'} style={{backgroundColor: colour, width: size, height: size, borderRadius: size/2, cursor: 'pointer'}} 
                onClick={() => onColorSelected(noChange)}>
                {selectedIndex == track ? 
                <Stack horizontal verticalAlign={'center'} style={{height: '100%'}}>{IconsView.iconView(Icons.Checkmark24Filled, '#ffffff')}</Stack> : null }
            </Stack>
            )
            track++
        }

        return elements
    }

    const onColorSelected = (index: number) => {
        setSelectedIndex(index)
        textRef.current?.focus()
    }

    const buttonSize: number = 44

    return (
        <Panel isLightDismiss type={PanelType.custom} customWidth={'400px'} isOpen={props.isOpen} onDismiss={onCloseClick} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.backgroundColor.color()}, main: {backgroundColor: styles.prColors.backgroundColor.color()}, content: {paddingLeft: '16px', paddingRight: '16px'}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton onClick={onCloseClick} style={{width: buttonSize, height: buttonSize}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        {showConfirm ? 
                        <PRButton onClick={onCompleteClick} style={{width: buttonSize, height: buttonSize}} icon={Icons.Checkmark24Filled} color={styles.prColors.primaryColor.color()}/> : null}
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <div style={{position: 'relative', marginTop: '30px'}}>
                        <TextField autoFocus componentRef={textRef} value={tagValue} borderless resizable={false} onChange={textDidChange} style={{ padding: '10px', paddingLeft: '40px', paddingRight: '50px', fontSize: '21px', fontWeight: 600, backgroundColor: styles.prColors.backgroundColor.color(), color: styles.prColors.textColor.color(), lineHeight: '2em'}} onKeyDown={onKeyDownAction}/>
                        {tagValue.length > 0 ? null :
                        <div style={{position: 'absolute', top: 5, left: 40, pointerEvents: 'none'}}>
                            <Text style={{color: styles.prColors.textColor.color('aa'), fontSize: '21px'}}>{t('tag_tag_name')}</Text>
                        </div>}
                        <div style={{position: 'absolute', top: 5, left: 5, width: '24px', height: '24px'}}>
                            {IconsView.iconView(Icons.Tag24Regular, styles.prColors.textColor.color())}
                        </div>
                        <div style={{position: 'absolute', top: -4, right: 5}}>
                            <PRButton onClick={addTag} style={{width: buttonSize, height: buttonSize}} icon={Icons.Add24Filled} color={tagValue.length > 0 ? styles.prColors.primaryColor.color() : styles.prColors.iconDisabledColor.color('a6')} />
                        </div>
                        <div style={{position: 'absolute', bottom: -10, left: 5, right: 5, height: 1, backgroundColor: styles.prColors.fileBorderColor.color()}}></div>
                        <div style={{position: 'absolute', bottom: '-30px', right: 5}}>
                            <Text style={{color: styles.prColors.subTextColor.color(), fontSize: 12}}>
                                {40 - tagValue.length}
                            </Text>
                        </div>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal style={{marginTop: '50px', justifyContent: 'space-evenly'}}>
                        {renderColors()}
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <div style={{marginTop: '30px'}}>
                        {activeTagsContent()}
                    </div>
                </Stack.Item>
            </Stack>
        </Panel>
    )
}

export default TagPanel