import { Stack, Text } from '@fluentui/react'
import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Mode, useLightSwitch } from 'use-light-switch'
import { useStyles } from '../assets/styles'
import { IconsView } from '../shared/utils/icons'
import { useTranslation } from 'react-i18next'
import { IImageCache, useGroupsState, useImageCache } from '../shared/stores/stores'
import { IItem, ItemType } from '../shared/stores/types'
import { useUtils } from '../shared/utils/utils'

interface IProps {
    item: IItem,
    itemImage?: IImageCache,
    onClick: (item: IItem | null) => void,
    isPremium: boolean,
    index: number,
    isEmpty: boolean
}

const RecentCell = (props: IProps) => {

    const { t } = useTranslation()
    const mode = useLightSwitch()
    const useDark = mode === Mode.Dark
    const styles = useStyles()
    const [hover, setHover] = useState<boolean>(false)
    const { groups } = useGroupsState()
    const { isValidHex } = useUtils()
    const { setImageCacheError } = useImageCache()

    const onHover = () => {
        setHover(true)
    }

    const onHoverLeave = () => {
        setHover(false)
    }

    const iconTintColor = () => {
        if (props.item.type === ItemType.Text && isValidHex(props.item)) {
            return props.item.content
        }
        return styles.colors.iconColor
    }

    const groupName = (): string => {
        const group = groups.find( group => group.uid == props.item.owner )
        if (group != null) {
            return group.name
        }
        return ''
    }

    const gradient = () => {
        if (useDark) {
            return 'linear-gradient(180deg, rgba(0,0,0,0) 30%, rgba(0,0,0,0.75))'
        }
        return 'linear-gradient(180deg, rgba(255,255,255,0) 30%, rgba(255,255,255,0.75))'
    }

    const onClick = () => {
        if (props.isEmpty) {
            return
        }
        props.onClick(allowed() ? props.item : null)
    }

    const textColor = () => {
        if (props.item.type == ItemType.Link) {
            return styles.prColors.primaryColor.color()
        }
        return styles.prColors.textColor.color()
    }

    const allowed = (): boolean => {
        return (props.isPremium || props.item.type == ItemType.Text || props.index == 0)
    }

    const renderContent = () => {
        if (props.isEmpty) {
            return (
                <Stack style={{position: 'absolute', width: '100%', height: '100%', borderRadius: 8}} verticalAlign={'center'} horizontalAlign={'center'} onMouseLeave={onHoverLeave} onMouseOver={onHover}>
                    <Stack>
                        <Text style={{color: styles.prColors.subTextColor.color(), fontSize: 14, textAlign: 'center'}}>Nothing to see here<br></br>right now 😎</Text>
                    </Stack>
                </Stack>
            )
        }
        if (allowed()) {
            return (
                <Stack style={{position: 'absolute', width: '100%', height: '100%'}} verticalAlign={'space-between'} onMouseLeave={onHoverLeave} onMouseOver={onHover}>
                    <Stack.Item>
                        <div style={{marginTop: 12, marginLeft: 12}}>
                            {IconsView.iconView(IconsView.getIconName(props.item), iconTintColor())}
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack style={{marginBottom: 12, marginLeft: 12, marginRight: 12}}>
                            <Text className={'multi-3line-ellipsis'} style={{color: textColor(), fontSize: 16}}>{props.item.content}</Text>
                            <Text style={{color: styles.prColors.subTextColor.color(), fontSize: 14, marginTop: 4}}>{groupName()}</Text>
                        </Stack>
                    </Stack.Item>
                </Stack>
            )
        } else {
            return (
                <Stack style={{position: 'absolute', width: '100%', height: '100%', borderRadius: 8}} verticalAlign={'center'} horizontalAlign={'center'} onMouseLeave={onHoverLeave} onMouseOver={onHover}>
                    <Stack>
                        <Text style={{color: styles.prColors.subTextColor.color(), fontSize: 14, textAlign: 'center', whiteSpace: 'pre-line'}}>{`${t('recent_premium_placeholder')} 💎`}</Text>
                    </Stack>
                </Stack>
            )
        }
    }

    const renderImage = () => {
        if (props.isEmpty) {
            return null
        }
        if (props.item.type == ItemType.Image && allowed()) {
            let src = props.item.type == ItemType.Image ? props.itemImage?.downloadUrl : props.itemImage?.videoThumbnailUrl
            if (props.itemImage?.hasError == true) {
                src = undefined
            }
            const show = (props.itemImage?.downloadUrl || props.itemImage?.videoThumbnailUrl) && props.itemImage.hasError !== true ? 1 : 0

            return (
                <div style={{backgroundColor: '#00ff0000', position: 'absolute', width: '100%', height: '100%', borderRadius: 8}}>
                    <LazyLoadImage
                            key={props.item.uid}
                            style={{objectFit: 'cover', opacity: show, borderRadius: '8px', imageOrientation: 'from-image', display: props.itemImage != null ? 'block' : 'none'}}
                            src={src}
                            width={220}
                            height={140}
                            draggable={false}
                            onError={() => {
                                setImageCacheError(props.item.uid)
                            }} />
                </div>)
        }
        return null
    }
    
    return (
        <div style={{width: 220, height: 140, marginRight: 12, marginBottom: 12, borderRadius: 8, border: '2px solid ' + styles.prColors.cellSeperatorColor.color(), backgroundColor: hover ? styles.prColors.cellSeperatorColor.color() : undefined, cursor: 'pointer', position: 'relative'}} onClick={onClick}>

            {renderImage()}
            {props.itemImage != null && allowed() ?
            <div style={{position: 'absolute', width: '100%', height: '100%', borderRadius: 8, backgroundImage: gradient()}}>
            </div> : undefined }

            {renderContent()}

        </div>
    )
}
export default RecentCell