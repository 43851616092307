import { CSSProperties, useEffect } from 'react'
import animationData from '../assets/lottie_loading.json'
import { Stack, Text } from '@fluentui/react'
import { merge, useStyles } from '../assets/styles'
import { signOut } from 'firebase/auth'
import { useGroupsState, useImageCache, useItemsState, useLinkPreviewCacheState, usePremiumDialogState, usePremiumState, useRecentSelectedState, useRootDisplayState, useSelectedGroupState, useStorageSizeState, useStorageUploadingState, useTagsState, useToast } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { clearSettings, setLastSelectedGroupId } from '../shared/utils/settings'
import { LoadingType } from '../shared/stores/types'
import { useUtils } from '../shared/utils/utils'
import { AnalyticsService, PREvent } from '../shared/services/analyticsService'
import { AuthService } from '../shared/services/authService'
import { FirestoreService } from '../shared/services/firestoreService'
import Lottie from 'react-lottie-player'

interface IProps {
    title: string,
    type: LoadingType
}

const FullScreenLoadingView = (props: IProps) => {

    const styles = useStyles()
    const { clearImageCache } = useImageCache()
    const { clearLinkPreviewCache } = useLinkPreviewCacheState()

    const { resetGroups } = useGroupsState()
    const { resetItems } = useItemsState()
    const { resetTags } = useTagsState()
    const { resetSelectedGroup } = useSelectedGroupState()
    const { resetToast } = useToast()
    const { resetPremium } = usePremiumState()
    const { resetUploading } = useStorageUploadingState()
    const { resetStorageSize } = useStorageSizeState()
    const { hidePremiumDialog } = usePremiumDialogState()
    const { delay } = useUtils()
    const { setIsRecentSelected } = useRecentSelectedState()

    const authService = container.resolve(AuthService)
    const firestoreService = container.resolve(FirestoreService)
    const analyticsService = container.resolve(AnalyticsService)

    const resetAll = () => {
        setLastSelectedGroupId(null)
        resetGroups()
        resetItems()
        resetTags()
        resetSelectedGroup()
        resetToast()
        resetPremium()
        resetUploading()
        resetStorageSize()
        hidePremiumDialog()
        clearImageCache()
        clearLinkPreviewCache()
        clearSettings()
        setIsRecentSelected(false)
    }

    const logout = async () => {
        resetAll()
        await firestoreService.deleteDevice()
        const auth = authService.auth
        await signOut(auth)

        await delay(1000) // for drama
        window.location.reload()
    }

    useEffect(() => {
        if (props.type === LoadingType.Logout) {
            logout()
        }
        else if (props.type === LoadingType.Delete) {
            deleteAccount()
        }
    }, [])

    const deleteAccount = async () => {
        resetAll()
        await firestoreService.deleteDevice()
        await firestoreService.deleteUser()
        const auth = authService.auth
        await signOut(auth)
        analyticsService.log(PREvent.deleteAccount)

        await delay(1000) // for drama
        window.location.reload()
    }

    const overlayStyle: CSSProperties = props.type === LoadingType.Overlay ? {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0} : {}
    const sizeStyle: CSSProperties = {backgroundColor: styles.prColors.menuBackgroundColor.color(), height: '100vh'}
    const title: CSSProperties = {
        fontSize: 32,
        fontWeight: 500,
        fontFamily: 'Rubik',
        color: styles.prColors.subTextColor.color()
    };

    return (
        <div style={merge(overlayStyle, sizeStyle)}>
            <Stack style={{display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
                <Text style={title}>
                    {props.title}
                </Text>
                <Lottie loop play animationData={animationData} style={{width: 100, height: 100}}/>
            </Stack>
        </div>
    )
}

export default FullScreenLoadingView

