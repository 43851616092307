import { CSSProperties } from 'react';
import { createTheme, ITheme } from '@fluentui/react';
import { Mode, useLightSwitch } from 'use-light-switch'

export enum ToastType {
    Announcement,
    Regular,
    Warning,
    Danger
}

export enum Theme {
    Light,
    Dark
}

export const useStyles = () => {
    const mode = useLightSwitch()
    const useDark = mode === Mode.Dark
    const styles = new Styles(useDark)

    return styles
}

export class Styles {

    private theme: Theme = Theme.Light
    prColors: PRColors

    constructor(useDark: boolean) {
        this.prColors = {
            menuBackgroundColor: new PRColor(NeutralColors.gray50, NeutralColors.gray900, useDark),
            topButtonColor: new PRColor('#fafafa', NeutralColors.gray800, useDark),
            backgroundColor: new PRColor(NeutralColors.white, '#161616', useDark),
            dialogBackgroundColor: new PRColor(NeutralColors.white, NeutralColors.gray900, useDark),
            bannerBackgroundColor: new PRColor('#0078d4', '#0E0E0E', useDark),
            textColor: new PRColor(NeutralColors.gray900, NeutralColors.gray100, useDark),
            subTextColor: new PRColor(NeutralColors.gray500, NeutralColors.gray400, useDark),
            textBorderColor: new PRColor(NeutralColors.gray600, NeutralColors.gray300, useDark),
            cellSeperatorColor: new PRColor(NeutralColors.gray100, '#0E0E0E', useDark),
            cellHoverColor: new PRColor(NeutralColors.gray100, NeutralColors.gray800, useDark),
            fileBorderColor: new PRColor(NeutralColors.gray400, NeutralColors.gray600, useDark),
            iconDisabledColor: new PRColor('#ACACAC', '#6E6E6E', useDark),
            primaryColor: new PRColor('#0078d4', '#0078d4', useDark),
            errorColor: new PRColor('#D92C2C', '#D92C2C', useDark),
            pinColor: new PRColor('#e8912d', '#e8912d', useDark),
            pinBgColor: new PRColor('#e8912d1a', '#e8912d33', useDark),
            countBgColor: new PRColor('#ffffffe6', '#ffffff33', useDark),
            countDialogBgColor: new PRColor('#0078D433', '#ffffff33', useDark),
            countTextColor: new PRColor('#0078d4', '#ffffff', useDark),
            countBgErrorColor: new PRColor('#E63237', '#E63237', useDark),
            selectedBgColor: new PRColor('#0078d433', '#0078d466', useDark),
            selectedDeleteBgColor: new PRColor('#D92C2C33', '#D92C2C66', useDark),
            selectedHoverBgColor: new PRColor('#0078d41a', '#0078d433', useDark),
            selectedHoverDeleteBgColor: new PRColor('#D92C2C1a', '#D92C2C33', useDark),
            greenColor: new PRColor('#11910D', '#5EC75A', useDark),
            linkPreviewBgColor: new PRColor('#f1f1f1', '#373737', useDark),
            textInputBgColor: new PRColor('#ffffff', '#0E0E0E', useDark)
        }
    }

    colors: Colors = {
        themePrimary: '#0078d4',
        themeLighterAlt: '#eff6fc',
        themeLighter: '#deecf9',
        themeLight: '#c7e0f4',
        themeTertiary: '#71afe5',
        themeSecondary: '#2b88d8',
        themeDarkAlt: '#106ebe',
        themeDark: '#005a9e',
        themeDarker: '#004578',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
        softRed: '#ED4337',
        primaryTint40: '#EFF6FC',
        bgErrorBadgeColor: '#FFEBEB',
        textErrorBadgeColor: '#BB2424',
        buttonColor: '#ffffff',
        iconColor: '#616161'
    }

    getToastBgColor = (type: ToastType, mode: Mode): string => {
        const useDark = mode === Mode.Dark
        switch (type) {
            case ToastType.Warning:
                return new PRColor('#FFF8DF', '#FFD335', useDark).color()
            case ToastType.Danger:
                return new PRColor('#F9D9D9', '#D92C2C', useDark).color()
            case ToastType.Regular:
                return new PRColor(NeutralColors.gray100, NeutralColors.gray700, useDark).color()
            default:
                return new PRColor('#c7e0f4', '#0078d4', useDark).color()
        }
    }
    
    getToastTextColor = (type: ToastType, mode: Mode): string => {
        const useDark = mode === Mode.Dark
        switch (type) {
            case ToastType.Warning:
                return new PRColor('#816B1B', NeutralColors.gray900, useDark).color()
            case ToastType.Danger:
                return new PRColor('#A52121', NeutralColors.gray900, useDark).color()
            case ToastType.Regular:
                return this.prColors.textColor.color()
            default:
                return new PRColor('#0078d4', NeutralColors.gray900, useDark).color()
        }
    }

    getShimmerColors = (mode: Mode): ITheme => {
        const useDark = mode === Mode.Dark

        const background = new PRColor(NeutralColors.white, '#000000', useDark)
        const shimmer = new PRColor(NeutralColors.gray100, NeutralColors.gray800, useDark)
        const shimmerWave = new PRColor(NeutralColors.gray50, NeutralColors.gray600, useDark)
        return createTheme({
        palette: {
            themePrimary: background.color(),
            neutralLight: shimmer.color(),
            neutralLighter: shimmerWave.color(),
            white: this.prColors.backgroundColor.color()
        }});
    }

    setTheme = (theme: Theme) => {
        this.theme = theme
    }

    whiteTextColor: CSSProperties = {
        color: this.colors.neutralLighterAlt
    }

    bodySize: CSSProperties = {
        fontSize: 16
    }

    subBodySize: CSSProperties = {
        fontSize: 14
    }

    rubikFont: CSSProperties = {
        fontFamily: 'Rubik'
    }

    applyTheme = () => {

    // const myTheme = createTheme({
    //     palette: {
    //         themePrimary: '#0078d4',
    //         themeLighterAlt: '#eff6fc',
    //         themeLighter: '#deecf9',
    //         themeLight: '#c7e0f4',
    //         themeTertiary: '#71afe5',
    //         themeSecondary: '#2b88d8',
    //         themeDarkAlt: '#106ebe',
    //         themeDark: '#005a9e',
    //         themeDarker: '#004578',
    //         neutralLighterAlt: '#faf9f8',
    //         neutralLighter: '#0078d41a',
    //         neutralLight: '#0078d433',
    //         neutralQuaternaryAlt: '#e1dfdd',
    //         neutralQuaternary: '#d0d0d0',
    //         neutralTertiaryAlt: '#c8c6c4',
    //         neutralTertiary: '#a19f9d',
    //         neutralSecondary: '#605e5c',
    //         neutralPrimaryAlt: '#3b3a39',
    //         neutralPrimary: '#323130',
    //         neutralDark: '#201f1e',
    //         black: '#000000',
    //         white: '#ffffff',
    //     }});
    //     Customizations.applySettings({theme: myTheme})
    }

}

export const merge = (...styles: CSSProperties[]) => {
    let merged: CSSProperties = {}
    for (const style of styles) {
        merged = {...merged, ...style}
    }
    return merged
}

class PRColor {

    private light: string
    private dark: string
    private useDark: boolean

    constructor(light: string, dark: string, useDark: boolean) {
        this.light = light
        this.dark = dark
        this.useDark = useDark
    }

    color = (withAlpha: string = 'ff'): string => {
        if (this.useDark) {
            return this.dark + (this.dark.length < 8 ? withAlpha : '')
        }
        return this.light + (this.light.length < 8 ? withAlpha : '')
    }
}

// this matches mobile colours
export const NeutralColors = {
    gray20: '#69797E',
    gray25: '#f8f8f8',
    gray30: '#7a7574',
    gray40: '#393939',
    gray50: '#f1f1f1',
    gray100: '#e1e1e1',
    gray200: '#c8c8c8',
    gray300: '#acacac',
    gray400: '#919191',
    gray500: '#6e6e6e',
    gray600: '#404040',
    gray700: '#303030',
    gray800: '#292929',
    gray900: '#212121',
    gray950: '#141414',
    white: '#ffffff',
    black: '#000000'
}

export interface PRColors {
    menuBackgroundColor: PRColor
    backgroundColor: PRColor
    dialogBackgroundColor: PRColor
    textColor: PRColor
    subTextColor: PRColor
    primaryColor: PRColor
    errorColor: PRColor
    textBorderColor: PRColor
    cellSeperatorColor: PRColor
    cellHoverColor: PRColor
    bannerBackgroundColor: PRColor
    topButtonColor: PRColor
    fileBorderColor: PRColor
    iconDisabledColor: PRColor
    pinColor: PRColor
    pinBgColor: PRColor
    countBgColor: PRColor
    countDialogBgColor: PRColor
    countTextColor: PRColor
    countBgErrorColor: PRColor
    selectedBgColor: PRColor
    selectedDeleteBgColor: PRColor
    selectedHoverBgColor: PRColor
    selectedHoverDeleteBgColor: PRColor
    greenColor: PRColor
    linkPreviewBgColor: PRColor
    textInputBgColor: PRColor
}

export interface Colors {
    themePrimary: string
    themeLighterAlt: string
    themeLighter: string
    themeLight: string
    themeTertiary: string
    themeSecondary: string
    themeDarkAlt: string
    themeDark: string
    themeDarker: string
    neutralLighterAlt: string
    neutralLighter: string
    neutralLight: string
    neutralQuaternaryAlt: string
    neutralQuaternary: string
    neutralTertiaryAlt: string
    neutralTertiary: string
    neutralSecondary: string
    neutralPrimaryAlt: string
    neutralPrimary: string
    neutralDark: string
    black: string
    white: string
    softRed: string
    primaryTint40: string
    bgErrorBadgeColor: string
    textErrorBadgeColor: string
    buttonColor: string
    iconColor: string
}

// const LightThemeColors = {
//       themePrimary: '#0078d4',
//       themeLighterAlt: '#eff6fc',
//       themeLighter: '#deecf9',
//       themeLight: '#c7e0f4',
//       themeTertiary: '#71afe5',
//       themeSecondary: '#2b88d8',
//       themeDarkAlt: '#106ebe',
//       themeDark: '#005a9e',
//       themeDarker: '#004578',
//       neutralLighterAlt: '#faf9f8',
//       neutralLighter: '#f3f2f1',
//       neutralLight: '#edebe9',
//       neutralQuaternaryAlt: '#e1dfdd',
//       neutralQuaternary: '#d0d0d0',
//       neutralTertiaryAlt: '#c8c6c4',
//       neutralTertiary: '#a19f9d',
//       neutralSecondary: '#605e5c',
//       neutralPrimaryAlt: '#3b3a39',
//       neutralPrimary: '#323130',
//       neutralDark: '#201f1e',
//       black: '#000000',
//       white: '#ffffff',
//     }



    // const myTheme = createTheme({
    //     palette: {
    //       themePrimary: '#0078d4',
    //       themeLighterAlt: '#eff6fc',
    //       themeLighter: '#deecf9',
    //       themeLight: '#c7e0f4',
    //       themeTertiary: '#71afe5',
    //       themeSecondary: '#2b88d8',
    //       themeDarkAlt: '#106ebe',
    //       themeDark: '#005a9e',
    //       themeDarker: '#004578',
    //       neutralLighterAlt: '#393939',
    //       neutralLighter: '#414141',
    //       neutralLight: '#4e4e4e',
    //       neutralQuaternaryAlt: '#565656',
    //       neutralQuaternary: '#5d5d5d',
    //       neutralTertiaryAlt: '#797979',
    //       neutralTertiary: '#fafafa',
    //       neutralSecondary: '#fbfbfb',
    //       neutralPrimaryAlt: '#fcfcfc',
    //       neutralPrimary: '#f8f8f8',
    //       neutralDark: '#fdfdfd',
    //       black: '#fefefe',
    //       white: '#303030',
    //     }});

    // Customizations.applySettings({theme: myTheme})