import { Text } from '@fluentui/react'
import { Stack} from '@fluentui/react'
import { PRButton, Icons } from '../shared/utils/icons'
import { ITag } from '../shared/stores/types'

interface IProps {
    tag: ITag,
    isSmall: boolean,
    onClick?: (tag: ITag) => void
}

const BadgeView = (props: IProps) => {
    const finalOuterStyle = () => {
        if (props.isSmall) {
            return { backgroundColor: props.tag.color + '1a', borderRadius: '4px', paddingLeft: '4px', paddingRight: '4px', paddingBottom: '4px' }
        }
        return { backgroundColor: props.tag.color, borderRadius: '6px', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '16px', paddingRight: '8px' }
    }

    const finalTexStyle = () => {
        if (props.isSmall) {
            return {color: props.tag.color, fontSize: 12, fontWeight: 600}
        }
        return {color: '#ffffff', fontSize: 16, fontWeight: 600}
    }
    
    const onClick = () => {
        if (props.onClick !== undefined) {
            props.onClick(props.tag)
        }
    }

    return (
        <div style={finalOuterStyle()}>
            {!props.isSmall ?
            <Stack horizontal verticalAlign={'center'} horizontalAlign={'stretch'}>
                <Stack.Item grow>
                    <Text className={'multi-1line-ellipsis'} style={finalTexStyle()}>{props.tag.name}</Text>
                </Stack.Item>                    
                <Stack.Item>
                    <PRButton style={{width: 40, height: 40}} icon={Icons.Dismiss24Filled} color={'#ffffff'} onClick={onClick}/>
                </Stack.Item>
            </Stack> :
            <Text style={finalTexStyle()}>{props.tag.name}</Text>}
        </div>
    )
}

export default BadgeView