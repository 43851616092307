import Login from './login';
import Dashboard from './dashboard';
import FullScreenLoadingView from './fullScreenLoadingView';
import { useParams } from 'react-router'
import { useRootDisplayState } from '../shared/stores/stores';
import { LoadingType, RootDisplayType } from '../shared/stores/types';
import { useTranslation } from 'react-i18next';
import SingleItem from './singleItem';

type IParams = {
    notification: string
}

const DashboardRoot = () => {

    const { t } = useTranslation()
    const { rootDisplayType } = useRootDisplayState();

    document.title = 'Dmyst | Dashboard'

    const params = useParams<IParams>()

    const render = () => {
        switch (rootDisplayType) {
            case RootDisplayType.login:
                return <Login />
            case RootDisplayType.logout:
                return <FullScreenLoadingView title={`${t('logout_logging_out')}...`} type={LoadingType.Logout}/>
            case RootDisplayType.delete:
                return <FullScreenLoadingView title={`${t('logout_deleting_account')}...`} type={LoadingType.Delete}/>
            case RootDisplayType.dashboard:
                return params.notification != null ? <SingleItem token={params.notification} /> : <Dashboard />
            default:
                return <div/>
        }
    }

    return render()
}

export default DashboardRoot;