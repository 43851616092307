import {useState, useEffect, CSSProperties} from 'react'
import { Text, Stack, Image, PrimaryButton, DefaultButton } from '@fluentui/react'
import QRCode from 'react-qr-code'
import { Styles } from '../assets/styles'
import app_logo from '../assets/app_logo.png';
import {isMobile} from 'react-device-detect';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore'
import { Trans, useTranslation } from 'react-i18next'
import { container } from 'tsyringe'
import { IQuick, ItemType } from '../shared/stores/types';
import { FirestoreService } from '../shared/services/firestoreService';

const QuickSend = () => {

    const { t } = useTranslation()
    const [sendKey, setSendKey] = useState('')
    const [quick, setQuick] = useState<IQuick>({ type: ItemType.Empty, content: '', timestamp: 0 })
    const [copyBtnText, setCopyBtnText] = useState(t('button_copy_to_clipboard'))

    const styles = new Styles(false)
    const firestoreService = container.resolve(FirestoreService)

    useEffect(() => {
        let listener: (() => void) | null = null

        if (quick.content.length == 0) {
            const genKey = firestoreService.generateQuickSend()
            setSendKey(genKey)
            listener = firestoreService.observeQuick(genKey, (value: DocumentSnapshot<DocumentData>) => {
                if (value.data() !== undefined) {
                    const quickItem = value.data() as IQuick
                    if (quickItem.content.length > 0) {
                        setQuick(quickItem)
                        if (listener != null) {
                            listener()
                        }
                        firestoreService.deleteQuick(value.id)
                    }
                }
            })
        }

        return () => {
            if (listener != null) {
                listener()
            }
        }
    }, [quick])

    const renderOtherActions = () => {
        if (quick.type === ItemType.Link) {
            return <PrimaryButton text={t('menu_popup_open_link')} onClick={onOpenLink}/>
        }
        else if (quick.type === ItemType.Image || quick.type === ItemType.File) {
            return <PrimaryButton text={t('quick_send_download')} onClick={onOpenLink}/> 
        }
        return null
    }

    const onReset = () => {
        setQuick({ type: ItemType.Empty, content: '', timestamp: 0 })
    }

    const onCopyClick = () => {
        setCopyBtnText(t('general_copied'))
        setTimeout(() => {
            setCopyBtnText(t('button_copy_to_clipboard'))
        }, 3000)
    }

    const onOpenLink = () => {
        let link = quick.content
        if (!link.startsWith('http')) {
            link = 'http://' + link
        }
        window.open(link, '_blank') 
    }

    const stepsStyle: CSSProperties = {color: styles.prColors.subTextColor.color(), fontSize: '20px', textAlign: 'start'}
    const stepsBoldStyle: CSSProperties = {color: styles.prColors.subTextColor.color(), fontSize: '20px', textAlign: 'start', fontWeight: 'bold'}
    const noteStyle: CSSProperties = {color: styles.prColors.subTextColor.color(), fontSize: '14px', textAlign: 'start', marginTop: '24px'}

    return (
        <Stack horizontal style={{height: '100vh', backgroundSize: 'auto, cover, cover', backgroundImage: 'url("https://dmystapp.com/welcome/assets/css/images/overlay2.png"), url("https://dmystapp.com/welcome/assets/css/images/overlay3.svg"), linear-gradient(45deg, rgba(62,140,255,1) 30%, rgba(129,49,244,1) 70%, rgba(129,49,244,1))'}}>
            <Stack horizontal verticalAlign={'center'} style={{height: '100%', width: '100%'}}>
                <Stack horizontalAlign={'center'} style={{justifyContent: 'center', width: '100%', marginBottom: '80px'}}>
                    <Stack horizontal verticalAlign={'center'} style={{marginBottom: '32px'}}>
                        <Image src={app_logo} width={32} height={32}/>
                        <Text style={{color: 'white', fontSize: '32px', fontWeight: 600, marginLeft: '10px'}}>Dmyst Quick Send</Text>
                    </Stack>
                    <Stack horizontalAlign={'start'} style={{backgroundColor: styles.prColors.backgroundColor.color(), maxWidth: '700px', padding: '40px', borderRadius: '8px', boxShadow: '3px 3px 3px 2px #00000033'}}>
                        <Text style={{color: styles.prColors.subTextColor.color(), fontSize: '32px', fontWeight: 400}}>{t('quick_send_web_title')}:</Text>
                        {quick.content.length == 0 ?
                        <Stack horizontalAlign={'start'} style={{marginTop: '32px', borderRadius: '8px', backgroundImage: 'linear-gradient(45deg, rgba(62,140,255,1) 30%, rgba(129,49,244,1) 70%, rgba(129,49,244,1))'}}>
                            <div style={{backgroundColor: 'white', margin: '5px', paddingTop: '8px', paddingLeft: '8px', paddingRight: '8px', paddingBottom: '6px', borderRadius: '4px'}}>
                                <QRCode value={sendKey} size={100} bgColor={'#ffffff'}/>
                            </div>
                        </Stack> :
                        <Stack horizontalAlign={'start'} style={{marginTop: '32px', borderRadius: '8px', width: '100%', padding: '5px', backgroundImage: 'linear-gradient(45deg, rgba(62,140,255,1) 30%, rgba(129,49,244,1) 70%, rgba(129,49,244,1))'}}>
                            <Stack horizontalAlign={'center'} style={{backgroundColor: '#ffffff', width: '100%', padding: '16px', borderRadius: '4px'}}>
                                <Text className={'multi-3line-ellipsis'} style={{color: styles.prColors.subTextColor.color(), fontSize: isMobile ? '16px' : '20px', textAlign: 'center', marginBottom: '16px'}}>
                                    {quick.content}
                                </Text>
                                <Stack horizontal={isMobile ? false : true} tokens={{childrenGap: '16px'}}>
                                    <DefaultButton text={t('quick_send_reset')} onClick={onReset}/>
                                        <PrimaryButton text={copyBtnText} onClick={onCopyClick}/>
                                    {renderOtherActions()}
                                </Stack>
                            </Stack>
                        </Stack> }
                        <Stack style={{marginTop: '32px'}} tokens={{childrenGap: '16px'}}>
                            <Text style={stepsStyle}><Trans i18nKey={'quick_send_step_1'} components={{1: <Text style={stepsBoldStyle}></Text>}}></Trans></Text>
                            <Text style={stepsStyle}>{t('quick_send_step_2')}</Text>
                            <Text style={stepsStyle}><Trans i18nKey={'quick_send_step_3'} components={{1: <Text style={stepsBoldStyle}></Text>}}></Trans></Text>
                            <Text style={stepsStyle}>{t('quick_send_step_4')}</Text>
                        </Stack>
                        
                        <Text style={noteStyle}><Trans i18nKey={'quick_send_footer'} components={{1: <a href={'https://file.io'} target={'_blank'} rel="noreferrer"></a>}}></Trans></Text>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default QuickSend