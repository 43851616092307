import { container } from 'tsyringe'
import { lastSelectedGroupId, setLastSelectedGroupId } from '../utils/settings'
import { AuthService } from '../services/authService'
import { FirestoreService } from '../services/firestoreService'
import { useGroupsState, useSelectedGroupState } from '../stores/stores'
import { CollectionKey, IGroup } from '../stores/types'
import { useModelFactory } from './useModelFactory'

export const useGlobalData = () => {
    const authService = container.resolve(AuthService)
    const firestoreService = container.resolve(FirestoreService)

    const { setGroups } = useGroupsState()
    const { setSelectedGroup } = useSelectedGroupState()
    const { createUser } = useModelFactory()

    const setup = async () => {
        const firebaseUser = authService.user
        if (firebaseUser == null) {
            return
        }

        const groupsResult = await firestoreService.query<IGroup>(CollectionKey.Groups, 'owner', firebaseUser.uid)
        setGroups(groupsResult)

        if (lastSelectedGroupId() == null && groupsResult.length > 0) {
            setLastSelectedGroupId(groupsResult[0].uid)
        }

        const findGroup = groupsResult.find(g => g.uid == lastSelectedGroupId())
        if (findGroup != null) {
            setSelectedGroup(findGroup)
        }

        const user = createUser(firebaseUser.uid, firebaseUser.email ?? '')
        await firestoreService.add(CollectionKey.Users, user.uid, user)
    }

    return { setup }
}