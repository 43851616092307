import { container } from 'tsyringe'
import { FirebaseService } from '../services/firebaseService'
import { useLinkPreviewCacheState } from '../stores/stores'
import { IItem, ItemType, ILinkPreview } from '../stores/types'

interface LinkPreviewReqBody {
    url: string
}

interface LinkPreviewResBody {
    uid: string
    title: string
    description: string
}

export const useLinkPreviewCaching = () => {
    const firebaseService = container.resolve(FirebaseService)
    const { cache, addLinkPreview, clearLinkPreviewCache } = useLinkPreviewCacheState()

    const processLinks = async (items: IItem[]) => {
        const call = firebaseService.getHttpsCallable<LinkPreviewReqBody, LinkPreviewResBody>('linkPreview2')
        for (const item of items) {
            if (item.type === ItemType.Link) {
                if (cache.find(i => i.uid == item.uid) != null) {
                    continue
                }

                let updatedLink = item.content
                if (!updatedLink.startsWith('http')) {
                    updatedLink = `https://${item.content}`
                }
                
                const result = await call({ url: updatedLink })
                const data = result.data

                const linkPreview: ILinkPreview = { uid: item.uid, title: '', description: '' }
                if (data != null && data.title != null) {
                    linkPreview.title = data.title
                    linkPreview.description = data.description ?? ''
                }

                addLinkPreview(linkPreview)
            }
        }
    }

    const cleanupLinkPreviewCache = () => {
        clearLinkPreviewCache()
    }

    return { processLinks, cleanupLinkPreviewCache }
}