import { Panel, Stack, Text, PanelType, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react'
import { PRButton, Icons } from '../shared/utils/icons'
import { useStyles } from '../assets/styles'
import { useEffect, useState } from 'react'
import animationData from '../assets/lottie_confetti.json'
import { useTranslation } from 'react-i18next'
import { useSelectedGroupState } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { FirebaseService } from '../shared/services/firebaseService'
import { FirestoreService } from '../shared/services/firestoreService'
import { IItem, ItemType } from '../shared/stores/types'
import { useModelFactory } from '../shared/viewModel/useModelFactory'
import Lottie from 'react-lottie-player'

interface IProps {
    isOpen: boolean,
    item: IItem | null,
    onDismiss: () => void
}

interface ShortenLinkReqBody {
    url: string
}

interface ShortenLinkResBody {
    url: ShortenLinkUrl
}

interface ShortenLinkUrl {
    shortLink: string
}

const ShortenPanel = (props: IProps) => {

    const { t } = useTranslation()
    const styles = useStyles()
    const [shortLink, setShortLink] = useState<string>('')
    const [copyText, setCopyText] = useState<string>(t('menu_popup_copy'))
    const [messageText, setMessageText] = useState<string>(`${t('shorten_shortening')}...`)
    const { selectedGroup } = useSelectedGroupState()
    const { createItem } = useModelFactory()
    const firebaseService = container.resolve(FirebaseService)
    const firestoreService = container.resolve(FirestoreService)

    useEffect(() => {
        const item = props.item
        if (item != null && props.isOpen) {
            const call = firebaseService.getHttpsCallable<ShortenLinkReqBody, ShortenLinkResBody>('shortenLink2')
            call({ url: item.content }).then(result => {
                const data = result.data;
                if (data != null && data.url != null && data.url.shortLink != null) {
                    const link = data.url.shortLink as string
                    setShortLink(link)
                }
                else {
                    setMessageText(t('error_oops'))
                }
            })
        } else {
            setShortLink('')
            setCopyText(t('menu_popup_copy'))
            setMessageText(`${t('shorten_shortening')}...`)
        }
    }, [props.isOpen])

    const onCopyClick = () => {
        navigator.clipboard.writeText(shortLink)
        setCopyText(t('toast_copied_clipboard'))
    }

    const onAddGroupClick = () => {
        const groupId = selectedGroup?.uid
        if (groupId != null) {
            const item = createItem(groupId, shortLink, ItemType.Link)
            firestoreService.addItem(item)
            props.onDismiss()
        }
    }

    const shortLinkRender = () => {
        if (shortLink.length > 0) {
            return (
                <div style={{position: 'relative'}}>

                    <Stack horizontalAlign={'center'} style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                        <Lottie loop play animationData={animationData} style={{width: 340, height: 340}}/>
                    </Stack>
                    <Stack horizontalAlign={'center'}>
                        <Text style={{fontSize: 22, color: styles.prColors.subTextColor.color(), marginTop: 24}}>{shortLink}</Text>
                        <Text style={{fontSize: 32, color: styles.prColors.subTextColor.color(), marginTop: 16}}>{'🎉🥳🎉'}</Text>
                        <PrimaryButton style={{height: 40, width: 280, fontSize: 18, paddingBottom: 2, marginTop: 32}} text={copyText} onClick={onCopyClick}/>
                        <PrimaryButton style={{height: 40, width: 280, fontSize: 18, paddingBottom: 2, marginTop: 16}} text={t('shorten_add_group')} onClick={onAddGroupClick}/>
                    </Stack>
                </div>
            )
        } else {
            return (
                <Stack horizontalAlign={'center'}>
                    <Spinner size={SpinnerSize.large} style={{marginTop: 24}}/>
                    <Text style={{fontSize: 18, color: styles.prColors.subTextColor.color(), marginTop: 24}}>{messageText}</Text>
                </Stack>
            )
        }
    }

    return (
        <Panel isLightDismiss type={PanelType.custom} customWidth={'400px'} isOpen={props.isOpen} onDismiss={props.onDismiss} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.backgroundColor.color()}, main: {backgroundColor: styles.prColors.backgroundColor.color()}, content: {paddingLeft: '16px', paddingRight: '16px'}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton onClick={props.onDismiss} style={{width: 44, height: 44}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <div/>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    {shortLinkRender()}
                </Stack.Item>
            </Stack>
        </Panel>
    )
}

export default ShortenPanel