import React, { CSSProperties, ReactNode, useState, useEffect } from 'react'
import { List, TextField, Text, IconButton, IContextualMenuItem, DialogType, Stack, Shimmer, SpinnerSize, CommandBarButton, Spinner, createTheme, Image } from '@fluentui/react'
import ItemRow from './itemRow'
import { ToastType, useStyles } from '../assets/styles'
import PRDialog from './prDialog'
import animationData from '../assets/lottie_nyan_cat.json'
import { PRButton, Icons, IconsView } from '../shared/utils/icons'
import { useLightSwitch } from 'use-light-switch'
import paperIcon from '../assets/paper_icon.png'
import { setLastSelectedGroupId, singleTapEnabled } from '../shared/utils/settings'
import CropView from './cropView'
import { PRTool } from '../panels/toolsPanel'
import { IPasteImage, PasteListener } from './pasteListener'
import ImageViewerHandler from './imageViewerHandler'
import { isFirefox, isSafari } from 'react-device-detect'
import { PRMore } from '../panels/morePanel'
import { useTranslation } from 'react-i18next';
import DialogManager, { PRDialogType } from './dialogManager'
import CreateGroupPanel from '../panels/createGroupPanel'
import CounterView from './counterView'
import { IImageCache, useGroupsState, useImageCache, useItemsState, useLinkPreviewCacheState, usePremiumDialogState, usePremiumState, usePushGroupsState, useSelectedGroupState, useStorageLimitReachedState, useStorageUploadingState, useToast } from '../shared/stores/stores'
import { ConfirmGroupDeleteDialog } from '../dialogs/confirmGroupDeleteDialog'
import { CollectionKey, DeleteToastState, IItem, ILinkPreview, ItemType } from '../shared/stores/types'
import { container } from 'tsyringe'
import { GROUP_LIMIT, PREMIUM_ITEM_LIMIT, REGULAR_ITEM_LIMIT } from '../shared/utils/constants'
import { useUtils } from '../shared/utils/utils'
import { AnalyticsService, PREvent } from '../shared/services/analyticsService'
import { useImageCaching } from '../shared/viewModel/useImageCaching'
import { useItemActions } from '../shared/viewModel/useItemsAction'
import { useLinkPreviewCaching } from '../shared/viewModel/useLinkPreviewCaching'
import { useModelFactory } from '../shared/viewModel/useModelFactory'
import Lottie from 'react-lottie-player'
import { FirestoreService } from '../shared/services/firestoreService'
import { ConsoleLoggerService } from '../shared/services/consoleLoggerService'

interface IDragItemToUpload {
    file: File,
    type: ItemType
}

const ItemsView = () => {

    const { t } = useTranslation()
    const mode = useLightSwitch()
    const styles = useStyles()
    const [isDragging, setIsDragging] = useState<boolean>(false)
    const cropInputRef = React.createRef<HTMLInputElement>()
    const photoInputRef = React.createRef<HTMLInputElement>()
    const fileInputRef = React.createRef<HTMLInputElement>()
    const itemScrollRef = React.createRef<HTMLDivElement>()
    const listRef = React.createRef<List>()

    const consoleLoggerService = container.resolve(ConsoleLoggerService)
    const analyticsService = container.resolve(AnalyticsService)
    const firestoreService = container.resolve(FirestoreService)
    const [inputText, setInputText] = useState('')
    const [hideLoading, setHideLoading] = useState<boolean>(true)
    const [hideScrollUpSuggest, setHideScrollUpSuggest] = useState<boolean>(true)
    const [viewImageUrl, setViewImageUrl] = useState<string>('')
    const [showImageViewer, setShowImageViewer] = useState<boolean>(false)
    const [pasteImage, setPasteImage] = useState<IPasteImage | null>(null)
    const [cropFile, setCropFile] = useState<File | null>(null)
    const [showBlur, setShowBlur] = useState<boolean>(false)
    const [actionItem, setActionItem] = useState<IItem | null>(null)
    const [itemForUndo, setItemForUndo] = useState<IItem | null>(null)
    const [showDialog, setShowDialog] = useState<PRDialogType>(PRDialogType.None)
    const [showEditGroup, setShowEditGroup] = useState<boolean>(false)
    const [showConfirmDeleteGroup, setShowConfirmDeleteGroup] = useState<boolean>(false)
    const [selectedGroupName, setSelectedGroupName] = useState('')

    const { selectedGroup, setSelectedGroup } = useSelectedGroupState()
    const { items, setItems } = useItemsState()
    const { submitItem, verifyLimits, uploadFromFileList, uploadFromArray, deleteItem, deleteItemForReal, undoItem, pinItem, unpinItem } = useItemActions()
    const { showToast, deleteToastState, setDeleteToastState } = useToast()
    const { cache: imageCache } = useImageCache()
    const { cache: linkPreviewCache } = useLinkPreviewCacheState()
    const { groups } = useGroupsState()
    const { isPremium } = usePremiumState()
    const { isUploading } = useStorageUploadingState()
    const { processImages, cleanupImageCache } = useImageCaching()
    const { processLinks } = useLinkPreviewCaching()
    const { createItem } = useModelFactory()
    const { showPremiumDialog } = usePremiumDialogState()
    const { pushGroups } = usePushGroupsState()
    const { showStorageLimitReached, setShowStorageLimitReached } = useStorageLimitReachedState()
    const { validURL } = useUtils()

    useEffect(() => {
        if (itemForUndo == null) {
            setDeleteToastState(DeleteToastState.None)
            return
        }

        switch (deleteToastState) {
            case DeleteToastState.UndoAction:
                undoItem(itemForUndo)
                break
            case DeleteToastState.AnimateDone:
                deleteItemForReal(itemForUndo)
                break
            default:
                break
        }

        setItemForUndo(null)
        setDeleteToastState(DeleteToastState.None)
    }, [deleteToastState])


    useEffect(() => {
        setLastSelectedGroupId(selectedGroup?.uid ?? null)
        if (selectedGroup == null) {
            setSelectedGroupName('')
            setItems([])
            return
        }
        
        setSelectedGroupName(selectedGroup.name)
        cleanupImageCache()
        setHideLoading(false)
        itemScrollRef.current?.scrollTo({top: 0, left: 0 });
        analyticsService.logScreen('ItemsView')

        const listener = firestoreService.observe<IItem>(CollectionKey.Items, 'owner', selectedGroup.uid, (list: IItem[]) => {
            consoleLoggerService.log('Item listener fired')
            setItems(list)
        })
        return listener
    }, [selectedGroup])

    useEffect(() => {
        const group = groups.find(g => g.uid === selectedGroup?.uid)
        if (group != null && selectedGroup != null && group.name !== selectedGroupName) {
            setSelectedGroupName(group.name)
            selectedGroup.name = group.name
        }

        if (group == null && groups.length === 0) {
            setSelectedGroup(null)
        }

    }, [groups])

    useEffect(() => {
        setTimeout(() => {
            setHideLoading(true)
        }, 500)

        processImages(items)
        processLinks(items)
    }, [items])

    useEffect(() => {
        listRef.current?.forceUpdate()
    }, [imageCache, linkPreviewCache])

    const onDialogDismissed = () => {
        setShowDialog(PRDialogType.None)
        setActionItem(null)
    }

    const onSubmitClicked = async (overrideString: string = '') => {
        const groupId = selectedGroup?.uid
        if ((inputText.trim().length > 0 || overrideString.length > 0) && canSubmitItem() && groupId != null) {
            const finalText = overrideString.length > 0 ? overrideString : inputText
            const item = await submitItem(groupId, finalText)
            setInputText('')

            let source = t('text_text_saved')
            if (item.type === ItemType.Link) {
                source = t('text_link_saved')
            }
            analyticsService.log(PREvent.addText)
            showToast({ message: source, type: ToastType.Announcement })
        }
    }

    const onCropClicked = () => {
        if (canSubmitItem()) {
            cropInputRef.current?.click()
        }
    }

    const onFileClicked = () => {
        if (canSubmitItem()) {
            fileInputRef.current?.click()
        }
    }

    const onPhotoClicked = () => {
        if (canSubmitItem()) {
            photoInputRef.current?.click()
        }
    }
    const onCropSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = cropInputRef.current?.files
        if (files != null && files.length > 0) {
            analyticsService.log(PREvent.capCrop)
            setCropFile(files[0])
        }
        event.target.value = ''
    }

    const onPhotoSelected = () => {
        const files = photoInputRef.current?.files
        if (files != null && files.length > 0) {
            if (files.length > 5) {
                showToast({ message: t('five_file_limit'), type: ToastType.Warning })
            }
            else {
                uploadFromFileList(files, ItemType.Image)
            }
        }
    }

    const onFileSelected = () => {
        const files = fileInputRef.current?.files
        if (files != null && files.length > 0) {
            if (files.length > 5) {
                showToast({ message: t('five_file_limit'), type: ToastType.Warning })
            }
            else {
                uploadFromFileList(files, ItemType.File)
            }
        }
    }

    const onRenderItemCell = (item?: IItem): ReactNode => {
        if (item !== undefined) {
            let imageItem: IImageCache | undefined = undefined
            const filtered = imageCache.filter( imageItem => imageItem.uid === item.uid)
            if (filtered.length > 0) {
                imageItem = filtered[0]
            }

            let linkPreview: ILinkPreview | undefined = undefined
            const linkFiltered = linkPreviewCache.filter( imageItem => imageItem.uid === item.uid)
            if (linkFiltered.length > 0) {
                linkPreview = linkFiltered[0]
            }

            return (
                <ItemRow item={item} linkPreview={linkPreview} itemImage={imageItem} onClick={onClick} onCopyClick={onCopyClick} onDelete={onDeleteClick} onTag={onTagClick} onImageClick={onImageClick} onEdit={onEditClick} onMoreClick={onMoreClick}/>
            );
        }
        return null;
    }

    const onMoreItemSelected = (item: IItem, more: PRMore) => {
        switch (more) {
            case PRMore.Copy: {
                navigator.clipboard.writeText(item.content)
                onCopyClick(item)
                break
            }
            case PRMore.CopyMulti: {
                setActionItem(item)
                setShowDialog(PRDialogType.MultiCopy)
                break
            }
            case PRMore.OpenLink: {
                let link = item.content
                if (!link.startsWith('http')) {
                    link = 'http://' + link
                }
                window.open(link, '_blank') 
                break
            }
            case PRMore.Tag: 
                onTagClick(item)
                break
            case PRMore.Delete:
                onDeleteClick(item)
                break
            case PRMore.Edit:
                onEditClick(item)
                break    
            case PRMore.Download: {
                const found = imageCache.filter(imageItem => imageItem.uid == item.uid)
                if (found.length > 0) {
                    const link = document.createElement('a');
                    link.download = item.content
                    link.target = '_blank'
                    link.href = found[0].downloadUrl
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                break
            }
            case PRMore.View: {
                const found2 = imageCache.filter(imageItem => imageItem.uid == item.uid)
                if (found2.length > 0) {
                    onImageClick(found2[0].downloadUrl)
                }
                break
            }
            case PRMore.ShortenLink: {
                if (!isPremium) {
                    showPremiumDialog(t('shorten_link_tagline'))
                    analyticsService.log(PREvent.guardShown)
                    return
                }
                setActionItem(item)
                setShowDialog(PRDialogType.Shorten)
                break
            }
            case PRMore.ShareTwitter: {
                const content = encodeURIComponent(item.content)
                let twitterLink = 'https://twitter.com/intent/tweet?'
                if (validURL(content)) {
                    twitterLink = twitterLink + 'url=' + content
                } else {
                    twitterLink = twitterLink + 'text=' + content
                }
                window.open(twitterLink, '_blank') 
                break
            }
            case PRMore.ShareWhatsApp: {
                const content2 = encodeURIComponent(item.content)
                const whatsAppLink = 'https://api.whatsapp.com/send?text=' + content2
                window.open(whatsAppLink, '_blank') 
                break
            }
            case PRMore.PinToTop: {
                analyticsService.log(PREvent.pin)
                pinItem(item)
                break
            }
            case PRMore.Unpin:
                unpinItem(item)
                break
            default:
                break
        }
    }

    const onBulkDeleteClick = () => {
        setShowDialog(PRDialogType.MultiCopy)
    }

    const onEditClick = (item: IItem) => {
        setActionItem(item)
        setShowDialog(PRDialogType.TextEdit)
    }

    const onImageClick = (url: string) => {
        setPasteImage(null)
        setViewImageUrl(url)
        setShowImageViewer(true)
    }

    const onImageClickDismiss = () => {
        setShowImageViewer(false)
    }

    const onTextEditClick = () => {
        const groupId = selectedGroup?.uid
        if (canSubmitItem() && groupId != null) {
            const item = createItem(groupId, inputText, ItemType.Text)
            setActionItem(item)
            setShowDialog(PRDialogType.TextEdit)
            setInputText('')
        }
    }

    const onTagClick = (item: IItem) => {
        setActionItem(item)
        setShowDialog(PRDialogType.Tag)
    }

    const onMoreClick = (item: IItem) => {
        setActionItem(item)
        setShowDialog(PRDialogType.More)
    }

    const onCopyClick = (item: IItem) => {
        analyticsService.log(PREvent.copyText)
        navigator.clipboard.writeText(item.content)
        showToast({ message: t('toast_copied_clipboard'), type: ToastType.Announcement })
    }

    const onClick = (item: IItem) => {
        if (singleTapEnabled()) {
            navigator.clipboard.writeText(item.content)
            onCopyClick(item)
        }
        else {
            analyticsService.log(PREvent.editText)
            setActionItem(item)
            setShowDialog(PRDialogType.TextEdit)
        }
    }

    const cleanupUndoItem = () => {
        if (itemForUndo != null) {
            deleteItemForReal(itemForUndo)
        }
    }

    const onDeleteClick = (item: IItem) => {
        cleanupUndoItem()
        setItemForUndo(item)
        deleteItem(item)
        showToast({ message: t('toast_deleted'), type: ToastType.Danger })
        analyticsService.log(PREvent.deleteItem)
    }

    const textDidChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (value !== undefined) {
            setInputText(value)
        }
    }

    const onKeyDownAction = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) {
                return
            }
            event.preventDefault()
            onSubmitClicked()
        }
    }

    const onExportItems = () => {
        setShowDialog(PRDialogType.Export)
    }

    const changeGroupNameIconRender = (): JSX.Element | null => {
        return <Stack horizontalAlign={'center'} verticalAlign={'center'} style={{margin: 4}}>{IconsView.iconView(Icons.Edit24Regular)}</Stack>
    }

    const deleteGroupIconRender = (): JSX.Element | null => {
        return <Stack horizontalAlign={'center'} verticalAlign={'center'} style={{margin: 4}}>{IconsView.iconView(Icons.Delete24Regular, styles.prColors.errorColor.color())}</Stack>
    }

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'onChangeName',
            name: t('menu_change_group_name'),
            onRenderIcon: changeGroupNameIconRender,
            iconProps: {iconName: ''},
            onClick: () => setShowEditGroup(true)
        },
        {
            key: 'onDeleteGroup',
            name: t('menu_delete_group'),
            style: {color: styles.prColors.errorColor.color()},
            onRenderIcon: deleteGroupIconRender,
            onClick: () => setShowConfirmDeleteGroup(true)
        }
    ]

    // const captureCropRender = (props?: IContextualMenuItemProps, defaultRender?: (props?: IContextualMenuItemProps) => JSX.Element | null): JSX.Element | null => {
    //     return <Stack horizontalAlign={'center'} verticalAlign={'center'} style={{margin: 4}}>{IconsView.iconView(Icons.Crop24Regular)}</Stack>
    // }

    const scrollToTop = () => {
        if (itemScrollRef.current != null) {
            itemScrollRef.current.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        }
    }

    const canSubmitItem = (): boolean => {
        if (!isPremium) {
            if (items.length >= REGULAR_ITEM_LIMIT) {
                showPremiumDialog(t('items_tagline'))
                analyticsService.log(PREvent.guardShown)
                return false
            } else if (groups.length > GROUP_LIMIT) {
                showPremiumDialog(t('premium_tagline'))
                analyticsService.log(PREvent.guardShown)
                return false
            }
        } else {
            if (items.length >= PREMIUM_ITEM_LIMIT) {
                setShowDialog(PRDialogType.Export)
                return false
            }
        }
        return true
    }

    const onScroll = () => { 
        if (itemScrollRef.current != null && itemScrollRef.current.scrollTop > 150 && hideScrollUpSuggest) {
            setHideScrollUpSuggest(false)
        }
        else if (itemScrollRef.current != null && itemScrollRef.current.scrollTop < 150 && !hideScrollUpSuggest) {
            setHideScrollUpSuggest(true)
        }
    }

    const placeholderContent = (
        <Stack.Item>
            <Stack style={{display: 'stretch', padding: 15, backgroundColor: styles.prColors.backgroundColor.color()}} tokens={{childrenGap: 10}}>
                <Shimmer width={'15%'} theme={styles.getShimmerColors(mode)}>
                    <Stack horizontal verticalAlign={'center'} style={{height: 18}} tokens={{childrenGap: 8}}>
                    </Stack>
                </Shimmer>
                <Shimmer width={'90%'} theme={styles.getShimmerColors(mode)}>
                    <pre style={{marginBottom: '2px'}}>
                    <Text variant='mediumPlus'>
                    </Text>
                    </pre>
                </Shimmer>
            </Stack>
        </Stack.Item>
    )

    const onDragEnter = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            setIsDragging(true)
        }
    }

    const onDrop = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        if (!canSubmitItem()) {
            return
        }
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            const items = event.dataTransfer.items
            if (items.length > 5) {
                showToast({ message: t('five_file_limit'), type: ToastType.Warning })
            }
            else {
                const itemList: IDragItemToUpload[] = []
                for (let i = 0; i<items.length; i++) {
                    const item = items[i]
                    if (item.kind == 'string' && (item.type.match('^text/plain') || item.type.match('^text/uri-list'))) {
                        item.getAsString(asString => {
                            onSubmitClicked(asString)
                        })
                    }
                    else if (item.kind == 'file' && item.type.match('^image/')) {
                        const file = item.getAsFile()
                        if (file != null) {
                            itemList.push({file: file, type: ItemType.Image})
                        }
                    }
                    else if (item.kind == 'file') {
                        const file = item.getAsFile()
                        if (file != null) {
                            itemList.push({file: file, type: ItemType.File})
                        }
                    }
                }

                const convertedFiles = itemList.map(item => item.file)
                if (verifyLimits(convertedFiles)) {
                    onProcessItems(itemList)
                }
            }
        }
        event.dataTransfer.clearData()
        setIsDragging(false)
    }

    const onProcessItems = async (items: IDragItemToUpload[]) => {
        const files: File[] = []
        for (let i = 0; i<items.length; i++) {
            const item = items[i]
            files.push(item.file)
            await uploadFromArray([item.file], item.type)
        }
    }

    const onDragOver = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const onDragEnded = (event: React.DragEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        event.dataTransfer.clearData()
        setIsDragging(false)
    }

    const onCropCompleted = async (file: File | null) => {
        if (!isPremium) {
            showPremiumDialog(t('crop_tagline'))
            return
        }

        if (file != null) {
            if (verifyLimits([file])) {
                await uploadFromArray([file], ItemType.Image)
            }
        }
    }

    const onToolsClicked = () => {
        setShowDialog(PRDialogType.Tools)
    }

    const onToolSelected = (tool: PRTool | null) => {
        if (tool != null) {
            switch (tool) {
                case PRTool.CaptureCrop:
                    onCropClicked()
                    break
                case PRTool.ClearClipboard:
                    navigator.clipboard.writeText('')
                    showToast( { message: t('clip_clipboard_cleared'), type: ToastType.Regular } )
                    break
            }
        }
    }

    const onSavePastedImage = async (pasteImage: IPasteImage | null) => {
        onImageClickDismiss()
        if (pasteImage != null) {
            const url = pasteImage.image
            const blob = await fetch(url).then(r => r.blob());
            const file = new File([blob], pasteImage.fileName)
    
            if (file != null) {
                if (verifyLimits([file])) {
                    await uploadFromArray([file], ItemType.Image)
                }
            }
        }
    }

    const onShowBlur = () => {
        setShowBlur(!showBlur)
    }

    const onShowAlert = () => {
        setShowDialog(PRDialogType.Notifications)
    }

    const renderAlertButton = () => {
        const groupId = selectedGroup?.uid
        if (groupId == null) {
            return undefined
        }
        let icon = Icons.AlertSnooze24Regular
        let color = '#eeeeee'
        if (pushGroups.indexOf(groupId) >= 0) {
            icon = Icons.AlertOn24Filled
            color = '#00d45c'
        } 

        return (
            <PRButton icon={icon} hoverColor={'#ffffff11'} color={color} onClick={onShowAlert}
            style={{width: 60, height: 60, marginRight: '5px'}}/>
        )
    }

    const myTheme = createTheme({
        palette: {
            themePrimary: styles.prColors.primaryColor.color(),
            neutralPrimary: styles.prColors.primaryColor.color()
        }});

    const contextMenuTheme = createTheme({
        palette: {
            themePrimary: styles.prColors.textColor.color(),
            neutralDark: styles.prColors.subTextColor.color(),
            neutralLighter: styles.prColors.cellHoverColor.color(),
            neutralLight: styles.prColors.menuBackgroundColor.color(),
            neutralPrimary: styles.prColors.textColor.color(),
            white: styles.prColors.menuBackgroundColor.color()
        }});


    const hover: CSSProperties = {overflowX: 'hidden', width: '100%', height: '100%', position: 'relative'}
    return (
        <Stack style={{width: '100%', height: '100%', backgroundColor: styles.prColors.backgroundColor.color()}} 
            onDragEnter={onDragEnter} 
            onDragOver={onDragOver}
            onDrop={onDrop}>
            <Stack.Item>
                <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'} style={{height: '80px', backgroundColor: styles.prColors.bannerBackgroundColor.color()}}>
                    <Text variant={'xxLarge'} style={{marginLeft: 20, color: '#ffffff', fontWeight: 600}}>
                        {selectedGroupName}
                    </Text>
                        {selectedGroup != null ? (
                        <Stack horizontal>
                            <CounterView itemCount={items.length} fontSize={14} isDialog={false} onClick={onExportItems}/>
                            <PRButton icon={showBlur ? Icons.EyeHide24Regular : Icons.EyeShow24Regular} hoverColor={'#ffffff11'} color={'#eeeeee'} onClick={onShowBlur}
                                style={{width: 60, height: 60, marginRight: '0px', marginLeft: 8}}/>
                            {renderAlertButton()}
                            <PRButton icon={Icons.MoreVertical24Filled} 
                                style={{width: 60, height: 60, marginRight: '0px'}} hoverColor={'#00000000'} color={'#ffffff'} 
                                menuProps={{ items: menuItems, theme: contextMenuTheme, calloutProps: {backgroundColor: '#00000000'} }}/>
                        </Stack>) : undefined}
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
            </Stack.Item>
            <Stack.Item grow styles={{root: {overflow: 'auto', position: 'relative'}}}>
                <div className={'items'} data-is-scrollable={true} style={hover} ref={itemScrollRef} onScroll={onScroll}>
                    <List ref={listRef} style={{borderWidth: 0, paddingBottom: '100px'}} items={items} onRenderCell={onRenderItemCell}/>
                    {items.length === 0 ? (
                        <div style={{position: 'absolute', left: '50%', top: '40%', transform: 'translate(-50%,-50%)'}}>
                            <Stack>
                            <Lottie loop play animationData={animationData} style={{width: 180, height: 180, marginLeft: 20}}/>
                                <Text variant={'mediumPlus'} style={{textAlign: 'center', fontWeight: 'bold', marginTop: '-20px', lineHeight: '1.4', color: styles.prColors.textColor.color()}}>
                                    {t('items_empty_group')}
                                </Text>
                                <Text variant={'medium'} style={{textAlign: 'center', marginTop: '8px', lineHeight: '1.4', color: styles.prColors.subTextColor.color()}}>
                                    {t('items_empty_group_sub')}
                                </Text>
                                
                            </Stack>
                        </div>      
                    ) : undefined}
                </div>
                <div style={{position: 'absolute', bottom: '20px', left: '50%', transform: 'translate(-50%,-50%)', display: 'none'}} > 
                    <CommandBarButton styles={{root: {padding: '14px', backgroundColor: styles.colors.themeSecondary, borderRadius: '8px'}, label: {fontSize: 16, color: styles.colors.white}, rootHovered: {backgroundColor: styles.colors.themeTertiary}}} text={'Tap to view at the top ☝️'} onClick={scrollToTop}/>
                </div>
                <div style={{position: 'absolute', bottom: '30px', right: '40px', display: hideScrollUpSuggest ? 'none' : 'block'}} > 
                    <IconButton style={{width: 52, height: 52, padding: '14px', backgroundColor: styles.prColors.topButtonColor.color(), borderRadius: '26px', boxShadow: '2px 2px 2px 1px #00000033'}} iconProps={{iconName: 'ChevronUpSmall', style: {fontSize: 20}}} onClick={scrollToTop}/>
                </div>

                {isFirefox || isSafari ? 
                <div style={{backgroundColor: styles.prColors.backgroundColor.color('ee'), position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: showBlur ? 'block' : 'none'}}></div>
                :
                <div style={{backdropFilter: 'blur(10px)', position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: showBlur ? 'block' : 'none'}}></div>
                }
                
                {hideLoading ? undefined : (
                    <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: styles.prColors.backgroundColor.color()}}>
                        <Stack style={{display: 'flex', marginTop: '8px'}}>
                            {placeholderContent}
                            {placeholderContent}
                            {placeholderContent}
                        </Stack>
                    </div>
                )}

            </Stack.Item>
            <Stack.Item>
                {selectedGroup != null ? (
                    <div style={{borderWidth: 1, marginBottom: '20px', marginLeft: '20px', marginRight: '20px', borderColor: styles.prColors.textBorderColor.color(), borderStyle: 'solid', borderRadius: '10px', overflow: 'hidden', position: 'relative'}}>
                        <TextField value={inputText} multiline autoAdjustHeight borderless resizable={false} onChange={textDidChange} style={{maxHeight: '150px', width: '100%', paddingTop: '15px', paddingLeft: '15px', paddingRight: '52px', paddingBottom: '0px', fontSize: '16px', overflow: 'auto', color: styles.prColors.textColor.color(), backgroundColor: styles.prColors.textInputBgColor.color()}} theme={myTheme} onKeyDown={onKeyDownAction} styles={{fieldGroup: {minHeight: '44px'}}}/>
                        {inputText.length > 0 ? null :
                        <div style={{position: 'absolute', top: 15, left: 15, pointerEvents: 'none'}}>
                            <Text style={{color: styles.prColors.textColor.color('aa'), fontSize: '16px'}}>{t('items_add_placeholder')}</Text>
                        </div>}
                        <div style={{position: 'absolute', top: 5, right: 10}}>
                            <PRButton style={{width: 44, height: 44}} icon={Icons.Send24Filled} color={inputText.length > 0 ? styles.prColors.primaryColor.color() : styles.prColors.iconDisabledColor.color('a6')} onClick={onSubmitClicked}/>
                        </div>
                        <Stack style={{backgroundColor: styles.prColors.menuBackgroundColor.color()}}>
                            <Stack horizontal horizontalAlign={'space-between'}>
                                
                                    {isUploading ? 
                                    
                                    <Stack horizontal verticalAlign={'center'} tokens={{childrenGap: 10}}>
                                        <Spinner size={SpinnerSize.medium} style={{marginLeft: 10}}/>
                                        <Text style={{color: styles.prColors.textColor.color(), fontSize: 16}}>Uploading...</Text>
                                    </Stack>
                                    
                                    :
                                    
                                    <Stack horizontal>
                                        <input ref={cropInputRef} type={'file'} accept={'image/*'} onChange={onCropSelected} style={{display: 'none'}} />
                                        <input multiple ref={photoInputRef} type={'file'} accept={'image/*'} onChange={onPhotoSelected} style={{display: 'none'}} />
                                        <input multiple ref={fileInputRef} type={'file'} accept={'*/*'} onChange={onFileSelected} style={{display: 'none'}} />
                                        <PRButton style={{width: 60, height: 44}} icon={Icons.Toolbox24Regular} onClick={onToolsClicked}/>
                                        <PRButton style={{width: 60, height: 44}} icon={Icons.DocumentAdd24Regular} onClick={onFileClicked}/>
                                        <PRButton style={{width: 60, height: 44}} icon={Icons.ImageAdd24Regular} onClick={onPhotoClicked}/>
                                    </Stack>}
                                
                                <PRButton style={{width: 60, height: 44}} icon={Icons.TextAdd24Filled} onClick={onTextEditClick}/>
                            </Stack>
                        </Stack>
                    </div>
                ) : undefined}
            </Stack.Item>

            { showConfirmDeleteGroup && <ConfirmGroupDeleteDialog onDismiss={() => setShowConfirmDeleteGroup(false)} /> }

            { showStorageLimitReached && <PRDialog 
                hidden={false} 
                type={DialogType.normal} 
                title={t('storage_storage_space')} 
                subText={t('storage_storage_space_warning')} 
                onDismiss={() => setShowStorageLimitReached(false)} 
                cancelButton={{text: t('general_cancel'), onClick: () => setShowStorageLimitReached(false) }}/> }

            <CreateGroupPanel key={selectedGroup?.uid} isOpen={showEditGroup} onDismiss={() => setShowEditGroup(false)} isEditMode={true} />

            <DialogManager 
                item={actionItem} 
                dialog={showDialog}
                onDialogDismissed={onDialogDismissed}
                onMoreClick={onMoreItemSelected}
                onToolsClick={onToolSelected}
                onBulkDeleteClick={onBulkDeleteClick} />

            <ImageViewerHandler visible={showImageViewer} onClose={onImageClickDismiss} onSave={onSavePastedImage} src={viewImageUrl} pasteSrc={pasteImage}/>

            <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: styles.prColors.backgroundColor.color('DD'), display: isDragging ? 'block' : 'none'}}
                onDragLeave={onDragEnded} onDragExit={onDragEnded}>
                <div style={{pointerEvents: 'none', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', width: '500px', height: '150px', borderRadius: '6px', borderStyle: 'dashed', borderWidth: '3px', borderColor: styles.prColors.textColor.color()}}>
                    <Stack horizontal verticalAlign={'center'} style={{height: '100%'}}>
                        <Stack horizontalAlign={'center'} style={{justifyContent: 'center', width: '100%'}}>
                            <Image src={paperIcon} style={{width: 60, height: 60, marginBottom: '16px'}}/>
                            <Text style={{pointerEvents: 'none', color: styles.prColors.textColor.color(), fontSize: '24px', fontWeight: 600}}>Drop here to upload!</Text>
                        </Stack>
                    </Stack>
                </div>
            </div>

            <CropView hide={cropFile == null} file={cropFile} onClose={() => setCropFile(null)} onCompleted={onCropCompleted}/>

            <PasteListener onPaste={(images) => {
                if (images != null) {
                    const file = images
                    setViewImageUrl(file.image)
                    setPasteImage(file)
                    setShowImageViewer(true)
                }
            }}
            acceptedFiles={['image/gif', 'image/png', 'image/jpeg', 'image/bmp']}/>

        </Stack>
    )
}
export default ItemsView