import 'reflect-metadata';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardRoot from './pages/dashboardRoot';
import Landing from './pages/landing';
import QuickSend from './pages/quickSend';
import DeleteInfo from './pages/deleteInfo';

function App() {

  return (
      <Router>
        <Routes>
          <Route path='/' element={<Landing/>}>            
          </Route>
          <Route path='/dashboard/:notification?' element={<DashboardRoot/>}>
          </Route>
          <Route path='/quick' element={<QuickSend />}>
          </Route>
          <Route path='/delete' element={<DeleteInfo />}>
          </Route>
        </Routes>
      </Router>
  );
}

export default App;
