import { container } from 'tsyringe';
import { useTranslation } from 'react-i18next';
import { useModelFactory } from './useModelFactory';
import { ToastType } from '../../assets/styles';
import { REGULAR_ITEM_LIMIT } from '../utils/constants';
import { MissingSelectedGroupError } from '../utils/errors';
import { useUtils } from '../utils/utils';
import { AnalyticsService, PREvent } from '../services/analyticsService';
import { FirestoreService } from '../services/firestoreService';
import { StorageService } from '../services/storageService';
import { usePremiumState, useItemsState, useSelectedGroupState, useToast, useStorageUploadingState, usePremiumDialogState, useStorageSizeState, useStorageLimitReachedState } from '../stores/stores';
import { IItem, ItemType, CollectionKey, IGroup } from '../stores/types';
import { ConsoleLoggerService } from '../services/consoleLoggerService';

export const useItemActions = () => {

    const { t } = useTranslation()
    const consoleLoggerService = container.resolve(ConsoleLoggerService)
    const firestoreService = container.resolve(FirestoreService)
    const storageService = container.resolve(StorageService)
    const analyticsService = container.resolve(AnalyticsService)
    const { createItem } = useModelFactory()
    const { isPremium } = usePremiumState()
    const { items } = useItemsState()
    const { selectedGroup } = useSelectedGroupState()
    const { showToast } = useToast()
    const { setUploading } = useStorageUploadingState()
    const { showPremiumDialog } = usePremiumDialogState()
    const { storageSize } = useStorageSizeState()
    const { setShowStorageLimitReached } = useStorageLimitReachedState()
    const { validURL } = useUtils()

    const submitItem = async (owner: string, content: string): Promise<IItem> => {
        const type = validURL(content) ? ItemType.Link : ItemType.Text
        const item = createItem(owner, content, type)
        await firestoreService.add(CollectionKey.Items, item.uid, item)
        return item
    }

    const updateGroup = async (group: IGroup) => {
        await firestoreService.add(CollectionKey.Groups, group.uid, group)
    }

    const verifyLimits = (files: File[]): boolean => {
        const storageSizeLimit = isPremium ? 500000000 : 50000000
        const currentStorageSize = storageSize

        if (!isPremium && (files.length + items.length) > REGULAR_ITEM_LIMIT) {
            showPremiumDialog(t('items_tagline'))
            analyticsService.log(PREvent.guardShown)
            return false
        }

        let totalFileSize = 0
        for (let i = 0; i<files.length; i++) {
            const file = files[i]
            totalFileSize += file.size
        }

        if (!isPremium && (currentStorageSize + totalFileSize) > storageSizeLimit) {
            showPremiumDialog(t('storage_tagline'))
            analyticsService.log(PREvent.guardShown)
            return false
        }

        if (isPremium && (currentStorageSize + totalFileSize) > storageSizeLimit) { 
            setShowStorageLimitReached(true)
            return false
        }

        return true
    }

    const uploadFromFileList = async (files: FileList, type: ItemType) => {
        const convertedFiles: File[] = []
        for (let i = 0; i<files.length; i++) {
            const file = files[i]
            convertedFiles.push(file)
        }

        await uploadFromArray(convertedFiles, type)
    }    

    const uploadFromArray = async (files: File[], type: ItemType) => {
        setUploading(true)

        try {
            const groupId = selectedGroup?.uid
            if (groupId == null) {
                throw new MissingSelectedGroupError()
            }
    
            if (verifyLimits(files)) {
                for (let i = 0; i<files.length; i++) {
                    const file = files[i]
                    const item = createItem(groupId, file.name, type)
                    await storageService.uploadFile(file, item)
                }

                if (type === ItemType.Image) {
                    showToast({ message: t('toast_image_added'), type: ToastType.Announcement })
                    analyticsService.log(PREvent.addImage)
                } else {
                    showToast({ message: t('toast_file_added'), type: ToastType.Announcement })
                    analyticsService.log(PREvent.addFile)
                }
                
            } else {
                // over limit
            }
        } catch (e) {
            consoleLoggerService.error('Upload failed', e)
            showToast({ message: t('file_upload_failed'), type: ToastType.Warning })
        }

        setUploading(false)
    }

    const deleteItem = (item: IItem) => {
        firestoreService.deleteItem(item)
    }

    const deleteItemForReal = (item: IItem) => {
        firestoreService.deleteItemForReal(item)
    }
    
    const undoItem = (item: IItem) => {
        item.localDeleted = null
        firestoreService.addItem(item)
    }

    const pinItem = (item: IItem) => {
        item.pinned = true
        firestoreService.addItem(item)
    }

    const unpinItem = (item: IItem) => {
        item.pinned = false
        firestoreService.addItem(item)
    }

    const isVideo = (item: IItem): boolean => {
        return item.content.endsWith('.mp4') || item.content.endsWith('.mov')
    }

    const textForItemType = (item: IItem): string => {
        switch (item.type) {
            case ItemType.Text:
                return 'Text'
            case ItemType.File:
                if (isVideo(item)) {
                    return 'Video'
                }
                return 'File'
            case ItemType.Image:
                return 'Image'
            case ItemType.Link:
                return 'Link'
            default:
                return ''
        }
    }

    return { textForItemType, isVideo, submitItem, updateGroup, verifyLimits, uploadFromFileList, uploadFromArray, deleteItem, deleteItemForReal, undoItem, pinItem, unpinItem }
}