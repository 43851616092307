import React, { useState, useEffect } from 'react'
import { Panel, Stack, TextField, Text, PanelType, DialogType } from '@fluentui/react'
import { PRButton, Icons } from '../shared/utils/icons'
import { ToastType, useStyles } from '../assets/styles'
import PRDialog, { IDialogButtonType } from '../components/prDialog';
import { useTranslation, Trans } from 'react-i18next';
import { container } from 'tsyringe';
import { useToast } from '../shared/stores/stores';
import moment from 'moment';
import { IItem, ItemType } from '../shared/stores/types';
import { useUtils } from '../shared/utils/utils';
import { FirestoreService } from '../shared/services/firestoreService';

interface IProps {
    item: IItem | null,
    isOpen: boolean,
    onDismiss: () => void
}

const TextEditPanel = (props: IProps) => {

    const { t } = useTranslation()
    const styles = useStyles()

    const [textValue, setTextValue] = useState<string>('')
    const [didCopy, setDidCopy] = useState<boolean>(false)
    const [hideConfirmClose, setHideConfirmClose] = useState<boolean>(true)
    const [originalText, setOriginalText] = useState<string>('')

    const firestoreService = container.resolve(FirestoreService)
    const { showToast } = useToast()
    const { validURL } = useUtils()

    useEffect(() => {
        if (props.item == null || !props.isOpen) {
            return
        }
        setOriginalText(props.item.content)
        setTextValue(props.item.content)
    }, [props.isOpen])

    const textDidChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (value !== undefined) {
            setTextValue(value)
        }
    }

    const onCopyClick = () => {
        navigator.clipboard.writeText(textValue)
        setDidCopy(true)
        setTimeout(() => {
            setDidCopy(false)
        }, 2000)
    }

    const onCompleteClick = () => {
        const trimmed = textValue.trim()
        const item = props.item
        if (trimmed.length > 0 && item != null) {
            let message = t('text_text_saved')
            if (item.content.length === 0) {
                let type = ItemType.Text
                message = t('text_text_saved')
                if (validURL(trimmed)) {
                    type = ItemType.Link
                    message = t('text_link_saved')
                }
                item.type = type
            }
            item.content = trimmed

            firestoreService.addItem(item)
            showToast({message, type: ToastType.Announcement})
            setHideConfirmClose(true)
            props.onDismiss()
        }
    }

    const timeFormat = (): string => {
        if (props.item == null) {
            return ''
        }
        const itemDate = new Date(props.item.created * 1000)
        const itemMoment = moment(itemDate)
        return itemMoment.format('D MMM, yyyy h:mm a')
    }

    const onLightDismiss = () => {
        if (textValue != originalText) {
            setHideConfirmClose(false)
        }
        else {
            props.onDismiss()
        }
    }

    const onDismissConfirm = () => {
        setHideConfirmClose(true)
    }

    const moveToEnd = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const temp = event.target.value
        event.target.value = ''
        event.target.value = temp
    }

    const buttonSize: number = 44

    return (
        <Panel isLightDismiss type={PanelType.custom} customWidth={'400px'} onLightDismissClick={onLightDismiss} isOpen={props.isOpen} onDismiss={props.onDismiss} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.backgroundColor.color()}, main: {backgroundColor: styles.prColors.backgroundColor.color()}, content: {paddingLeft: '16px', paddingRight: '16px'}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton onClick={props.onDismiss} style={{width: buttonSize, height: buttonSize}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <Stack horizontal tokens={{childrenGap: 8}}>
                                {didCopy ? 
                                <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.CheckmarkCircle24Regular} color={'#50C878'}/>
                                :
                                <PRButton style={{width: buttonSize, height: buttonSize}} icon={Icons.Copy24Regular} onClick={onCopyClick} color={styles.prColors.subTextColor.color()}/>
                                }
                            <PRButton onClick={onCompleteClick} style={{width: buttonSize, height: buttonSize}} icon={Icons.Save24Regular} color={textValue.length > 0 ? styles.prColors.greenColor.color() : styles.prColors.iconDisabledColor.color()}/>
                        </Stack>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={{childrenGap: 8}} style={{marginLeft: '10px', marginTop: '16px', marginBottom: '8px'}}>
                        <Text style={{color: styles.prColors.subTextColor.color(), fontSize: 12}}>{timeFormat()} </Text>
                        <Text style={{color: styles.prColors.subTextColor.color(), fontSize: 12}}>| &nbsp;<Trans i18nKey={'text_count_words'} values={{0: textValue.length === 0 ? 0 : textValue.split(' ').length}}></Trans></Text>
                        <Text style={{color: styles.prColors.subTextColor.color(), fontSize: 12}}>| &nbsp;<Trans i18nKey={'text_count_character'} values={{0: textValue.length}}></Trans></Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <div style={{position: 'relative'}}>
                        <TextField autoFocus onFocus={moveToEnd} value={textValue} multiline autoAdjustHeight borderless resizable={false} onChange={textDidChange} style={{minHeight: '400px', width: '100%', paddingTop: '16px', paddingLeft: '10px', paddingRight: '12px', paddingBottom: '0px', fontSize: '16px', overflow: 'auto', color: styles.prColors.textColor.color(), backgroundColor: styles.prColors.backgroundColor.color(), lineHeight: '1.4em'}}/>
                        {textValue.length > 0 ? null :
                        <div style={{position: 'absolute', top: 16, left: 12, pointerEvents: 'none'}}>
                            <Text style={{color: styles.prColors.textColor.color('aa'), fontSize: '16px'}}>{`${t('text_start_typing')}...`}</Text>
                        </div>}
                    </div>
                </Stack.Item>
            </Stack>

            <PRDialog hidden={hideConfirmClose} type={DialogType.normal} title={t('text_unsaved')} subText={t('text_unsaved_msg')} cancelButton={{text: t('general_cancel'), onClick: onDismissConfirm}} actionButton={{text: t('general_save'), onClick: onCompleteClick, type: IDialogButtonType.Primary}} onDismiss={onDismissConfirm}> 
                
            </PRDialog>
        </Panel>
    )
}

export default TextEditPanel