import { useEffect, useState } from 'react';
import MorePanel, { PRMore } from '../panels/morePanel';
import AlertsPanel from '../panels/alertsPanel';
import ExportPanel from '../panels/exportPanel';
import MultiCopyPanel from '../panels/multiCopyPanel';
import ShortenPanel from '../panels/shortenPanel';
import TagPanel from '../panels/tagPanel';
import TextEditPanel from '../panels/textEditPanel';
import { useTranslation } from 'react-i18next';
import ToolsPanel, { PRTool } from '../panels/toolsPanel';
import { usePremiumDialogState } from '../shared/stores/stores';
import { IItem } from '../shared/stores/types';

interface IProps {
    item: IItem | null,
    dialog: PRDialogType
    onDialogDismissed: () => void,
    onMoreClick?: (item: IItem, more: PRMore) => void,
    onToolsClick?: (tool: PRTool | null) => void,
    onBulkDeleteClick?: () => void
}

export enum PRDialogType {
    None,
    More,
    Tag,
    TextEdit,
    Notifications,
    Shorten,
    Export,
    MultiCopy,
    Tools,
    CreateGroup
}

const DialogManager = (props: IProps) => {

    const { t } = useTranslation()
    const { showPremiumDialog } = usePremiumDialogState()
    const initialState = {
        [PRDialogType.None]: false,
        [PRDialogType.More]: false,
        [PRDialogType.Tag]: false,
        [PRDialogType.TextEdit]: false,
        [PRDialogType.Notifications]: false,
        [PRDialogType.Shorten]: false,
        [PRDialogType.Export]: false,
        [PRDialogType.MultiCopy]: false,
        [PRDialogType.Tools]: false,
        [PRDialogType.CreateGroup]: false
    }

    const [dialogs, setDialogs] = useState(initialState)

    useEffect(() => {
        setDialogs({...dialogs, [props.dialog]: true})
    }, [props.dialog])

    const onDismiss = () => {
        setDialogs(initialState)
        props.onDialogDismissed()
    }

    const onTagWillAdd = () => {
        showPremiumDialog(t('tag_tag_line'))
    }

    const onToolsMiddleMan = (tool: PRTool | null) => {
        setDialogs(initialState)
        props.onDialogDismissed()
        if (props.onToolsClick != null) {
            props.onToolsClick(tool)
        }
    }

    const onBulkDeleteMiddleMan = () => {
        setDialogs(initialState)
        props.onDialogDismissed()
        if (props.onBulkDeleteClick != null) {
            props.onBulkDeleteClick()
        }
    }

    const onMoreMiddleMan = (item: IItem, more: PRMore) => {
        setDialogs(initialState)
        props.onDialogDismissed()
        if (props.onMoreClick != null) {
            props.onMoreClick(item, more)
        }
    }

    return (
        <div>
            <MorePanel isOpen={dialogs[PRDialogType.More]} item={props.item} onDismiss={onDismiss} onMoreClick={onMoreMiddleMan}/>
            <TagPanel isOpen={dialogs[PRDialogType.Tag]} onDismiss={onDismiss} onTagWillAdd={onTagWillAdd} item={props.item} />
            <TextEditPanel isOpen={dialogs[PRDialogType.TextEdit]} onDismiss={onDismiss} item={props.item} />
            <AlertsPanel isOpen={dialogs[PRDialogType.Notifications]} onDismiss={onDismiss}/>
            <ShortenPanel isOpen={dialogs[PRDialogType.Shorten]} onDismiss={onDismiss} item={props.item}/>
            <ExportPanel isOpen={dialogs[PRDialogType.Export]} onDismiss={onDismiss} onBulkDeleteClick={onBulkDeleteMiddleMan}/>
            <MultiCopyPanel isOpen={dialogs[PRDialogType.MultiCopy]} onDismiss={onDismiss} startingItem={props.item}/>
            <ToolsPanel isOpen={dialogs[PRDialogType.Tools]} onDismiss={onToolsMiddleMan} />
        </div>

    );
}
export default DialogManager