import { DialogType } from '@fluentui/react'
import PRDialog, { IDialogButtonType } from '../components/prDialog'
import { useTranslation } from 'react-i18next'
import { useGroupsState, useSelectedGroupState } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { IGroup } from '../shared/stores/types'
import { AnalyticsService, PREvent } from '../shared/services/analyticsService'
import { FirestoreService } from '../shared/services/firestoreService'

export interface IConfirmGroupDeleteDialogProps {
    onDismiss: () => void
}

export const ConfirmGroupDeleteDialog = (props: IConfirmGroupDeleteDialogProps) => {

    const { t } = useTranslation()
    const { selectedGroup, setSelectedGroup } = useSelectedGroupState()
    const { groups } = useGroupsState()
    const firestoreService = container.resolve(FirestoreService)
    const analyticsService = container.resolve(AnalyticsService)

    const onDelete = async () => {
        props.onDismiss()
        if (selectedGroup != null) {
            const deletedGroupUid = selectedGroup.uid
            let newGroup: IGroup | null = null
            for (const potentialGroup of groups) {
                if (potentialGroup.uid !== deletedGroupUid) {
                    newGroup = potentialGroup
                    break
                }
            }

            await firestoreService.deleteGroup(selectedGroup)
            setSelectedGroup(newGroup)
            analyticsService.log(PREvent.deleteGroup)
        }
    }

    return (
        <PRDialog 
            hidden={false}
            type={DialogType.normal} 
            title={t('menu_delete_group')} 
            subText={t('delete_group_confirm')} 
            cancelButton={{text: t('general_cancel'), onClick: props.onDismiss}} 
            actionButton={{text: t('menu_popup_delete'), onClick: onDelete, type: IDialogButtonType.Destructive}} 
            onDismiss={props.onDismiss}> 
        </PRDialog>
    )
}