import { useEffect } from 'react'
import { container } from 'tsyringe'
import { ConsoleLoggerService } from '../shared/services/consoleLoggerService'

export interface IPasteImage {
  image: string,
  fileName: string
}

export interface IProps {
  onPaste: (images: IPasteImage) => void
  acceptedFiles: string[]
}

type BlobLikeFile = File | null

export const PasteListener = (props: IProps) => {

  const consoleLogger = container.resolve(ConsoleLoggerService)

  const isValidFormat = (fileType: string): boolean => {
    return props.acceptedFiles.includes(fileType)
  }

  const transformImages = (data: DataTransfer) => {
    // NOTE: This needs to be a for loop, it's a list like object
    for (let i = 0; i < data.items.length; i++) {
      const type = data.items[i].type;
      if (isValidFormat(type) !== false) {
        const blob: BlobLikeFile = data.items[i].getAsFile()
        const URL = window.URL


        if (blob) {
          let fileName = blob.name
          if (fileName.length === 0) {
            fileName = 'dmyst_pasted_img.jpg'
          }
          // We shouldn't fire the callback if we can't create `new Blob()`
          const src = URL.createObjectURL(blob)

          const obj: IPasteImage = { image: src, fileName }
          props.onPaste(obj)
          break
        }
      } else {
        consoleLogger.log(`Ignored format type: ${type}`)
      }
    }
  }

  const checkPasted = (e: ClipboardEvent) => {
    e.clipboardData && e.clipboardData.items.length > 0
      ? transformImages(e.clipboardData)
      : consoleLogger.error('Failed to capture paste event')
  }

  const pasteHandler = (e: ClipboardEvent) => checkPasted(e)

  useEffect(() => {
    document.addEventListener('paste', pasteHandler)

    return () => {
      document.removeEventListener('paste', pasteHandler)
    }
  }, [])

  return null
}
