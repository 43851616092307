import { DefaultButton, PrimaryButton, Slider, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react'
import { useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Area, Point } from 'react-easy-crop/types'
import { useStyles } from '../assets/styles'
import { Icons, PRButton } from '../shared/utils/icons'

interface IProp {
    hide: boolean,
    file: File | null,
    onClose: () => void,
    onCompleted: (file: File | null) => void
}
const CropView = (props: IProp) => {

    const styles = useStyles()
    const [crop, setCrop] = useState<Point>({x: 0, y: 0})
    const [zoom, setZoom] = useState<number>(1)
    const [rotate, setRotate] = useState<number>(0)
    const [aspect, setAspect] = useState<number>(1)
    const [cropImage, setCropImage] = useState<string>('')
    const [cropArea, setCropArea] = useState<Area>({x: 0, y: 0, width: 0, height: 0})
    const [fileName, setFileName] = useState<string>('')
    const [processing, setProcessing] = useState<boolean>(false)

    const onCropChange = (location: Point) => {
        setCrop(location)
    }

    const onCropComplete = (cropArea: Area, cropAreaPixel: Area) => {
        setCropArea(cropAreaPixel)
    }

    const getBase64 = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          setCropImage(reader.result as string)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }

    const onRotateLeft = () => {
        let newRotate = rotate - 90
        if (newRotate < 0) {
            newRotate = 0
        }
        setRotate(newRotate)

    }

    const onRotateRight = () => {
        let newRotate = rotate + 90
        if (newRotate > 360) {
            newRotate = 360
        }
        setRotate(newRotate)
    }

    useEffect(() => {
        onReset()
        if (props.file != null) {
            getBase64(props.file)
            if (props.file.name.length > 0) {
                setFileName(props.file.name)
            }
            else {
                setFileName('dmyst_img_cropped.jpg')
            }
        }
    }, [props.file])

    const onSave = async () => {
        setProcessing(true)
        const img = await getCroppedImg(cropImage, cropArea)
        props.onCompleted(img)
        props.onClose()
    }

    const onReset = () => {
        setProcessing(false)
        setCrop({x: 0, y: 0})
        setRotate(0)
        setZoom(1)
        setAspect(1)
        setCropImage('')
        setCropArea({x: 0, y: 0, width: 0, height: 0})
        setFileName('')
    }

    const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })

    const getCroppedImg = async (imageSrc: string, crop: Area) => {
        const image: HTMLImageElement = await createImage(imageSrc)
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const maxSize = Math.max(image.width, image.height)
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

        canvas.width = safeArea
        canvas.height = safeArea

        ctx?.translate(safeArea / 2, safeArea / 2)
        ctx?.rotate((rotate * Math.PI) / 180)
        ctx?.translate(-safeArea / 2, -safeArea / 2)

        ctx?.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        )
        const data = ctx?.getImageData(0, 0, safeArea, safeArea)

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = crop.width
        canvas.height = crop.height

        if (data != null) {
            // paste generated rotate image with correct offsets for x,y crop values.
            ctx?.putImageData(
                data,
                Math.round(0 - safeArea / 2 + image.width * 0.5 - crop.x),
                Math.round(0 - safeArea / 2 + image.height * 0.5 - crop.y)
            )

            let type = 'image/jpeg'
            if (fileName.endsWith('png')) {
                type = 'image/png'
            }

            return new Promise<File | null>((resolve) => {
                canvas.toBlob((blob) => {
                    if (blob != null) {
                        const file = new File([blob], fileName)
                        resolve(file)
                    }
                    else {
                        resolve(null)
                    }
                }, type)
            })
        }
        else {
            return new Promise<File | null>((resolve) => {
                resolve(null)
            })
        }
    }

    return (

        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: styles.prColors.backgroundColor.color('DD'), display: props.hide ? 'none' : 'block'}}>
            <PRButton style={{width: 60, height: 60, position: 'absolute', top: 0, right: 0}} icon={Icons.Dismiss24Filled} color={styles.prColors.textColor.color()} onClick={props.onClose}/>
            <PrimaryButton style={{width: 60, height: 44, position: 'absolute', top: 11, right: 80, fontSize: 18, display: processing ? 'none': 'block'}} text={'Save'} onClick={onSave}/>
            <Spinner size={SpinnerSize.medium} style={{position: 'absolute', top: 24, right: 115, display: processing ? 'block': 'none'}}/>
            <Stack horizontalAlign={'center'}>
                <div style={{height: 400, width: '100%', maxWidth: 800, position: 'relative', marginTop: 60}}>
                    <Cropper image={cropImage} crop={crop} zoom={zoom} rotation={rotate}
                    aspect={aspect} onCropChange={onCropChange} onCropComplete={onCropComplete} onZoomChange={setZoom}/>
                </div>
                <Stack horizontal verticalAlign={'center'} style={{marginTop: 16}}>
                    <Text style={{color: styles.prColors.textColor.color(), fontSize: '16px', fontWeight: 600, marginRight: 16}}>ZOOM</Text>
                    <Slider styles={{root: {width: 300}}} min={1} step={0.1} max={4} value={zoom} onChange={setZoom} showValue={false}/>
                </Stack>

                <Stack horizontal verticalAlign={'center'} style={{marginTop: 16}}>
                    <Text style={{color: styles.prColors.textColor.color(), fontSize: '16px', fontWeight: 600, marginRight: 16}}>ROTATE</Text>
                    <Slider styles={{root: {width: 300}}} min={0} step={1} max={360} value={rotate} onChange={setRotate} showValue={false}/>
                    <PRButton style={{width: 44, height: 44, marginLeft: 8}} icon={Icons.ArrowRotateLeft24Regular} color={styles.prColors.primaryColor.color()} onClick={onRotateLeft}/>
                    <PRButton style={{width: 44, height: 44}} icon={Icons.ArrowRotateRight24Regular} color={styles.prColors.primaryColor.color()} onClick={onRotateRight}/>
                </Stack>

                <Stack horizontal verticalAlign={'center'} style={{marginTop: 16}} tokens={{childrenGap: 8}}>
                    <DefaultButton text={'1:1'} onClick={() => setAspect(1)}/>
                    <DefaultButton text={'4:3'} onClick={() => setAspect(4/3)}/>
                    <DefaultButton text={'16:9'} onClick={() => setAspect(16/9)}/>
                </Stack>
            </Stack>
        </div>
    )
}

export default CropView