import { container } from 'tsyringe'
import { useItemActions } from './useItemsAction'
import { StorageService } from '../services/storageService'
import { useImageCache } from '../stores/stores'
import { IItem, ItemType } from '../stores/types'

export const useImageCaching = () => {
    const storageService = container.resolve(StorageService)
    const { cache, addImageCache, clearImageCache } = useImageCache()
    const { isVideo } = useItemActions()
 
    const processImages = async (items: IItem[]) => {
        for (const item of items) {
            if (item.type === ItemType.Image || item.type === ItemType.File) {
                if (cache.find(i => i.uid == item.uid) != null) {
                    continue
                }

                const url = await storageService.getItemDownloadUrl(item)
                if (item.type === ItemType.File && isVideo(item)) {
                    await captureThumbnail(item, url)
                } else {
                    addImageCache({ uid: item.uid, downloadUrl: url })
                }
            }
        }
    }

    const cleanupImageCache = () => {
        clearImageCache()
    }

    const captureThumbnail = async (item: IItem, videoUrl: string) => {
        const dataURL = await getThumbnailFromVideo(videoUrl)
        addImageCache({ uid: item.uid, downloadUrl: videoUrl, videoThumbnailUrl: dataURL })
    };

    const getThumbnailFromVideo = (url: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.src = url;
            video.crossOrigin = 'anonymous';

            const onLoadedData = () => {
                video.currentTime = 1; // Set to 1 second into the video, adjust as needed
            };

            const onSeeked = () => {
                const originalWidth = video.videoWidth;
                const originalHeight = video.videoHeight;
                const aspectRatio = originalWidth / originalHeight;
                let width = 400
                let height = 240

                if (width / height > aspectRatio) {
                    height = width / aspectRatio;
                } else {
                    width = height * aspectRatio;
                }

                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const context = canvas.getContext('2d');
                context?.drawImage(video, 0, 0, width, height);
                const dataURL = canvas.toDataURL('image/png');

                // Clean up
                video.removeEventListener('loadeddata', onLoadedData);
                video.removeEventListener('seeked', onSeeked);
                video.src = '';
                video.load();

                resolve(dataURL);
            };

            const onError = (err: unknown) => {
                // Clean up
                video.removeEventListener('loadeddata', onLoadedData);
                video.removeEventListener('seeked', onSeeked);
                video.removeEventListener('error', onError);
                video.src = '';
                video.load();

                reject(err);
            };

            video.addEventListener('loadeddata', onLoadedData);
            video.addEventListener('seeked', onSeeked);
            video.addEventListener('error', onError);
        })
    }

    return { processImages, cleanupImageCache }
}