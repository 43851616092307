import { container } from 'tsyringe';
import { useGlobalData } from './useGlobalData';
import { useModelFactory } from './useModelFactory';
import { AuthService } from '../services/authService';
import { FirestoreService } from '../services/firestoreService';
import { MissingUserIdError } from '../utils/errors';
import { CollectionKey } from '../stores/types';

export const useLogin = () => {
    const authService = container.resolve(AuthService)
    const firestoreService = container.resolve(FirestoreService)
    const { createUser, createGroup } = useModelFactory()
    const { setup } = useGlobalData()

    const finalizeLogin = async () => {
        const firebaseUser = authService.user
        if (firebaseUser == null) {
            throw new MissingUserIdError()
        }

        const user = createUser(firebaseUser.uid, firebaseUser.email ?? '')
        const doesUserExist = await firestoreService.doesUserExist(user.uid)

        if (!doesUserExist) {
            await firestoreService.add(CollectionKey.Users, user.uid, user)

            const group = createGroup(user.uid, 'General')
            await firestoreService.addGroup(group)
        }

        await setup()
    }

    const loginWithGoogle = async () => {
        await authService.loginWithGoogle()
        await finalizeLogin()
    }

    const loginWithApple = async () => {
        await authService.loginWithApple()
        await finalizeLogin()
    }

    return { loginWithGoogle, loginWithApple, finalizeLogin }
}