import { container, singleton } from 'tsyringe';
import { FirebaseService } from './firebaseService';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics'

export enum PREvent {
    addText = 'addText',
    addImage = 'addImage',
    addFile = 'addFile',
    createGroup = 'createGroup',
    changeGroupName = 'changeGroupName',
    deleteGroup = 'deleteGroup',
    viewPremium = 'viewPremium',
    copyText = 'copyText',
    editText = 'editText',
    previewItem = 'previewItem',
    guardShown = 'guardShown',
    deleteItem = 'deleteItem',
    savedItem = 'savedItem',
    shareItem = 'shareItem',
    openLink = 'openLink',
    capCrop = 'capCrop',
    deleteAccount = 'deleteAccount',
    export = 'export',
    bulkDelete = 'bulkDelete',
    pin = 'pin',
    multiCopy = 'multiCopy'
}

@singleton()
export class AnalyticsService {
    private readonly firebaseService: FirebaseService
    private readonly analytics: Analytics

    constructor() {
        this.firebaseService = container.resolve(FirebaseService)
        this.analytics = getAnalytics(this.firebaseService.firebaseApp)
    }

    public log(event: PREvent) {
        if (process.env.REACT_APP_FIREBASE_SERVER !== 'production') {
            return
        }
        logEvent(this.analytics, event.toString(), { platform: 'web' })
    }

    public logScreen(name: string) {
        if (process.env.REACT_APP_FIREBASE_SERVER !== 'production') {
            return
        }
        logEvent(this.analytics, 'screen_view', {
            firebase_screen: name, 
            firebase_screen_class: name
        });
    }
}