import { Stack, Text } from '@fluentui/react'
import { useState } from 'react'
import { useStyles } from '../assets/styles'
import { IconsView } from '../shared/utils/icons'
import { MoreUtils, PRMore } from '../panels/morePanel'
import { useTranslation } from 'react-i18next'
import { usePremiumState } from '../shared/stores/stores'

interface IProps {
    more: PRMore,
    onClick: (more: PRMore) => void
}
const MoreRow = (props: IProps) => {

    const { t } = useTranslation()
    const styles = useStyles()
    const [hover, setHover] = useState<boolean>(false)
    const { isPremium } = usePremiumState()

    const onHover = () => {
        setHover(true)
    }

    const onHoverLeave = () => {
        setHover(false)
    }

    const onClick = () => {
        props.onClick(props.more)
    }

    const iconColor = props.more == PRMore.Delete ? styles.prColors.errorColor.color() : styles.prColors.primaryColor.color()

    return (
        <Stack>
            <Stack horizontal verticalAlign={'center'} style={{backgroundColor: hover ? styles.prColors.cellSeperatorColor.color() : styles.prColors.backgroundColor.color(), cursor: 'pointer'}} onMouseLeave={onHoverLeave} onMouseOver={onHover} onClick={onClick}>
                <Stack style={{width: 48, height: 48, marginLeft: 0, marginTop: 3}} verticalAlign={'center'} horizontalAlign={'center'}>
                    <div style={{position: 'relative'}}>
                        {IconsView.iconView(MoreUtils.image(props.more), iconColor)}
                    </div>
                </Stack>
                <Text style={{color: styles.prColors.textColor.color(), fontSize: '16px', marginLeft: 16}}>{MoreUtils.text(props.more, isPremium, t)}</Text>
            </Stack>
        </Stack>
    )
}
export default MoreRow