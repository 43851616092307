import { useState, useEffect } from 'react'
import { Panel, Stack, Text, PanelType, Image, PrimaryButton, DefaultButton } from '@fluentui/react'
import { PRButton, Icons } from '../shared/utils/icons'
import {  useStyles } from '../assets/styles'
import bellAllow from '../assets/bell_allow.png'
import bellOn from '../assets/bell_on.png'
import bellOff from '../assets/bell_off.png'
import bellPlain from '../assets/bell_plain.png'
import { Trans, useTranslation } from 'react-i18next'
import { usePremiumDialogState, usePremiumState, usePushGroupsState, useSelectedGroupState } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { FirestoreService } from '../shared/services/firestoreService'

interface IProps {
    isOpen: boolean,
    onDismiss: () => void
}

const AlertsPanel = (props: IProps) => {

    const { t } = useTranslation()
    const styles = useStyles()
    const { isPremium } = usePremiumState()
    const { selectedGroup } = useSelectedGroupState()
    const { pushGroups } = usePushGroupsState()
    const { showPremiumDialog } = usePremiumDialogState()
    const [isSupported, setIsSupported] = useState(false)
    const firestoreService = container.resolve(FirestoreService)

    useEffect(() => {
        const getSupported = async () => {
            const value = await firestoreService.isMessageSupported()
            setIsSupported(value)
        }
        getSupported()
    }, [])

    const askNotificationPermission = async () => {
        await firestoreService.requestTokenAndSend()
        props.onDismiss()
    }

    const snoozeNotifications = () => {
        const groupId = selectedGroup?.uid
        if (groupId != null) {
            firestoreService.removePushGroup(groupId)
            props.onDismiss()
        }
    }

    const turnOnNotifications = () => {
        if (!isPremium && pushGroups.length >= 1) {
            showPremiumDialog(t('push_tagline'))
            return
        }

        const groupId = selectedGroup?.uid
        if (groupId != null) {
            firestoreService.addPushGroup(groupId)
            props.onDismiss()
        }
    }

    const renderContent = () => {
        let imgSrc = bellAllow
        let btn = null
        let opacity = 1.0
        let transComp = null

        const group = selectedGroup
        if (group == null) {
            return
        }

        if (!isSupported) {
            imgSrc = bellOff
            transComp = <Trans i18nKey={'push_notifications_not_supported'}></Trans>
            btn = null
        }
        else if (Notification.permission === 'granted') {
            if (pushGroups.indexOf(group.uid) >= 0) {
                imgSrc = bellOn
                transComp = (
                    <Trans 
                        i18nKey={'push_push_group_enabled'} 
                        values={{0: group.name}}
                        components={{bold: <Text style={{fontSize: 18, fontWeight: 600, color: styles.prColors.textColor.color()}}></Text>}}>
                    </Trans>
                )
                btn = <DefaultButton text={t('push_snooze_notifications')} style={{fontSize: 18, height: 44}} onClick={snoozeNotifications}/>
            }
            else {
                imgSrc = bellPlain
                transComp = (
                    <Trans 
                        i18nKey={'push_push_group_snoozed'} 
                        values={{0: group.name}}
                        components={{bold: <Text style={{fontSize: 18, fontWeight: 600, color: styles.prColors.textColor.color()}}></Text>}}>
                    </Trans>
                )
                btn = <PrimaryButton text={t('push_turn_on_notifications')} style={{fontSize: 18, height: 44}} onClick={turnOnNotifications}/>
                opacity = 0.4
            }
        }
        else if (Notification.permission === 'denied') {
            imgSrc = bellOff
            transComp = <Trans i18nKey={'push_permission_denied_message'}></Trans>
            btn = null
        }
        else {
            imgSrc = bellAllow
            transComp = (
                <Trans 
                    i18nKey={'push_allow_message'} 
                    values={{0: group.name}}
                    components={{bold: <Text style={{fontSize: 18, fontWeight: 600, color: styles.prColors.textColor.color()}}></Text>}}>
                </Trans>
            )
            btn = <PrimaryButton text={t('push_allow_notifications')} style={{fontSize: 18, height: 44}} onClick={askNotificationPermission}/>
        }

        return (
            <Stack horizontalAlign={'center'} style={{marginTop: 24, marginLeft: 24, marginRight: 24}} tokens={{childrenGap: 36}}>
                <Image src={imgSrc} width={100} height={100} style={{opacity}} />
            
                <Text style={{fontSize: 18, color: styles.prColors.subTextColor.color(), textAlign: 'center'}}>
                    {transComp}
                </Text>
                
                {btn}
            </Stack>
        )
    }

    return (
        <Panel isLightDismiss type={PanelType.custom} customWidth={'400px'} isOpen={props.isOpen} onDismiss={props.onDismiss} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.backgroundColor.color()}, main: {backgroundColor: styles.prColors.backgroundColor.color()}, content: {paddingLeft: '16px', paddingRight: '16px'}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton onClick={props.onDismiss} style={{width: 44, height: 44}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <div/>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    {renderContent()}
                </Stack.Item>
            </Stack>
        </Panel>
    )
}

export default AlertsPanel