import { Panel, PanelType, Stack, Text, TextField } from '@fluentui/react'
import { ToastType, useStyles } from '../assets/styles'
import { Icons, PRButton } from '../shared/utils/icons'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useSelectedGroupState, useToast } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { useUtils } from '../shared/utils/utils'
import { AnalyticsService, PREvent } from '../shared/services/analyticsService'
import { AuthService } from '../shared/services/authService'
import { useItemActions } from '../shared/viewModel/useItemsAction'
import { useModelFactory } from '../shared/viewModel/useModelFactory'

interface IProps {
    isOpen: boolean
    isEditMode: boolean
    onDismiss: () => void
}

const CreateGroupPanel = (props: IProps) => {

    const { t } = useTranslation()
    const styles = useStyles()
    const authService = container.resolve(AuthService)
    const analyticsService = container.resolve(AnalyticsService)
    const { selectedGroup, setSelectedGroup } = useSelectedGroupState()
    const { hasNoSpecialChars } = useUtils()
    const { updateGroup } = useItemActions()
    const { createGroup } = useModelFactory()
    const { showToast } = useToast()
    const [text, setText] = useState('')

    useEffect(() => {
        if (props.isEditMode) {
            setText(props.isEditMode ? selectedGroup?.name ?? '' : '')
        } else {
            setText('')
        }
    }, [props.isOpen])

    const textDidChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (value !== undefined) {
            if (value.length > 40) {
                return
            }
            setText(value)
        }
    }

    const onCreateGroupClicked = async () => {
        const userId = authService.userId
        if (isTextValid() && userId != null) {
            props.onDismiss()

            if (props.isEditMode) {
                const group = selectedGroup
                if (group != null) {
                    group.name = text.trim()
                    await updateGroup(group)
                    showToast({ message: t('group_name_changed'), type: ToastType.Announcement })
                    analyticsService.log(PREvent.changeGroupName)
                }
            } else {
                const group = createGroup(userId, text.trim())
                await updateGroup(group)
                setSelectedGroup(group)
                analyticsService.log(PREvent.createGroup)
            }
        }
    }

    const isTextValid = (): boolean => {
        return text.trim().length > 0 && hasNoSpecialChars(text.trim())
    }

    const buttonSize = 44
    return (
        <Panel isLightDismiss type={props.isEditMode ? PanelType.custom : PanelType.customNear} customWidth={'400px'} onLightDismissClick={props.onDismiss} isOpen={props.isOpen} onDismiss={props.onDismiss} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.menuBackgroundColor.color()}, main: {backgroundColor: styles.prColors.menuBackgroundColor.color()}, content: {paddingLeft: '0px', paddingRight: '0px'}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton onClick={props.onDismiss} style={{width: buttonSize, height: buttonSize, marginLeft: 12}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <Text style={{color: styles.prColors.textColor.color(), fontSize: 16, fontWeight: 600, marginTop: 12}}>{props.isEditMode ? t('group_edit_group') : t('group_add_group')}</Text>
                        <PRButton onClick={onCreateGroupClicked} style={{width: buttonSize, height: buttonSize, marginRight: 12, }} icon={Icons.Checkmark24Filled} color={text.length > 0 && isTextValid() ? styles.prColors.greenColor.color() : styles.prColors.iconDisabledColor.color('a6')}/>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginLeft: 24, marginRight: 24, marginTop: 24}}>
                        <Text style={{textAlign: 'center', fontSize: 14, color: styles.prColors.subTextColor.color()}}>{t('add_group_special_chars')}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <TextField autoFocus underlined borderless placeholder={t('group_enter_name')} styles={{root: {padding: '24px', marginTop: 24}, field: {backgroundColor: styles.prColors.menuBackgroundColor.color(), color: styles.prColors.textColor.color()}}} style={{fontSize: 18, fontWeight: 600, lineHeight: '2em', textAlign: 'center'}} value={text} onChange={textDidChange}/>
                </Stack.Item>
            </Stack>
        </Panel>
    )
}
export default CreateGroupPanel