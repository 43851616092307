import { Stack, Text } from '@fluentui/react'
import { useState } from 'react'
import { useStyles } from '../assets/styles'
import { IconsView } from '../shared/utils/icons'
import { PRTool, PRToolUtil } from '../panels/toolsPanel'
import { useTranslation } from 'react-i18next'
import { usePremiumState } from '../shared/stores/stores'

interface IProps {
    tool: PRTool,
    onClick: () => void
}
const ToolsRow = (props: IProps) => {

    const { t } = useTranslation()
    const styles = useStyles()
    const [hover, setHover] = useState(false)
    const { isPremium } = usePremiumState()

    const onHover = () => {
        setHover(true)
    }

    const onHoverLeave = () => {
        setHover(false)
    }

    const onClick = () => {
        props.onClick()
    }

    return (
        <Stack>
            <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
            <Stack horizontal verticalAlign={'center'} style={{backgroundColor: hover ? styles.prColors.cellSeperatorColor.color() : styles.prColors.menuBackgroundColor.color(), cursor: 'pointer'}} onMouseLeave={onHoverLeave} onMouseOver={onHover} onClick={onClick}>
                <Stack style={{width: 64, height: 64, marginLeft: 16, marginTop: 8, marginBottom: 8, backgroundColor: styles.prColors.backgroundColor.color(), borderRadius: 8,
                boxShadow: '2px 2px 2px 1px #00000033'}} verticalAlign={'center'} horizontalAlign={'center'}>
                    <div style={{position: 'relative'}}>
                        {IconsView.iconView(PRToolUtil.image(props.tool), styles.prColors.primaryColor.color())}
                        <Text style={{position: 'absolute', bottom: -5, right: -5, fontSize: 20, display: isPremium ? 'none' : PRToolUtil.requiresPremium(props.tool) ? 'block' : 'none'}}>💎</Text>
                    </div>
                </Stack>
                <Text style={{color: styles.prColors.textColor.color(), fontSize: '18px', marginLeft: 16}}>{PRToolUtil.text(props.tool, t)}</Text>
            </Stack>
            <div style={{backgroundColor: styles.prColors.cellSeperatorColor.color(), height: 1, width: '100%'}}></div>
        </Stack>
    )
}
export default ToolsRow