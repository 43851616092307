import React, { useState, useEffect } from 'react';
import { Nav, INavLink, Stack, Text, IStyle, ProgressIndicator, Image, createTheme } from '@fluentui/react'
import { Styles } from '../assets/styles'
import { PRButton, Icons } from '../shared/utils/icons'
import logoIcon from '../assets/logo_icon.png'
import { Mode, useLightSwitch } from 'use-light-switch'
import { useTranslation } from 'react-i18next';
import CreateGroupPanel from '../panels/createGroupPanel';
import { container } from 'tsyringe';
import { FeedbackDialog } from '../dialogs/feedbackDialog';
import { LogoutConfirmDialog } from '../dialogs/logoutConfirmDialog';
import { useGroupsState, usePremiumDialogState, usePremiumState, useRecentSelectedState, useSelectedGroupState, useStorageSizeState } from '../shared/stores/stores';
import SettingsPanel from '../panels/settingsPanel';
import { CollectionKey, IGroup } from '../shared/stores/types';
import { GROUP_LIMIT } from '../shared/utils/constants';
import { AnalyticsService, PREvent } from '../shared/services/analyticsService';
import { AuthService } from '../shared/services/authService';
import { FirestoreService } from '../shared/services/firestoreService';
import { useSideBarActions } from '../shared/viewModel/useSideBarActions';
import { lastSelectedGroupId } from '../shared/utils/settings';

const SideBar = () => {
    const { t } = useTranslation()
    const { settingsNavLink, logoutNavLink, recentNavLink, feedbackNavLink, premiumInfo, nonPremiumInfo } = useSideBarActions()
    const [showSettingsPanel, setShowSettingsPanel] = useState(false)
    const [showCreateGroup, setShowCreateGroup] = useState(false)
    const [showFeedback, setShowFeedback] = useState(false)
    const [showLogout, setShowLogout] = useState(false)
    const [navGroupItems, setNavGroupItems] = useState<INavLink[]>([])
    const { groups, setGroups } = useGroupsState()
    const { selectedGroup, setSelectedGroup } = useSelectedGroupState()
    const { isRecentSelected, setIsRecentSelected } = useRecentSelectedState()
    const { storageSize } = useStorageSizeState()
    const { isPremium } = usePremiumState()
    const { showPremiumDialog } = usePremiumDialogState()
    const authService = container.resolve(AuthService)
    const firestoreService = container.resolve(FirestoreService)
    const analyticsService = container.resolve(AnalyticsService)

    useEffect(() => {
        const navGroups: INavLink[] = []
        for (const group of groups) {
            const navLink: INavLink = {
                name: group.name,
                url: '',
                key: group.uid
            }
            navGroups.push(navLink);
        }
        setNavGroupItems(navGroups)

        const filtered = groups.filter( g => g.uid === selectedGroup?.uid) 
        if (filtered.length === 0 && groups.length > 0 && selectedGroup != null) {
            setSelectedGroup(groups[0])
        }

    }, [groups])

    useEffect(() => {
        const userId = authService.userId
        if (userId == null) {
            return
        }
        const unsubscribe = firestoreService.observe<IGroup>(CollectionKey.Groups, 'owner', userId, (list: IGroup[]) => {
            setGroups(list)
        })
        return unsubscribe
    }, [])

    const onClickAction = (ev?: React.MouseEvent<HTMLElement, MouseEvent>, item?: INavLink | undefined) => {
        ev?.preventDefault()
        if (item !== undefined) {
            const filteredGroups = groups.filter( group => group.uid === item.key);
            const group = filteredGroups[0];
            if (group.uid == selectedGroup?.uid) {
                return
            }
            setSelectedGroup(group)
            setIsRecentSelected(false)
        }
    }

    const onRecentAction = (ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
        ev?.preventDefault()
        setSelectedGroup(null)
        setIsRecentSelected(true)
    }

    const openAddGroupDialog = () => {
        if (!isPremium && groups.length >= GROUP_LIMIT) {
            showPremiumDialog(t('group_tagline'))
            analyticsService.log(PREvent.guardShown)
            return
        }
        setShowCreateGroup(true)
    }

    const onPremiumClick = () => {
        showPremiumDialog('')
        analyticsService.log(PREvent.viewPremium)
    }

    const onSettingsClick = () => {
        setShowSettingsPanel(true)
    }

    const mode = useLightSwitch()
    const useDark = mode === Mode.Dark
    const styles = new Styles(useDark)
    const textColor = styles.prColors.textColor.color()
    const subTextColor = styles.prColors.subTextColor.color()
    const greetingTextStyle = {...styles.bodySize, ...{color: textColor, fontWeight: 700, marginTop: '16px'}}
    const emailTextStyle = {...styles.subBodySize, ...{color: subTextColor, fontWeight: 500}}
    const groupsHeaderStyle = {...styles.bodySize, ...{color: textColor, fontWeight: 600, fontSize: 14, marginLeft: 20}}
    const linkTextStyle: IStyle = {color: textColor, fontSize: 16}
  
    const divide = 1000000
    const currentSize = storageSize
    const storageLimit = isPremium ? 500000000 : 50000000
    const sizePercentage = currentSize / storageLimit
    const sizeLabel = Math.round(currentSize/divide) + 'MB of ' + (storageLimit/divide) + 'MB'

    const myTheme = createTheme({
        palette: {
            themePrimary: styles.prColors.primaryColor.color(),
            neutralLight: styles.prColors.primaryColor.color('33'),
            neutralLighter: styles.prColors.primaryColor.color('1a')
        }});

    let greeting = t('menu_panel_good_morning')
    const hours = new Date().getHours()
    if (hours > 18) {
        greeting = t('menu_panel_good_evening')
    }
    else if (hours > 12) {
        greeting = t('menu_panel_good_afternoon')
    }
    
    return(
        <Stack styles={{root: {height: '100%', backgroundColor: styles.prColors.menuBackgroundColor.color()}}}>
            <div className={'sidebar'} style={{overflowY: 'auto'}}>
                <Stack.Item>
                    <Stack horizontalAlign={'center'} styles={{root: {marginTop: '20px', marginLeft: '16px', marginRight: '16px'}}}>
                        <Image src={logoIcon} width={44} height={44}/>
                        <Text style={greetingTextStyle}>
                            {greeting}
                        </Text>
                        <div style={{width: '100%', wordWrap: 'break-word', textAlign: 'center', marginTop: '4px'}}>
                            <Text style={emailTextStyle}>
                                {authService.user?.email ?? ''}
                            </Text>
                        </div>
                    </Stack>
                </Stack.Item>
                <Stack.Item styles={{root: {marginTop: '20px'}}}>
                    <Stack style={{margin: '16px', padding: '16px', cursor: 'pointer', background: styles.prColors.backgroundColor.color(), borderRadius: '8px', boxShadow: depth4}}
                        onClick={onPremiumClick}>
                        <Text style={{color: textColor, fontSize: '16px', fontWeight: 600}}>{isPremium ? premiumInfo().title : nonPremiumInfo().title}</Text>
                        <Text style={{color: subTextColor, fontSize: '14px', marginTop: '4px'}}>{isPremium ? premiumInfo().description : nonPremiumInfo().description}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Nav
                        theme={myTheme}
                        selectedKey={''}
                        styles={{groupContent: navGroupContent, linkText: linkTextStyle, link: {paddingLeft: 15}}}
                        groups={[{ links: [recentNavLink()] }]}
                        onLinkClick={onRecentAction}
                    />
                </Stack.Item>
                <Stack.Item styles={{root: { marginTop: '10px' }}}>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <Stack.Item align={'center'}>
                            <Text style={groupsHeaderStyle}>
                                {t('menu_panel_groups')}
                            </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <PRButton icon={Icons.TextBulletAdd24Filled} style={{width: 44, height: 44, marginRight: '8px'}} onClick={openAddGroupDialog}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item styles={{root: {marginTop: '5px'}}}>
                    <Nav 
                        theme={myTheme}
                        selectedKey={isRecentSelected ? '' : selectedGroup?.uid ?? ''}
                        styles={{groupContent: navGroupContent, linkText: linkTextStyle}}
                        groups={[{ links: navGroupItems }]}
                        onLinkClick={onClickAction}
                    />
                </Stack.Item>
                <Stack.Item styles={{root: { marginTop: '28px' }}}>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <Stack.Item align={'center'}>
                            <Text style={groupsHeaderStyle}>
                                {t('menu_panel_storage')}
                            </Text>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item align={'stretch'} styles={{root: {marginTop: '10px'}}}>
                    <Stack style={{margin: '16px', padding: '16px', background: styles.prColors.backgroundColor.color(), borderRadius: '8px', boxShadow: depth4}}>
                        <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'end'}>
                            <Text style={{color: subTextColor, fontSize: 16}}>{sizeLabel}</Text>
                        </Stack>
                        <ProgressIndicator styles={{progressTrack: {backgroundColor: styles.prColors.primaryColor.color('33')}}} percentComplete={sizePercentage}/>
                    </Stack>
                </Stack.Item>
                <Stack.Item styles={{root: { marginTop: '28px' }}}>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <Stack.Item align={'center'}>
                            <Text style={groupsHeaderStyle}>
                                {t('menu_panel_more')}
                            </Text>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item styles={{root: {marginTop: '10px'}}}>
                    <Nav
                        theme={myTheme}
                        selectedKey={''}
                        styles={{groupContent: navGroupContent, linkText: linkTextStyle, link: {paddingLeft: 15}}}
                        groups={[{ links: [feedbackNavLink()] }]}
                        onLinkClick={() => setShowFeedback(true)}
                    />
                    <Nav
                        theme={myTheme}
                        selectedKey={''}
                        styles={{groupContent: navGroupContent, linkText: linkTextStyle, link: {paddingLeft: 15}}}
                        groups={[{ links: [settingsNavLink()] }]}
                        onLinkClick={onSettingsClick}
                    />
                    <Nav
                        theme={myTheme}
                        selectedKey={''}
                        styles={{groupContent: navGroupContent, linkText: linkTextStyle, link: {paddingLeft: 15}}}
                        groups={[{ links: [logoutNavLink()] }]}
                        onLinkClick={() => setShowLogout(true)}
                    />
                </Stack.Item>
                
            </div>

            <SettingsPanel isOpen={showSettingsPanel} onDismiss={() => setShowSettingsPanel(false)} />

            <CreateGroupPanel isOpen={showCreateGroup} isEditMode={false} onDismiss={() => setShowCreateGroup(false)} />

            { showLogout && <LogoutConfirmDialog onDismiss={() => setShowLogout(false)} /> }
            { showFeedback && <FeedbackDialog onDismiss={() => setShowFeedback(false)} /> }
        </Stack>
    )
}

export default SideBar

const navGroupContent: IStyle = {
    margin: 0
}
const depth0 = '0 0 0 0 transparent';
const depth4 = '0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)';
const depth8 = '0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)';
const depth16 = '0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)';
const depth64 = '0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)';
