import { Stack, Text } from '@fluentui/react'
import { useState, useEffect, ReactNode } from 'react'
import { ToastType, useStyles } from '../assets/styles'
import RecentCell from './recentCell'
import { singleTapEnabled } from '../shared/utils/settings'
import TextEditView from '../panels/textEditPanel'
import ImageViewerHandler from './imageViewerHandler'
import { useTranslation } from 'react-i18next'
import { IImageCache, useImageCache, usePremiumDialogState, usePremiumState, useToast } from '../shared/stores/stores'
import { container } from 'tsyringe'
import { AnalyticsService } from '../shared/services/analyticsService'
import { FirestoreService } from '../shared/services/firestoreService'
import { IItem, ItemType } from '../shared/stores/types'
import { useModelFactory } from '../shared/viewModel/useModelFactory'
import { useImageCaching } from '../shared/viewModel/useImageCaching'
import { ConsoleLoggerService } from '../shared/services/consoleLoggerService'

const RecentView = () => {

    const { t } = useTranslation();
    const styles = useStyles()

    const [textList, setTextList] = useState<IItem[]>([])
    const [linksList, setLinksList] = useState<IItem[]>([])
    const [imagesList, setImagesList] = useState<IItem[]>([])
    const [filesList, setFilesList] = useState<IItem[]>([])

    const [itemForEdit, setItemForEdit] = useState<IItem | null>(null)
    const [viewImageUrl, setViewImageUrl] = useState<string | null>(null)

    const { createItem } = useModelFactory()
    const { isPremium } = usePremiumState()
    const { showPremiumDialog } = usePremiumDialogState()
    const { cache: imageCache } = useImageCache()
    const { processImages } = useImageCaching()
    const { showToast } = useToast()
    const consoleLoggerService = container.resolve(ConsoleLoggerService)
    const firestoreService = container.resolve(FirestoreService)
    const analyticsService = container.resolve(AnalyticsService)

    const textLimit = isPremium ? 10 : 5
    const otherLimit = isPremium ? 10 : 3

    useEffect(() => {
        analyticsService.logScreen('RecentView')

        const listener = firestoreService.observeRecent(ItemType.Text, textLimit, (list: IItem[]) => {
            consoleLoggerService.log('Recent text listener fired')
            setTextList(list)
        })
        return listener
    }, [])

    useEffect(() => {
        const listener = firestoreService.observeRecent(ItemType.Link, otherLimit, (list: IItem[]) => {
            consoleLoggerService.log('Recent link listener fired')
            setLinksList(list)
        })
        return listener
    }, [])

    useEffect(() => {
        const listener = firestoreService.observeRecent(ItemType.Image, otherLimit, (list: IItem[]) => {
            consoleLoggerService.log('Recent image listener fired')
            setImagesList(list)
        })
        return listener
    }, [])

    useEffect(() => {
        const listener = firestoreService.observeRecent(ItemType.File, otherLimit, (list: IItem[]) => {
            consoleLoggerService.log('Recent file listener fired')
            setFilesList(list)
        })
        return listener
    }, [])

    useEffect(() => {
        processAllThings()
    }, [imagesList, filesList])

    const processAllThings = async () => {
        await processImages(imagesList)
        await processImages(filesList)
    }

    const onClick = (item: IItem | null) => {
        if (item == null) {
            if (!isPremium) {
                showPremiumDialog(t('recent_tagline'))
            }
            return
        }
        if (singleTapEnabled()) {
            if (item.type == ItemType.Text || item.type == ItemType.Link) {
                navigator.clipboard.writeText(item.content)
                showToast({message: t('toast_copied_clipboard'), type: ToastType.Announcement})
            }
            else if (item.type == ItemType.Image) {
                const itemImage = imageCache.find(ii => ii.uid == item.uid)
                downloadFile(item, itemImage)
            }
            else if (item.type == ItemType.File) {
                const itemImage = imageCache.find(ii => ii.uid == item.uid)
                downloadFile(item, itemImage)
            }
        } else {
            if (item.type == ItemType.Text) {
                setItemForEdit(item)
            }
            else if (item.type == ItemType.Link) {
                let link = item.content
                if (!link.startsWith('http')) {
                    link = 'http://' + link
                }
                window.open(link, '_blank') 
            }
            else if (item.type == ItemType.Image) {
                const itemImage = imageCache.find(ii => ii.uid == item.uid)
                if (itemImage != null) {
                    setViewImageUrl(itemImage.downloadUrl)
                }
            }
            else if (item.type == ItemType.File) {
                const itemImage = imageCache.find(ii => ii.uid == item.uid)
                downloadFile(item, itemImage)
            }
        }
    }

    const downloadFile = (item: IItem, itemImage?: IImageCache) => {
        if (itemImage != null) {
            const link = document.createElement('a');
            link.download = item.content
            link.target = '_blank'
            link.href = itemImage.downloadUrl
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const onTextEditPanelDismiss = () => {
        setItemForEdit(null)
    }

    const onImageViewerDismiss = () => {
        setViewImageUrl(null)
    }

    const buildList = (header: string, list: IItem[]) => {
        const cells: ReactNode[] = []
        let index = 0
        for (const item of list) {
            const image = imageCache.find(ii => ii.uid == item.uid)
            cells.push(<RecentCell key={item.uid} item={item} itemImage={image} onClick={onClick} isPremium={isPremium} index={index} isEmpty={false}/>)
            index++;
        }
        if (list.length == 0) {
            const item = createItem('', '', ItemType.Empty)
            cells.push(<RecentCell key={'empty'} item={item} onClick={onClick} isPremium={isPremium} index={index} isEmpty={true}/>)
        }
        return (
            <div style={{marginTop: 24}}>
                <Text style={{fontSize: 18, color: styles.prColors.textColor.color(), marginTop: 24, marginLeft: 24, fontWeight: 600}}>{header}</Text>
                <Stack horizontal wrap style={{marginTop: 16, marginLeft: 24}}>
                    {cells}
                </Stack>
                <div style={{marginTop: 16, height: 1, width: '100%', backgroundColor: styles.prColors.cellSeperatorColor.color()}}></div>
            </div>
        )
    }
    
    return (
        <div style={{width: '100%', height: '100%', backgroundColor: styles.prColors.backgroundColor.color()}}>
            <Stack style={{width: '100%', height: '100%', backgroundColor: styles.prColors.backgroundColor.color()}}>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'} style={{height: '80px', backgroundColor: styles.prColors.bannerBackgroundColor.color()}}>
                        <Text variant={'xxLarge'} style={{marginLeft: 20, color: '#ffffff', fontWeight: 600}}>
                            {t('menu_panel_recent')}
                        </Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item grow  styles={{root: {overflow: 'auto', position: 'relative'}}}>
                    <Stack style={{marginBottom: 24}}>
                        {buildList(t('recent_recently_text'), textList)}
                        {buildList(t('recent_recently_link'), linksList)}
                        {buildList(t('recent_recently_images'), imagesList)}
                        {buildList(t('recent_recently_files'), filesList)}
                    </Stack>
                </Stack.Item>
            </Stack>

            <TextEditView isOpen={itemForEdit != null} onDismiss={onTextEditPanelDismiss} item={itemForEdit} />

            <ImageViewerHandler visible={viewImageUrl != null} onClose={onImageViewerDismiss} src={viewImageUrl}/>
        </div>
    )
}
export default RecentView