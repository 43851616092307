import { Text } from '@fluentui/react'
import { useStyles } from '../assets/styles'
import { Stack} from '@fluentui/react'
import { usePremiumState } from '../shared/stores/stores'
import { PREMIUM_ITEM_LIMIT, REGULAR_ITEM_LIMIT } from '../shared/utils/constants'

interface IProps {
    itemCount: number,
    fontSize: number,
    isDialog: boolean,
    onClick?: () => void
}

const CounterView = (props: IProps) => {

    const styles = useStyles()
    const { isPremium } = usePremiumState()
    const max = isPremium ? PREMIUM_ITEM_LIMIT : REGULAR_ITEM_LIMIT

    const countText = () => {
        const text = `${props.itemCount}/${max}`
        return text
    }

    const onClick = () => {
        if (props.onClick != null) {
            props.onClick()
        }
    }

    return (
        <Stack verticalAlign={'center'} style={{cursor: props.isDialog ? undefined : 'pointer'}} onClick={onClick}>
            <div style={{
                backgroundColor: props.itemCount > max ? styles.prColors.countBgErrorColor.color() : props.isDialog ? styles.prColors.countDialogBgColor.color() : styles.prColors.countBgColor.color(),
                padding: props.isDialog ? 8 : 4, 
                borderRadius: 4
            }}>
                <Text style={
                    {
                        fontSize: props.fontSize,
                        fontWeight: 600,
                        letterSpacing: props.isDialog ? 4 : 0,
                        color: props.itemCount > max ? '#ffffffe6' : styles.prColors.countTextColor.color()
                    }}>
                    {countText()}
                </Text>
            </div>
            
        </Stack>
    )

}
export default CounterView