import { Panel, PanelType, Stack } from '@fluentui/react'
import { useStyles } from '../assets/styles'
import { Icons, PRButton } from '../shared/utils/icons'
import ToolsRow from '../components/toolsRow'
import { TranslateFunction } from '../shared/utils/localization'

interface IProps {
    isOpen: boolean,
    onDismiss: (tool: PRTool | null) => void
}

export enum PRTool {
    CaptureCrop,
    ClearClipboard
}


export class PRToolUtil {
    static requiresPremium(tool: PRTool): boolean {
        switch (tool) {
            case PRTool.CaptureCrop:
                return true
            case PRTool.ClearClipboard:
                return false
        }
    }

    static image(tool: PRTool): Icons {
        switch (tool) {
            case PRTool.CaptureCrop:
                return Icons.Crop24Regular
            case PRTool.ClearClipboard:
                return Icons.ClipboardCheckmark24Filled
        }
    }

    static text(tool: PRTool, t: TranslateFunction): string {
        switch (tool) {
            case PRTool.CaptureCrop:
                return t('toolbox_web_crop')
            case PRTool.ClearClipboard:
                return t('toolbox_clear_clipboard')
        }
    }
}


const ToolsPanel = (props: IProps) => {

    const styles = useStyles()

    const onCloseClick = () => {
        props.onDismiss(null)
    }

    const onClearClipboard = () => {
        props.onDismiss(PRTool.ClearClipboard)
    }

    const onCapCrop = () => {
        props.onDismiss(PRTool.CaptureCrop)
    }

    const buttonSize = 44
    return (
        <Panel isLightDismiss type={PanelType.customNear} customWidth={'400px'} onLightDismissClick={onCloseClick} isOpen={props.isOpen} onDismiss={onCloseClick} hasCloseButton={false} styles={{overlay: {backgroundColor: styles.prColors.backgroundColor.color('aa')}, commands: {backgroundColor: styles.prColors.menuBackgroundColor.color()}, main: {backgroundColor: styles.prColors.menuBackgroundColor.color()}, content: {paddingLeft: '0px', paddingRight: '0px'}}}>
            <Stack>
                <Stack.Item>
                    <Stack horizontal horizontalAlign={'space-between'}>
                        <PRButton style={{width: buttonSize, height: buttonSize, visibility: 'hidden'}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                        <PRButton onClick={onCloseClick} style={{width: buttonSize, height: buttonSize, marginRight: 12}} icon={Icons.Dismiss24Filled} color={styles.prColors.subTextColor.color()}/>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack style={{marginTop: 8}}>
                        <ToolsRow tool={PRTool.ClearClipboard} onClick={onClearClipboard}/>
                        <ToolsRow tool={PRTool.CaptureCrop} onClick={onCapCrop}/>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Panel>
    )
}
export default ToolsPanel