import { FirebaseApp, initializeApp } from 'firebase/app';
import { HttpsCallable, getFunctions, httpsCallable } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import {singleton} from 'tsyringe';

@singleton()
export class FirebaseService {

    public firebaseApp: FirebaseApp
    constructor() {
        let firebaseConfig = {
            apiKey: 'AIzaSyAlapLGwuqsbubgWzuW0XYjumD1EKzphQo',
            authDomain: 'projectrevengedev.firebaseapp.com',
            databaseURL: 'https://projectrevengedev.firebaseio.com',
            projectId: 'projectrevengedev',
            storageBucket: 'projectrevengedev.appspot.com',
            messagingSenderId: '473151425251',
            appId: '1:473151425251:web:11bd393e2e829c2c2dd3d6',
            measurementId: 'G-M0Z2SZ74P1'
          } 
        if (process.env.REACT_APP_FIREBASE_SERVER === 'production') {
            firebaseConfig = {
                apiKey: 'AIzaSyAQI7r-pgpiugKIk3L5luRK0IciaPjlR3A',
                authDomain: 'dmyst-app.firebaseapp.com',
                databaseURL: 'https://dmyst-app.firebaseio.com',
                projectId: 'dmyst-app',
                storageBucket: 'dmyst-app.appspot.com',
                messagingSenderId: '343370945735',
                appId: '1:343370945735:web:51f41eb5a4a11dc90b9ae2',
                measurementId: 'G-9LGHJ1MRCX'
            }
        }
        
        this.firebaseApp = initializeApp(firebaseConfig);

        if (process.env.REACT_APP_FIREBASE_SERVER === 'production') {
            const appCheck = initializeAppCheck(this.firebaseApp, {
                provider: new ReCaptchaV3Provider('6LfRsTAlAAAAACl1adazzQE-me_1JEPyo5T_jjRw'),
                isTokenAutoRefreshEnabled: true
              });
        }
    }

    public getHttpsCallable<T, H>(path: string): HttpsCallable<T, H> {
        const functions = getFunctions(this.firebaseApp)
        return httpsCallable<T, H>(functions, path)
    }
}