import { CSSProperties } from 'react'
import { Text, Stack, Image } from '@fluentui/react'
import { Styles } from '../assets/styles'
import app_logo from '../assets/app_logo.png';

const DeleteInfo = () => {

    const styles = new Styles(false)

    const stepsStyle: CSSProperties = {color: styles.prColors.subTextColor.color(), fontSize: '20px', textAlign: 'start'}

    return (
        <Stack horizontal style={{height: '100vh', backgroundSize: 'auto, cover, cover', backgroundImage: 'url("https://dmystapp.com/welcome/assets/css/images/overlay2.png"), url("https://dmystapp.com/welcome/assets/css/images/overlay3.svg"), linear-gradient(45deg, rgba(62,140,255,1) 30%, rgba(129,49,244,1) 70%, rgba(129,49,244,1))'}}>
            <Stack horizontal verticalAlign={'center'} style={{height: '100%', width: '100%'}}>
                <Stack horizontalAlign={'center'} style={{justifyContent: 'center', width: '100%', marginBottom: '80px'}}>
                    <Stack horizontal verticalAlign={'center'} style={{marginBottom: '32px'}}>
                        <Image src={app_logo} width={32} height={32}/>
                        <Text style={{color: 'white', fontSize: '32px', fontWeight: 600, marginLeft: '10px'}}>Delete Dmyst Account Data</Text>
                    </Stack>

                    <Stack horizontalAlign={'start'} style={{backgroundColor: styles.prColors.backgroundColor.color(), maxWidth: '700px', padding: '40px', borderRadius: '8px', boxShadow: '3px 3px 3px 2px #00000033', minWidth: '400px'}}>
                        <Text style={{color: styles.prColors.textColor.color(), fontSize: 24, fontWeight: 600}}>Delete From Mobile App</Text>
                        <br></br>
                        <Text style={stepsStyle}>{'You can delete your account and data from inside the Dmyst mobile app. Select the menu, go to Settings, and select "Delete Account" at the bottom. Your account and all data associated with the account will be deleted.'}</Text>
                        <br></br><br></br>
                        <Text style={{color: styles.prColors.textColor.color(), fontSize: 24, fontWeight: 600}}>Delete From Web Dashboard</Text>
                        <br></br>
                        <Text style={stepsStyle}>{'If you\'ve already deleted the mobile app, you can log into the '}<a href="https://www.dmystapp.com/dashboard">Dmyst Web Dashboard</a> on your <b>desktop computer</b>. From there, you can follow the same process to delete your account and data.</Text>
                    </Stack>

                </Stack>
            </Stack>
        </Stack>
    )
}

export default DeleteInfo