// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

export type TranslateFunction = (key: string) => string;


// <Text style={stepsStyle}>1. Launch the <b>Dmyst</b> app on your mobile device</Text>
// <Text style={stepsStyle}>2. Long press the item you want to send</Text>
// <Text style={stepsStyle}>3. Select <b>Scan Quick Send Code</b></Text>
// <Text style={stepsStyle}>4. Scan QR code displayed above</Text>
const resources: Record<string, { translation: Record<string, string> }> = {
  en: {
    translation: {
      'file_upload_failed': 'All files did not upload successfully',
      'five_file_limit': 'Only 5 files can be uploaded at once',
      'general_save': 'Save',
      'text_unsaved': 'Unsaved Text',
      'text_unsaved_msg': 'Do you want to save before closing?',
      'text_start_typing': 'Start typing here',
      'quick_send_download': 'Download from file.io',
      'quick_send_reset': 'Reset',
      'quick_send_footer': 'Files are temporarily stored on <1>file.io</1>. They are secure and value privacy.',
      'quick_send_web_title': 'Send an item from your Dmyst Mobile App',
      'quick_send_step_1': '1. Launch the <1>Dmyst</1> app on your mobile device',
      'quick_send_step_2': '2. Long press the item you want to send',
      'quick_send_step_3': '3. Select <1>Scan Quick Send Code</1>',
      'quick_send_step_4': '4. Scan QR code displayed above',
      'toolbox_web_crop': 'Select and Crop Image',
      'notification_why_seeing': 'Why am I seeing this?',
      'notification_why_seeing_desc': 'Web browser notifications are limited in their functionality and allowing one click copy requires the user\'s action from the browser window.',
      'general_download': 'Download',
      'general_copied': 'Copied!',
      'button_copy_to_clipboard': 'Copy to Clipboard',
      'settings_one_click_sub': 'On single click, text will copy to clipboard, files will download',
      'settings_one_click': 'One Click Copy/Download',
      'premium_web_description': 'You can get premium by downloading the app on your iOS or Android device and subscribing on the app.',
      'logout_deleting_account': 'Deleting account',
      'logout_logging_out': 'Logging out',
      'general_loading': 'Loading',
      'login_apps_mobile_message': 'Download the apps to get started',
      'login_apps_desktop_message': 'Don\'t forget to download the apps',
      'login_apple': 'Sign in with Apple',
      'push_notifications_not_supported': 'Push notifications are not supported for this browser',
      'share_to_twitter': 'Share to Twitter',
      'share_to_whatsapp': 'Share to Whatsapp',
      'feedback_sent': 'Feedback Sent!',
      'general_send': 'Send',
      'add_group_special_chars': 'Most special characters are not allowed except dashes(-) and underscores(_)',
      'items_add_placeholder': 'Add something to this group',
      'menu_delete_group': 'Delete Group',
      'menu_change_group_name': 'Change Group Name',
      'menu_panel_good_morning': 'Good Morning',
      'menu_panel_good_afternoon': 'Good Afternoon',
      'menu_panel_good_evening': 'Good Evening',
      'toolbox_clear_clipboard': 'Clear Clipboard',
      'toolbox_scan_document': 'Scan Document',
      'toolbox_capture_crop_image': 'Capture and Crop Image',
      'toolbox_qr_scanner': 'QR Code Scanner',
      'toolbox_browse_copy': 'Browse and Copy',
      'toolbox_text_recognizer': 'Text Recognizer',
      'toolbox_sub_clear_clipboard': 'Clear the copied content in your clipboard',
      'toolbox_sub_scan_document': 'Use your camera to scan a document into a picture',
      'toolbox_sub_capture_crop_image': 'Take a photo and crop it how you like',
      'toolbox_sub_qr_scanner': 'Scan a QR code to read its contents',
      'toolbox_sub_browse_copy': 'Browse the web and copy content to a group',
      'toolbox_sub_text_recognizer': 'Take a photo and extract the text',
      'menu_panel_groups': 'Groups',
      'menu_panel_more': 'More',
      'menu_panel_storage': 'Storage',
      'settings_general': 'General',
      'settings_app_icon': 'App Icon',
      'settings_social': 'Social',
      'settings_premium': 'Premium',
      'recent_premium_placeholder': 'See More With\nPremium',
      'recent_empty_placeholder': 'Nothing to see here right now',
      'media_uploading': 'Uploading',
      'login_google': 'Sign in with Google',
      'progress_sending': 'Sending',
      'menu_popup_view_file': 'View File',
      'menu_popup_view_image': 'View Image',
      'menu_popup_edit': 'Edit',
      'menu_popup_copy': 'Copy',
      'menu_popup_tag': 'Tag',
      'menu_popup_share': 'Share',
      'menu_popup_scan_quick_send_code': 'Scan Quick Send Code',
      'menu_popup_copy_image': 'Copy Image',
      'menu_popup_save_camera_roll': 'Save to Camera Roll',
      'menu_popup_copy_file': 'Copy File',
      'menu_popup_copy_file_name': 'Copy File Name',
      'menu_popup_save_file': 'Save File',
      'menu_popup_open_link': 'Open Link',
      'menu_popup_delete': 'Delete',
      'menu_popup_text_transform': 'Text Transform',
      'menu_popup_link_comps': 'Link Components',
      'menu_popup_shorten_link': 'Shorten Link',
      'menu_popup_call_number': 'Call Number',
      'toast_image_added': 'Image Added',
      'toast_file_added': 'File Added',
      'error_connection_offline': 'Your connection appears to be offline',
      'error_file_upload_fail': 'File upload has failed',
      'photo_upload_photo': 'Upload Photo',
      'general_cancel': 'Cancel',
      'photo_camera': 'Camera',
      'photo_gallery': 'Gallery',
      'toast_copied_clipboard': 'Copied to Clipboard',
      'toast_undo': 'Undo',
      'clip_clip': 'Clip',
      'clip_empty_placeholder': 'Nothing in your clipboard!',
      'clip_preview_file': 'Preview File',
      'clip_error_placeholder': 'Could not read the clipboard data.\n\nLet us know in feedback what you copied so it can be supported in the future.',
      'feedback_feedback': 'Feedback',
      'feedback_email': 'Email',
      'feedback_optional': 'Optional',
      'feedback_placeholder': 'Write your feedback, comments, and/or suggestions here',
      'group_add_group': 'Add New Group',
      'group_edit_group': 'Edit Group',
      'group_enter_name': 'Enter a Group Name',
      'group_invalid_placeholder': 'Name has invalid characters',
      'group_name_changed': 'Group name changed!',
      'settings_settings': 'Settings',
      'settings_enable_link_previews': 'Enable Link Previews',
      'settings_enable_bio_lock': 'Enable Biometric Lock',
      'settings_one_tap': 'One Tap Copy',
      'settings_one_tap_desc': 'Tapping an item will copy it to your clipboard',
      'settings_keyboard': 'Keyboard',
      'premium_go_premium': 'Go Premium',
      'premium_membership_active': 'Your membership is active',
      'premium_tagline': 'Add more storage, groups and notes with premium! Get started here.',
      'settings_rate_dmyst': 'Rate Dmyst',
      'settings_follow_twitter': 'Follow @dmyst_app | Twitter',
      'settings_facebook_page': 'Facebook Page',
      'settings_delete_account': 'Delete Account',
      'settings_version': 'Version',
      'premium_bio_tagline': 'Use biometrics to unlock Dmyst on app start up',
      'settings_are_you_sure': 'Are you sure?',
      'settings_delete_desc': 'This will delete your account and all data associated with it.',
      'settings_no_sync_desc': 'Settings do not sync across devices',
      'shorten_shorten_link': 'Shorten Link',
      'shorten_shortening': 'Shortening',
      'shorten_copy_link': 'Copy Link',
      'shorten_add_group': 'Add to Group',
      'error_oops': 'Oops, something went wrong. Try again!',
      'transform_upper_case': 'Upper Case',
      'transform_lower_case': 'Lower Case',
      'transform_word_case': 'Word Case',
      'transform_no_space': 'Word Case, No Spaces',
      'transform_reverse_char': 'Reverse Characters',
      'transform_reverse_words': 'Reverse Sentence',
      'transform_reverse_words_case': 'Reverse Sentence, Word Case',
      'transform_space_claps': 'Replace Space with Claps',
      'transform_hashtag': 'Hashtag For Each Word',
      'transform_hashtag_case': 'Hashtag For Each Word, Word Case',
      'transform_upside_down': 'Upside Down Text',
      'transform_trim': 'Trim Whitespace Ends',
      'transform_trim_white_break': 'Trim Whitespace/New Line Ends',
      'transform_url_encode': 'URL Encoded',
      'transform_comma_dash': 'Commas to Dashed List',
      'transform_comma_bullet': 'Commas to Bullet List',
      'transform_remove_numbers': 'Remove Numbers',
      'transform_link_comps': 'Link Components',
      'transform_text_transform': 'Text Transform',
      'transform_scheme': 'Scheme',
      'transform_host': 'Host',
      'transform_path': 'Path',
      'transform_path_sub': 'Path Subcomponent',
      'transform_queries': 'Queries',
      'transform_query': 'Query',
      'transform_tagline': 'Copy these items to your clipboard with premium!',
      'tag_tag_name': 'Tag Name',
      'tag_tag_line': 'Add more than one tag with Premium.',
      'text_text_saved': 'Text Saved!',
      'text_link_saved': 'Link Saved!',
      'text_count_words': '{{0}} words',
      'text_count_character': '{{0}} characters',
      'text_recognizer_text_recognizer': 'Text Recognizer',
      'text_recognizer_choose_image': 'Choose Image',
      'text_recognizer_intro': 'Choose an image to use text recognition!',
      'text_recognizer_processing': 'Processing',
      'text_recognizer_error': 'Nice image, but an error was detected',
      'text_recognizer_no_text': 'Nice image, but no text was detected',
      'push_allow_message': 'Allow push notifications to start receiving alerts when content is added to <bold>{{0}}</bold>',
      'push_allow_notifications': 'Allow Notifications',
      'push_push_group_enabled': 'Push notifications for <bold>{{0}}</bold> are enabled!',
      'push_snooze_notifications': 'Snooze Notifications',
      'push_push_group_snoozed': 'Push notifications for <bold>{{0}}</bold> are snoozed!',
      'push_turn_on_notifications': 'Turn On Notifications',
      'push_permission_denied_message': 'Push notifications permissions have been denied on this device. Turn them on if you wish to receive notifications.',
      'push_app_settings': 'App Settings',
      'push_tagline': 'Turn on notifications for more than 1 group with premium!',
      'app_icon_original': 'Original',
      'app_icon_light': 'Light',
      'app_icon_dark': 'Dark',
      'app_icon_mono_light': 'Mono Light',
      'app_icon_mono_dark': 'Mono Dark',
      'crop_info': 'Choose or capture an image\nthen crop it!',
      'crop_tagline': 'Liked this tool? Use it any time with premium!',
      'keyboard_custom_keyboard': 'Custom Keyboard',
      'keyboard_custom_keyboard_sub': 'Get access to your Dmyst content from any app when you bring up the keyboard',
      'keyboard_get_started': 'Get Started',
      'keyboard_get_started_sub': 'Open the app settings, enable the keyboard and allow full access',
      'keyboard_open_settings': 'Open App Settings',
      'keyboard_full_access': 'Why Full Access?',
      'keyboard_full_access_sub': 'Dmyst retrieves your content from the cloud, so a network connection is required',
      'keyboard_no_tracking': 'No Tracking',
      'keyboard_no_tracking_sub': 'The Dmyst keyboard DOES NOT track any actions you take with keyboard such as key strokes',
      'browser_url_placeholder': 'Type your web address here',
      'browser_copied_group': 'Copied to Group!',
      'browser_tagline': 'Copy items into your group while browsing!',
      'browser_cta': 'If you enable the 📋 icon in the bottom right, anything you copy will be added to the current group.\n\nHow convenient!',
      'login_sub_text': 'Sign in to start direct messaging yourself notes and files.',
      'login_terms': 'Terms and Conditions',
      'login_privacy': 'Privacy Policy',
      'group_tagline': 'Add more than 2 groups with Premium.',
      'menu_panel_logout_warning': 'Are you sure you want to logout?',
      'menu_panel_logout': 'Logout',
      'menu_panel_recent': 'Recent',
      'menu_panel_give_feedback': 'Give Feedback',
      'menu_panel_settings': 'Settings',
      'items_empty_group_gone': 'All the groups are gone!',
      'items_empty_group_sub': 'Enjoy nyan cat in the meantime',
      'tooltip_hold': 'Hold for more options!',
      'items_empty_group': 'This group is empty for now',
      'delete_group_confirm': 'Are you sure you want to delete this group?',
      'toast_clipboard_empty': 'Clipboard is empty',
      'items_tagline': 'Add more notes with Premium.',
      'storage_storage_space': 'Storage Space',
      'storage_storage_space_warning': 'You\'ve reached the storage space limit. Delete some files to free up some space.',
      'storage_tagline': 'Get more storage space with Premium.',
      'menu_select_action': 'Select an action to perform',
      'clip_clip_saved': 'Clip Saved!',
      'clip_clip_failed_message': 'Clipboard item could not be saved',
      'toast_deleted': 'Deleted!',
      'toast_file_copied': 'File Copied!',
      'toast_file_copy_failed': 'This file could not be copied',
      'toast_image_copied': 'Image Copied!',
      'toast_image_copy_failed': 'This image could not be copied',
      'toast_saved': 'Saved!',
      'shorten_link_tagline': 'Shorten links in the app with premium!',
      'text_recognizer_tagline': 'Use text recognizer on images with premium!',
      'clip_clipboard_cleared': 'Clipboard cleared!',
      'drag_drop_cta': 'Drop Here to Add',
      'purchase_purchase_successful': 'Purchase Successful',
      'purchase_purchase_failed': 'Purchase Failed',
      'purchase_thanks': 'Thanks for the purchase! You are now using Dmyst Premium.',
      'purchase_congrats': 'Congratulations',
      'purchase_premium_activated': 'Premium Subscription Activated',
      'purchase_sync_across': 'Sync across devices with more features',
      'feature_bigger_collections': 'Bigger Collections',
      'feature_bigger_collections_sub': 'Organize your content with more groups, more content per group and more storage space',
      'feature_all_tools': 'Access All Tools',
      'feature_all_tools_sub': 'Add multiple tags, more notification groups, text recognizer, link shortener, crop captured images and more',
      'feature_biometric': 'Biometric Lock',
      'feature_biometric_sub': 'Keep your content behind a security lock to prevent others from snooping in the app',
      'feature_one_sub': 'One Subscription',
      'feature_one_sub_sub': 'Purchase on one platform and your premium membership will work across all your devices including iOS, Android and the web',
      'general_continue': 'Continue',
      'purchase_sub_notice': 'Subscriptions will be charged to your credit card through your iTunes account. Subscriptions will automatically renew unless canceled within 24-hours before the end of the current period. You can cancel anytime with your iTunes account settings. For more information, see terms and privacy policy below.',
      'purchase_learn_more': 'Learn More',
      'purchase_restore': 'Restore previous purchase',
      'purchase_month': 'Month',
      'purchase_months': 'Months',
      'viewer_no_preview': 'No Preview Available',
      'viewer_no_preview_message': 'The file isn\'t recognized to show a preview',
      'scanner_quick_send': 'Quick Send',
      'scanner_qr_code': 'QR Code Scanner',
      'scanner_quick_send_title': 'Go to {0} to send this item to your web browser',
      'scanner_qr_code_title': 'Scan a QR code and see the preview here',
      'scanner_invalid_qr': 'Invalid QR Code',
      'scanner_invalid_qr_message': 'The code scanned doesn\'t look right. Make sure you scan on the QR code on the dmystapp.com/quick website.',
      'error_something_wrong': 'Something went wrong',
      'error_something_wrong_message': 'There was an error when sending the content. Please try again.',
      'toolbox_toolbox': 'Toolbox',
      'biometric_authenticate_test': 'Authenticate to test it out!',
      'biometric_authenticate_access': 'Authenticate to access Dmyst',
      'recent_tagline': 'See all recent items with premium!',
      'recent_recently_text': 'Recently Added Text Items',
      'recent_recently_link': 'Recently Added Links',
      'recent_recently_images': 'Recently Added Images',
      'recent_recently_files': 'Recently Added Files',
      'share_share_to_dmyst': 'Share to Dmyst',
      'share_select_group_message': 'Select a group from your Dmyst account to add your content to.',
      'share_no_login': 'Welcome to Dmyst!\n\nTo use the sharing feature, you\'ll need to launch the app to login or create an account first.',
      'share_read_error': 'Something went wrong!\n\nThe shared data contained unknown data or Dmyst could not access the data.',
      'share_non_premium_max_items': 'You hit your item limit!\n\nYou\'ve reached your item limit for this group. Upgrade to Dmyst Premium to add more!',
      'share_non_premium_max_storage': 'You hit your storage limit!\n\nYou\'ve reached your storage limit. Upgrade to Dmyst Premium to add more!',
      'share_premium_max_storage': 'You hit your storage limit!\n\nYou\'ve reached your storage limit. Delete some files from your groups before continuing.',
      'share_upload_error': 'Something went wrong!\n\nThere was an error when attempting to upload the file.',
      'share_sharing': 'Sharing',
      'share_sharing_progress': 'Sharing {0} of {1}',
      'keyboard_allow_full_access': 'Please allow full access to see your Dmyst content',
      'keyboard_login': 'Please log into Dmyst to see your content',
      'keyboard_allow_full_access2': 'Allow full access to copy to clipboard',
      'menu_export_items': 'Export Items',
      'export_export': 'Export',
      'export_export_text_link': 'Export {{0}} Texts And Links As',
      'export_export_images_files': 'Export {{0}} Images And Files As',
      'menu_popup_pin': 'Pin To Top',
      'menu_popup_unpin': 'Unpin',
      'menu_popup_copy_multiple': 'Copy Multiple',
      'toast_nothing_export': 'There\'s nothing to export',
      'limit_items_per_group': 'Groups are limited to {{0}} per group',
      'limit_suggest_premium': 'Add up to {{0}} when you upgrade to Premium',
      'limit_export_delete_suggest': 'Export your data if you wish to keep it and if you need more space, delete some items',
      'export_clean_up': 'Clean Up',
      'export_bulk_delete': 'Bulk Delete',
      'settings_screen_security': 'Screen Security',
      'settings_security': 'Security',
      'settings_screen_security_desc': 'Hide Dmyst contents while switching between apps',
      'share_premium_max_items': 'You\'ve reached your item limit for this group. Use another group or delete items in this group first.',
      'bulk_delete_delete_items': 'Delete Items',
      'bulk_delete_message': 'Are you sure you want to delete the selected items?'
    },
  },
  fr: {
    translation: {
      'file_upload_failed': 'Tous les fichiers n\'ont pas été téléchargés avec succès',
      'five_file_limit': 'Seulement 5 fichiers peuvent être téléchargés à la fois',
      'general_save': 'Enregistrer',
      'text_unsaved': 'Texte non enregistré',
      'text_unsaved_msg': 'Voulez-vous enregistrer avant de fermer ?',
      'text_start_typing': 'Commencez à taper ici',
      'quick_send_download': 'Télécharger depuis file.io',
      'quick_send_reset': 'Réinitialiser',
      'quick_send_footer': 'Les fichiers sont temporairement stockés sur <1>file.io</1>. Ils sont sécurisés et protègent votre vie privée.',
      'quick_send_web_title': 'Envoyer un élément depuis votre application mobile Dmyst',
      'quick_send_step_1': '1. Lancez l\'application <1>Dmyst</1> sur votre appareil mobile',
      'quick_send_step_2': '2. Appuyez longuement sur l\'élément que vous souhaitez envoyer',
      'quick_send_step_3': '3. Sélectionnez <1>Scanner le code d\'envoi rapide</1>',
      'quick_send_step_4': '4. Scannez le code QR affiché ci-dessus',
      'toolbox_web_crop': 'Sélectionner et recadrer l\'image',
      'notification_why_seeing': 'Pourquoi est-ce que je vois cela ?',
      'notification_why_seeing_desc': 'Les notifications du navigateur Web sont limitées dans leur fonctionnalité et permettre la copie en un clic nécessite l\'action de l\'utilisateur à partir de la fenêtre du navigateur.',
      'general_download': 'Télécharger',
      'general_copied': 'Copié!',
      'button_copy_to_clipboard': 'Copier dans le presse-papiers',
      'settings_one_click': 'Copie/Téléchargement en un clic',
      'settings_one_click_sub': 'Sur un seul clic, le texte sera copié dans le presse-papiers, les fichiers seront téléchargés',
      'premium_web_description': 'Vous pouvez obtenir un abonnement premium en téléchargeant l\'application sur votre appareil iOS ou Android et en vous abonnant dans l\'application.',
      'logout_deleting_account': 'Suppression du compte',
      'logout_logging_out': 'Déconnexion',
      'general_loading': 'Chargement',
      'login_apps_mobile_message': 'Téléchargez les applications pour commencer',
      'login_apps_desktop_message': 'N\'oubliez pas de télécharger les applications',
      'login_apple': 'Se connecter avec Apple',
      'push_notifications_not_supported': 'Les notifications push ne sont pas prises en charge pour ce navigateur',
      'share_to_twitter': 'Partager sur Twitter',
      'share_to_whatsapp': 'Partager sur WhatsApp',
      'feedback_sent': 'Commentaires envoyés !',
      'general_send': 'Envoyer',
      'add_group_special_chars': 'La plupart des caractères spéciaux ne sont pas autorisés sauf les tirets (-) et les tirets bas (_)',
      'items_add_placeholder': 'Ajouter quelque chose à ce groupe',
      'menu_delete_group': 'Supprimer le groupe',
      'menu_change_group_name': 'Changer le nom du groupe',
      'menu_panel_good_morning': 'Bonjour',
      'menu_panel_good_afternoon': 'Bon après-midi',
      'menu_panel_good_evening': 'Bonsoir',
      'toolbox_clear_clipboard': 'Effacer le presse-papiers',
      'toolbox_scan_document': 'Scanner le document',
      'toolbox_capture_crop_image': 'Capturer et recadrer l\'image',
      'toolbox_qr_scanner': 'Scanner de code QR',
      'toolbox_browse_copy': 'Naviguer et copier',
      'toolbox_text_recognizer': 'Reconnaissance de texte',
      'toolbox_sub_clear_clipboard': 'Effacez le contenu copié dans votre presse-papiers',
      'toolbox_sub_scan_document': 'Utilisez votre appareil photo pour numériser un document en image',
      'toolbox_sub_capture_crop_image': 'Prenez une photo et recadrez-la selon vos préférences',
      'toolbox_sub_qr_scanner': 'Numérisez un code QR pour lire son contenu',
      'toolbox_sub_browse_copy': 'Parcourez le web et copiez le contenu dans un groupe',
      'toolbox_sub_text_recognizer': 'Prenez une photo et extrayez le texte',
      'menu_panel_groups': 'Groupes',
      'menu_panel_more': 'Plus',
      'menu_panel_storage': 'Stockage',
      'settings_general': 'Général',
      'settings_app_icon': 'Icône de l\'application',
      'settings_social': 'Social',
      'settings_premium': 'Premium',
      'recent_premium_placeholder': 'Voir plus avec\nPremium',
      'recent_empty_placeholder': 'Rien à voir ici\npour le moment',
      'media_uploading': 'Chargement en cours',
      'login_google': 'Se connecter avec Google',
      'progress_sending': 'Envoi en cours',
      'menu_popup_view_file': 'Voir le fichier',
      'menu_popup_view_image': 'Voir l\'image',
      'menu_popup_edit': 'Modifier',
      'menu_popup_copy': 'Copier',
      'menu_popup_tag': 'Tag',
      'menu_popup_share': 'Partager',
      'menu_popup_scan_quick_send_code': 'Scanner le code d\'envoi rapide',
      'menu_popup_copy_image': 'Copier l\'image',
      'menu_popup_save_camera_roll': 'Enregistrer dans Pellicule',
      'menu_popup_copy_file': 'Copier le fichier',
      'menu_popup_copy_file_name': 'Copier le nom du fichier',
      'menu_popup_save_file': 'Enregistrer le fichier',
      'menu_popup_open_link': 'Ouvrir le lien',
      'menu_popup_delete': 'Supprimer',
      'menu_popup_text_transform': 'Transformation de texte',
      'menu_popup_link_comps': 'Composants de lien',
      'menu_popup_shorten_link': 'Raccourcir le lien',
      'menu_popup_call_number': 'Appeler le numéro',
      'toast_image_added': 'Image ajoutée',
      'toast_file_added': 'Fichier ajouté',
      'error_connection_offline': 'Votre connexion semble être hors ligne',
      'error_file_upload_fail': 'Échec du téléchargement du fichier',
      'photo_upload_photo': 'Télécharger une photo',
      'general_cancel': 'Annuler',
      'photo_camera': 'Appareil photo',
      'photo_gallery': 'Galerie',
      'toast_copied_clipboard': 'Copié dans le presse-papiers',
      'toast_undo': 'Annuler',
      'clip_clip': 'Pincer',
      'clip_empty_placeholder': 'Rien dans votre presse-papiers !',
      'clip_preview_file': 'Aperçu du fichier',
      'clip_error_placeholder': 'Impossible de lire les données du presse-papiers.\n\nFaites-nous savoir dans les commentaires ce que vous avez copié pour qu\'il puisse être pris en charge à l\'avenir.',
      'feedback_feedback': 'Retour d\'information',
      'feedback_email': 'Email',
      'feedback_optional': 'Facultatif',
      'feedback_placeholder': 'Écrivez vos commentaires, suggestions et/ou remarques ici',
      'group_add_group': 'Ajouter un nouveau groupe',
      'group_edit_group': 'Modifier le groupe',
      'group_enter_name': 'Entrer un nom de groupe',
      'group_invalid_placeholder': 'Le nom contient des caractères non valides',
      'group_name_changed': 'Nom du groupe modifié !',
      'settings_settings': 'Paramètres',
      'settings_enable_link_previews': 'Activer les aperçus de lien',
      'settings_enable_bio_lock': 'Activer le verrou biométrique',
      'settings_one_tap': 'Copie en un clic',
      'settings_one_tap_desc': 'Taper sur un élément le copiera dans votre presse-papiers',
      'settings_keyboard': 'Clavier',
      'premium_go_premium': 'Passer à l\'abonnement Premium',
      'premium_membership_active': 'Votre abonnement est actif',
      'premium_tagline': 'Ajoutez plus de stockage, de groupes et de notes avec Premium ! Commencez dès maintenant.',
      'settings_rate_dmyst': 'Évaluer Dmyst',
      'settings_follow_twitter': 'Suivez @dmyst_app | Twitter',
      'settings_facebook_page': 'Page Facebook',
      'settings_delete_account': 'Supprimer le compte',
      'settings_version': 'Version',
      'premium_bio_tagline': 'Utilisez la biométrie pour déverrouiller Dmyst au démarrage de l\'application',
      'settings_are_you_sure': 'Êtes-vous sûr ?',
      'settings_delete_desc': 'Cela supprimera votre compte ainsi que toutes les données qui y sont associées.',
      'settings_no_sync_desc': 'Les paramètres ne se synchronisent pas entre les appareils',
      'shorten_shorten_link': 'Raccourcir le lien',
      'shorten_shortening': 'Raccourcissement en cours',
      'shorten_copy_link': 'Copier le lien',
      'shorten_add_group': 'Ajouter au groupe',
      'error_oops': 'Oups, quelque chose s\'est mal passé. Réessayez !',
      'transform_upper_case': 'Majuscules',
      'transform_lower_case': 'Minuscules',
      'transform_word_case': 'Première lettre en majuscule',
      'transform_no_space': 'Première lettre en majuscule, sans espaces',
      'transform_reverse_char': 'Caractères inversés',
      'transform_reverse_words': 'Phrase inversée',
      'transform_reverse_words_case': 'Phrase inversée, première lettre en majuscule',
      'transform_space_claps': 'Remplacer l\'espace par des applaudissements',
      'transform_hashtag': 'Hashtag pour chaque mot',
      'transform_hashtag_case': 'Hashtag pour chaque mot, première lettre en majuscule',
      'transform_upside_down': 'Texte à l\'envers',
      'transform_trim': 'Supprimer les espaces',
      'transform_trim_white_break': 'Supprimer les espaces/retours à la ligne',
      'transform_url_encode': 'Encodage d\'URL',
      'transform_comma_dash': 'Virgules en liste avec tirets',
      'transform_comma_bullet': 'Virgules en liste à puces',
      'transform_remove_numbers': 'Supprimer les nombres',
      'transform_link_comps': 'Composants de lien',
      'transform_text_transform': 'Transformation de texte',
      'transform_scheme': 'Scheme',
      'transform_host': 'Host',
      'transform_path': 'Path',
      'transform_path_sub': 'Path Subcomponent',
      'transform_queries': 'Queries',
      'transform_query': 'Query',
      'transform_tagline': 'Copiez ces éléments dans votre presse-papiers avec Premium !',
      'tag_tag_name': 'Nom du tag',
      'tag_tag_line': 'Ajoutez plus d\'un tag avec Premium.',
      'text_text_saved': 'Texte enregistré !',
      'text_link_saved': 'Lien enregistré !',
      'text_count_words': '{{0}} mots',
      'text_count_character': '{{0}} caractères',
      'text_recognizer_text_recognizer': 'Reconnaissance de texte',
      'text_recognizer_choose_image': 'Choisir une image',
      'text_recognizer_intro': 'Choisissez une image pour utiliser la reconnaissance de texte !',
      'text_recognizer_processing': 'Traitement en cours',
      'text_recognizer_error': 'Belle image, mais une erreur a été détectée',
      'text_recognizer_no_text': 'Belle image, mais aucun texte n\'a été détecté',
      'push_allow_message': 'Autorisez les notifications push pour commencer à recevoir des alertes lorsque du contenu est ajouté à <bold>{{0}}</bold>',
      'push_allow_notifications': 'Autoriser les notifications',
      'push_push_group_enabled': 'Les notifications push pour <bold>{{0}}</bold> sont activées !',
      'push_snooze_notifications': 'Mettre en veille les notifications',
      'push_push_group_snoozed': 'Les notifications push pour <bold>{{0}}</bold> sont en veille !',
      'push_turn_on_notifications': 'Activer les notifications',
      'push_permission_denied_message': 'Les autorisations de notifications push ont été refusées sur cet appareil. Activez-les si vous souhaitez recevoir des notifications.',
      'push_app_settings': 'Paramètres de l\'application',
      'push_tagline': 'Activez les notifications pour plus d\'un groupe avec Premium !',
      'app_icon_original': 'Original',
      'app_icon_light': 'Clair',
      'app_icon_dark': 'Sombre',
      'app_icon_mono_light': 'Mono clair',
      'app_icon_mono_dark': 'Mono sombre',
      'crop_info': 'Choisissez ou capturez une image\npuis recadrez-la !',
      'crop_tagline': 'Vous avez aimé cet outil ? Utilisez-le à tout moment avec Premium !',
      'keyboard_custom_keyboard': 'Clavier personnalisé',
      'keyboard_custom_keyboard_sub': 'Accédez à votre contenu Dmyst à partir de n\'importe quelle application lorsque vous faites apparaître le clavier',
      'keyboard_get_started': 'Commencer',
      'keyboard_get_started_sub': 'Ouvrez les paramètres de l\'application, activez le clavier et autorisez l\'accès complet',
      'keyboard_open_settings': 'Ouvrir les paramètres de l\'application',
      'keyboard_full_access': 'Pourquoi un accès complet ?',
      'keyboard_full_access_sub': 'Dmyst récupère votre contenu à partir du cloud, donc une connexion réseau est requise',
      'keyboard_no_tracking': 'Aucun suivi',
      'keyboard_no_tracking_sub': 'Le clavier Dmyst NE TRACE PAS les actions que vous effectuez avec le clavier, telles que les frappes',
      'browser_url_placeholder': 'Tapez votre adresse web ici',
      'browser_copied_group': 'Copié dans le groupe !',
      'browser_tagline': 'Copiez des éléments dans votre groupe pendant la navigation !',
      'browser_cta': 'Si vous activez l\'icône 📋 en bas à droite, tout ce que vous copiez sera ajouté au groupe actuel.\n\nQuelle commodité !',
      'login_sub_text': 'Connectez-vous pour commencer à vous envoyer des messages directement des notes et des fichiers.',
      'login_terms': 'Conditions générales',
      'login_privacy': 'Politique de confidentialité',
      'group_tagline': 'Ajoutez plus de 2 groupes avec Premium.',
      'menu_panel_logout_warning': 'Êtes-vous sûr de vouloir vous déconnecter ?',
      'menu_panel_logout': 'Se déconnecter',
      'menu_panel_recent': 'Récent',
      'menu_panel_give_feedback': 'Donner un retour',
      'menu_panel_settings': 'Paramètres',
      'items_empty_group_gone': 'Tous les groupes ont disparu !',
      'items_empty_group_sub': 'Profitez de nyan cat en attendant',
      'tooltip_hold': 'Maintenez pour plus d\'options !',
      'items_empty_group': 'Ce groupe est actuellement vide',
      'delete_group_confirm': 'Êtes-vous sûr de vouloir supprimer ce groupe ?',
      'toast_clipboard_empty': 'Le presse-papiers est vide',
      'items_tagline': 'Ajoutez plus de notes avec Premium.',
      'storage_storage_space': 'Espace de stockage',
      'storage_storage_space_warning': 'Vous avez atteint la limite d\'espace de stockage. Supprimez certains fichiers pour libérer de l\'espace.',
      'storage_tagline': 'Obtenez plus d\'espace de stockage avec Premium.',
      'menu_select_action': 'Sélectionnez une action à effectuer',
      'clip_clip_saved': 'Clip enregistré !',
      'clip_clip_failed_message': 'L\'élément du presse-papiers n\'a pas pu être enregistré',
      'toast_deleted': 'Supprimé !',
      'toast_file_copied': 'Fichier copié !',
      'toast_file_copy_failed': 'Ce fichier n\'a pas pu être copié',
      'toast_image_copied': 'Image copiée !',
      'toast_image_copy_failed': 'Cette image n\'a pas pu être copiée',
      'toast_saved': 'Enregistré !',
      'shorten_link_tagline': 'Raccourcissez les liens dans l\'application avec Premium !',
      'text_recognizer_tagline': 'Utilisez le texte du reconnaître sur les images avec Premium !',
      'clip_clipboard_cleared': 'Presse-papiers effacé !',
      'drag_drop_cta': 'Déposez ici pour ajouter',
      'purchase_purchase_successful': 'Achat réussi',
      'purchase_purchase_failed': 'L\'achat a échoué',
      'purchase_thanks': 'Merci pour votre achat ! Vous utilisez maintenant Dmyst Premium.',
      'purchase_congrats': 'Félicitations',
      'purchase_premium_activated': 'Abonnement Premium activé',
      'purchase_sync_across': 'Synchronisation entre appareils avec plus de fonctionnalités',
      'feature_bigger_collections': 'Collections plus grandes',
      'feature_bigger_collections_sub': 'Organisez votre contenu avec plus de groupes, plus de contenu par groupe et plus d\'espace de stockage',
      'feature_all_tools': 'Accès à tous les outils',
      'feature_all_tools_sub': 'Ajoutez plusieurs tags, plus de groupes de notification, un reconnaître de texte, un raccourcisseur de liens, recadrez les images capturées, et plus encore',
      'feature_biometric': 'Verrouillage biométrique',
      'feature_biometric_sub': 'Gardez votre contenu derrière un verrou de sécurité pour empêcher les autres de fouiller dans l\'application',
      'feature_one_sub': 'Un abonnement',
      'feature_one_sub_sub': 'Achetez sur une plateforme et votre abonnement Premium fonctionnera sur tous vos appareils, y compris iOS, Android et le web',
      'general_continue': 'Continuer',
      'purchase_sub_notice': 'Les abonnements seront facturés sur votre carte de crédit via votre compte iTunes. Les abonnements se renouvelleront automatiquement sauf annulation 24 heures avant la fin de la période en cours. Vous pouvez annuler à tout moment via les paramètres de votre compte iTunes. Pour plus d\'informations, consultez les conditions générales et la politique de confidentialité ci-dessous.',
      'purchase_learn_more': 'En savoir plus',
      'purchase_restore': 'Restaurer l\'achat précédent',
      'purchase_month': 'Mois',
      'purchase_months': 'Mois',
      'scanner_quick_send': 'Envoi rapide',
      'scanner_qr_code': 'Lecteur de code QR',
      'scanner_quick_send_title': 'Rendez-vous sur {0} pour envoyer cet élément vers votre navigateur web',
      'scanner_qr_code_title': 'Scannez un code QR et visualisez l\'aperçu ici',
      'scanner_invalid_qr': 'Code QR invalide',
      'scanner_invalid_qr_message': 'Le code scanné ne semble pas correct. Assurez-vous de scanner le code QR sur le site web dmystapp.com/quick.',
      'error_something_wrong': 'Quelque chose s\'est mal passé',
      'error_something_wrong_message': 'Une erreur s\'est produite lors de l\'envoi du contenu. Veuillez réessayer.',
      'toolbox_toolbox': 'Boîte à outils',
      'biometric_authenticate_test': 'Authentifiez-vous pour l\'essayer !',
      'biometric_authenticate_access': 'Authentifiez-vous pour accéder à Dmyst',
      'recent_tagline': 'Voir tous les éléments récents avec Premium !',
      'recent_recently_text': 'Éléments de texte récemment ajoutés',
      'recent_recently_link': 'Liens récemment ajoutés',
      'recent_recently_images': 'Images récemment ajoutées',
      'recent_recently_files': 'Fichiers récemment ajoutés',
      'share_share_to_dmyst': 'Partager sur Dmyst',
      'share_select_group_message': 'Sélectionnez un groupe de votre compte Dmyst pour ajouter votre contenu.',
      'share_no_login': 'Bienvenue sur Dmyst !\n\nPour utiliser la fonction de partage, vous devez lancer l\'application pour vous connecter ou créer un compte d\'abord.',
      'share_read_error': 'Quelque chose s\'est mal passé !\n\nLes données partagées contenaient des données inconnues ou Dmyst n\'a pas pu accéder aux données.',
      'share_non_premium_max_items': 'Vous avez atteint votre limite d\'éléments !\n\nVous avez atteint votre limite d\'éléments pour ce groupe. Mettez à niveau vers Dmyst Premium pour en ajouter davantage !',
      'share_non_premium_max_storage': 'Vous avez atteint votre limite de stockage !\n\nVous avez atteint votre limite de stockage. Mettez à niveau vers Dmyst Premium pour en ajouter davantage !',
      'share_premium_max_storage': 'Vous avez atteint votre limite de stockage !\n\nVous avez atteint votre limite de stockage. Supprimez certains fichiers de vos groupes avant de continuer.',
      'share_upload_error': 'Quelque chose s\'est mal passé !\n\nUne erreur s\'est produite lors de la tentative de téléchargement du fichier.',
      'share_sharing': 'Partage en cours',
      'share_sharing_progress': 'Partage de {0} sur {1}',
      'keyboard_allow_full_access': 'Veuillez autoriser l\'accès complet pour voir votre contenu Dmyst',
      'keyboard_login': 'Veuillez vous connecter à Dmyst pour voir votre contenu',
      'keyboard_allow_full_access2': 'Autoriser l\'accès complet pour copier dans le presse-papiers',
      'menu_export_items': 'Exporter les éléments',
      'export_export': 'Exporter',
      'export_export_text_link': 'Exporter {{0}} textes et liens',
      'export_export_images_files': 'Exporter {{0}} images et fichiers',
      'menu_popup_pin': 'Épingler en haut',
      'menu_popup_unpin': 'Détacher',
      'menu_popup_copy_multiple': 'Copier plusieurs',
      'toast_nothing_export': 'Il n\'y a rien à exporter',
      'limit_items_per_group': 'Les groupes sont limités à {{0}} par groupe',
      'limit_suggest_premium': 'Ajoutez jusqu\'à {{0}} en passant à Premium',
      'limit_export_delete_suggest': 'Exportez vos données si vous souhaitez les conserver et si vous avez besoin de plus d\'espace, supprimez certains éléments',
      'export_clean_up': 'Nettoyer',
      'export_bulk_delete': 'Suppression en masse',
      'settings_screen_security': 'Sécurité de l\'écran',
      'settings_security': 'Sécurité',
      'settings_screen_security_desc': 'Masquer les contenus de Dmyst lors du basculement entre les applications',
      'share_premium_max_items': 'Vous avez atteint votre limite d\'éléments pour ce groupe. Utilisez un autre groupe ou supprimez d\'abord des éléments de ce groupe.',
      'bulk_delete_delete_items': 'Supprimer les éléments',
      'bulk_delete_message': 'Êtes-vous sûr de vouloir supprimer les éléments sélectionnés ?'

    },
  },
  es: {
    translation: {
      'file_upload_failed': 'No se subieron todos los archivos correctamente',
      'five_file_limit': 'Solo se pueden subir 5 archivos a la vez',
      'general_save': 'Guardar',
      'text_unsaved': 'Texto no guardado',
      'text_unsaved_msg': '¿Desea guardar antes de cerrar?',
      'text_start_typing': 'Comience a escribir aquí',
      'quick_send_download': 'Descargar desde file.io',
      'quick_send_reset': 'Reiniciar',
      'quick_send_footer': 'Los archivos se almacenan temporalmente en <1>file.io</1>. Son seguros y respetan la privacidad.',
      'quick_send_web_title': 'Envía un elemento desde tu aplicación móvil Dmyst',
      'quick_send_step_1': '1. Abre la aplicación <1>Dmyst</1> en tu dispositivo móvil',
      'quick_send_step_2': '2. Mantén presionado el elemento que deseas enviar',
      'quick_send_step_3': '3. Selecciona <1>Escanear código de envío rápido</1>',
      'quick_send_step_4': '4. Escanea el código QR que se muestra arriba',
      'toolbox_web_crop': 'Seleccionar y recortar imagen',
      'notification_why_seeing': '¿Por qué estoy viendo esto?',
      'notification_why_seeing_desc': 'Las notificaciones del navegador web tienen funcionalidad limitada y permitir la copia con un solo clic requiere la acción del usuario desde la ventana del navegador.',
      'general_download': 'Descargar',
      'general_copied': '¡Copiado!',
      'button_copy_to_clipboard': 'Copiar al portapapeles',
      'settings_one_click_sub': 'Con un solo clic, el texto se copiará al portapapeles y los archivos se descargarán',
      'settings_one_click': 'Copia/Descarga con un clic',
      'premium_web_description': 'Puedes obtener premium descargando la aplicación en tu dispositivo iOS o Android y suscribiéndote en la aplicación.',
      'logout_deleting_account': 'Eliminando cuenta',
      'logout_logging_out': 'Cerrando sesión',
      'general_loading': 'Cargando',
      'login_apps_mobile_message': 'Descarga las aplicaciones para comenzar',
      'login_apps_desktop_message': 'No olvides descargar las aplicaciones',
      'login_apple': 'Iniciar sesión con Apple',
      'push_notifications_not_supported': 'Las notificaciones push no son compatibles con este navegador',
      'share_to_twitter': 'Compartir en Twitter',
      'share_to_whatsapp': 'Compartir en Whatsapp',
      'feedback_sent': '¡Comentario enviado!',
      'general_send': 'Enviar',
      'add_group_special_chars': 'La mayoría de los caracteres especiales no están permitidos, excepto los guiones (-) y los guiones bajos (_)',
      'items_add_placeholder': 'Añadir algo a este grupo',
      'menu_delete_group': 'Eliminar grupo',
      'menu_change_group_name': 'Cambiar nombre del grupo',
      'menu_panel_good_morning': 'Buenos días',
      'menu_panel_good_afternoon': 'Buenas tardes',
      'menu_panel_good_evening': 'Buenas noches',
      'toolbox_clear_clipboard': 'Borrar portapapeles',
      'toolbox_scan_document': 'Escanear documento',
      'toolbox_capture_crop_image': 'Capturar y recortar imagen',
      'toolbox_qr_scanner': 'Escáner de código QR',
      'toolbox_browse_copy': 'Navegar y copiar',
      'toolbox_text_recognizer': 'Reconocimiento de texto',
      'toolbox_sub_clear_clipboard': 'Borrar el contenido copiado en tu portapapeles',
      'toolbox_sub_scan_document': 'Usa tu cámara para escanear un documento en una imagen',
      'toolbox_sub_capture_crop_image': 'Toma una foto y recórtala como prefieras',
      'toolbox_sub_qr_scanner': 'Escanea un código QR para leer su contenido',
      'toolbox_sub_browse_copy': 'Navega por la web y copia el contenido a un grupo',
      'toolbox_sub_text_recognizer': 'Toma una foto y extrae el texto',
      'menu_panel_groups': 'Grupos',
      'menu_panel_more': 'Más',
      'menu_panel_storage': 'Almacenamiento',
      'settings_general': 'General',
      'settings_app_icon': 'Icono de la aplicación',
      'settings_social': 'Social',
      'settings_premium': 'Premium',
      'recent_premium_placeholder': 'Ver más con\nPremium',
      'recent_empty_placeholder': 'No hay nada que ver aquí\nde momento',
      'media_uploading': 'Subiendo',
      'login_google': 'Iniciar sesión con Google',
      'progress_sending': 'Enviando',
      'menu_popup_view_file': 'Ver archivo',
      'menu_popup_view_image': 'Ver imagen',
      'menu_popup_edit': 'Editar',
      'menu_popup_copy': 'Copiar',
      'menu_popup_tag': 'Etiqueta',
      'menu_popup_share': 'Compartir',
      'menu_popup_scan_quick_send_code': 'Escanear código de envío rápido',
      'menu_popup_copy_image': 'Copiar imagen',
      'menu_popup_save_camera_roll': 'Guardar en Carrete',
      'menu_popup_copy_file': 'Copiar archivo',
      'menu_popup_copy_file_name': 'Copiar nombre de archivo',
      'menu_popup_save_file': 'Guardar archivo',
      'menu_popup_open_link': 'Abrir enlace',
      'menu_popup_delete': 'Eliminar',
      'menu_popup_text_transform': 'Transformación de texto',
      'menu_popup_link_comps': 'Componentes de enlace',
      'menu_popup_shorten_link': 'Acortar enlace',
      'menu_popup_call_number': 'Llamar al número',
      'toast_image_added': 'Imagen Agregada',
      'toast_file_added': 'Archivo Agregado',
      'error_connection_offline': 'Tu conexión parece estar fuera de línea',
      'error_file_upload_fail': 'Error al cargar el archivo',
      'photo_upload_photo': 'Subir Foto',
      'general_cancel': 'Cancelar',
      'photo_camera': 'Cámara',
      'photo_gallery': 'Galería',
      'toast_copied_clipboard': 'Copiado al Portapapeles',
      'toast_undo': 'Deshacer',
      'clip_clip': 'Recorte',
      'clip_empty_placeholder': '¡Nada en tu portapapeles!',
      'clip_preview_file': 'Vista Previa de Archivo',
      'clip_error_placeholder': 'No se pudieron leer los datos del portapapeles.\n\nAvísanos en los comentarios qué copiaste para que pueda ser compatible en el futuro.',
      'feedback_feedback': 'Comentarios',
      'feedback_email': 'Correo Electrónico',
      'feedback_optional': 'Opcional',
      'feedback_placeholder': 'Escribe tus comentarios, sugerencias y/o observaciones aquí',
      'group_add_group': 'Agregar Nuevo Grupo',
      'group_edit_group': 'Editar Grupo',
      'group_enter_name': 'Ingresar un Nombre de Grupo',
      'group_invalid_placeholder': 'El nombre contiene caracteres no válidos',
      'group_name_changed': '¡Nombre del grupo cambiado!',
      'settings_settings': 'Configuraciones',
      'settings_enable_link_previews': 'Habilitar Previsualizaciones de Enlaces',
      'settings_enable_bio_lock': 'Habilitar Bloqueo Biométrico',
      'settings_one_tap': 'Copia con un Toque',
      'settings_one_tap_desc': 'Al tocar un elemento se copiará en tu portapapeles',
      'settings_keyboard': 'Teclado',
      'premium_go_premium': 'Obtener Premium',
      'premium_membership_active': 'Tu membresía está activa',
      'premium_tagline': '¡Agrega más almacenamiento, grupos y notas con Premium! Comienza aquí.',
      'settings_rate_dmyst': 'Calificar Dmyst',
      'settings_follow_twitter': 'Síguenos en @dmyst_app | Twitter',
      'settings_facebook_page': 'Página de Facebook',
      'settings_delete_account': 'Eliminar Cuenta',
      'settings_version': 'Versión',
      'premium_bio_tagline': 'Usa la biometría para desbloquear Dmyst al iniciar la aplicación',
      'settings_are_you_sure': '¿Estás seguro?',
      'settings_delete_desc': 'Esto eliminará tu cuenta y todos los datos asociados.',
      'settings_no_sync_desc': 'Los ajustes no se sincronizan entre dispositivos',
      'shorten_shorten_link': 'Acortar Enlace',
      'shorten_shortening': 'Acortando',
      'shorten_copy_link': 'Copiar Enlace',
      'shorten_add_group': 'Añadir a Grupo',
      'error_oops': '¡Ups, algo salió mal. ¡Inténtalo de nuevo!',
      'transform_upper_case': 'Mayúsculas',
      'transform_lower_case': 'Minúsculas',
      'transform_word_case': 'Mayúscula Inicial',
      'transform_no_space': 'Mayúscula Inicial, Sin Espacios',
      'transform_reverse_char': 'Caracteres Invertidos',
      'transform_reverse_words': 'Frase Invertida',
      'transform_reverse_words_case': 'Frase Invertida, Mayúscula Inicial',
      'transform_space_claps': 'Reemplazar Espacios con Aplausos',
      'transform_hashtag': 'Hashtag para Cada Palabra',
      'transform_hashtag_case': 'Hashtag para Cada Palabra, Mayúscula Inicial',
      'transform_upside_down': 'Texto al Revés',
      'transform_trim': 'Eliminar Espacios en Blanco',
      'transform_trim_white_break': 'Eliminar Espacios en Blanco/Saltos de Línea',
      'transform_url_encode': 'Codificación de URL',
      'transform_comma_dash': 'Comas a Lista con Guión',
      'transform_comma_bullet': 'Comas a Lista de Viñetas',
      'transform_remove_numbers': 'Eliminar Números',
      'transform_link_comps': 'Componentes de Enlace',
      'transform_text_transform': 'Transformación de Texto',
      'transform_scheme': 'Esquema',
      'transform_host': 'Anfitrión',
      'transform_path': 'Ruta',
      'transform_path_sub': 'Subcomponente de Ruta',
      'transform_queries': 'Consultas',
      'transform_query': 'Consulta',
      'transform_tagline': '¡Copia estos elementos en tu portapapeles con Premium!',
      'tag_tag_name': 'Nombre de Etiqueta',
      'tag_tag_line': 'Agrega más de una etiqueta con Premium.',
      'text_text_saved': '¡Texto Guardado!',
      'text_link_saved': '¡Enlace Guardado!',
      'text_count_words': '{{0}} palabras',
      'text_count_character': '{{0}} caracteres',
      'text_recognizer_text_recognizer': 'Reconocimiento de Texto',
      'text_recognizer_choose_image': 'Elegir Imagen',
      'text_recognizer_intro': '¡Elige una imagen para usar el reconocimiento de texto!',
      'text_recognizer_processing': 'Procesando',
      'text_recognizer_error': 'Bonita imagen, pero se detectó un error',
      'text_recognizer_no_text': 'Bonita imagen, pero no se detectó ningún texto',
      'push_allow_message': 'Permitir notificaciones push para empezar a recibir alertas cuando se añade contenido a <bold>{{0}}</bold>',
      'push_allow_notifications': 'Permitir Notificaciones',
      'push_push_group_enabled': '¡Las notificaciones push para <bold>{{0}}</bold> están habilitadas!',
      'push_snooze_notifications': 'Pausar Notificaciones',
      'push_push_group_snoozed': '¡Las notificaciones push para <bold>{{0}}</bold> están en pausa!',
      'push_turn_on_notifications': 'Activar Notificaciones',
      'push_permission_denied_message': 'Los permisos de notificaciones push han sido denegados en este dispositivo. Actívalos si deseas recibir notificaciones.',
      'push_app_settings': 'Configuración de la Aplicación',
      'push_tagline': '¡Activa las notificaciones para más de 1 grupo con premium!',
      'app_icon_original': 'Original',
      'app_icon_light': 'Claro',
      'app_icon_dark': 'Oscuro',
      'app_icon_mono_light': 'Mono Claro',
      'app_icon_mono_dark': 'Mono Oscuro',
      'crop_info': '¡Elige o captura una imagen\n¡luego recórtala!',
      'crop_tagline': '¿Te ha gustado esta herramienta? ¡Úsala en cualquier momento con premium!',
      'keyboard_custom_keyboard': 'Teclado Personalizado',
      'keyboard_custom_keyboard_sub': 'Obtén acceso a tu contenido de Dmyst desde cualquier aplicación cuando muestres el teclado',
      'keyboard_get_started': 'Empezar',
      'keyboard_get_started_sub': 'Abre la configuración de la aplicación, habilita el teclado y permite el acceso completo',
      'keyboard_open_settings': 'Abrir Configuración de la Aplicación',
      'keyboard_full_access': '¿Por qué Acceso Completo?',
      'keyboard_full_access_sub': 'Dmyst recupera tu contenido de la nube, por lo que se requiere una conexión de red',
      'keyboard_no_tracking': 'Sin Rastreo',
      'keyboard_no_tracking_sub': 'El teclado de Dmyst NO RASTREA ninguna acción que realices con el teclado, como las pulsaciones de teclas',
      'browser_url_placeholder': 'Escribe aquí tu dirección web',
      'browser_copied_group': '¡Copiado al Grupo!',
      'browser_tagline': '¡Copia elementos en tu grupo mientras navegas!',
      'browser_cta': 'Si habilitas el icono 📋 en la parte inferior derecha, todo lo que copies se agregará al grupo actual.\n\n¡Qué conveniente!',
      'login_sub_text': 'Inicia sesión para empezar a enviarte mensajes directamente desde notas y archivos.',
      'login_terms': 'Términos y Condiciones',
      'login_privacy': 'Política de Privacidad',
      'group_tagline': 'Añade más de 2 grupos con Premium.',
      'menu_panel_logout_warning': '¿Estás seguro de que quieres cerrar sesión?',
      'menu_panel_logout': 'Cerrar Sesión',
      'menu_panel_recent': 'Reciente',
      'menu_panel_give_feedback': 'Dar Comentarios',
      'menu_panel_settings': 'Configuración',
      'items_empty_group_gone': '¡Todos los grupos han desaparecido!',
      'items_empty_group_sub': 'Disfruta de nyan cat mientras tanto',
      'tooltip_hold': '¡Mantén presionado para más opciones!',
      'items_empty_group': 'Este grupo está vacío por ahora',
      'delete_group_confirm': '¿Estás seguro de que quieres borrar este grupo?',
      'toast_clipboard_empty': 'El portapapeles está vacío',
      'items_tagline': 'Añade más notas con Premium.',
      'storage_storage_space': 'Espacio de Almacenamiento',
      'storage_storage_space_warning': 'Has alcanzado el límite de espacio de almacenamiento. Elimina algunos archivos para liberar espacio.',
      'storage_tagline': 'Obtén más espacio de almacenamiento con Premium.',
      'menu_select_action': 'Selecciona una acción para realizar',
      'clip_clip_saved': '¡Clip Guardado!',
      'clip_clip_failed_message': 'No se pudo guardar el elemento del portapapeles',
      'toast_deleted': '¡Eliminado!',
      'toast_file_copied': '¡Archivo Copiado!',
      'toast_file_copy_failed': 'No se pudo copiar este archivo',
      'toast_image_copied': '¡Imagen Copiada!',
      'toast_image_copy_failed': 'No se pudo copiar esta imagen',
      'toast_saved': '¡Guardado!',
      'shorten_link_tagline': '¡Acorta enlaces en la aplicación con premium!',
      'text_recognizer_tagline': '¡Usa el reconocedor de texto en imágenes con premium!',
      'clip_clipboard_cleared': '¡Portapapeles borrado!',
      'drag_drop_cta': 'Suelta aquí para añadir',
      'purchase_purchase_successful': 'Compra Exitosa',
      'purchase_purchase_failed': 'Compra Fallida',
      'purchase_thanks': '¡Gracias por la compra! Ahora estás usando Dmyst Premium.',
      'purchase_congrats': 'Felicidades',
      'purchase_premium_activated': 'Suscripción Premium Activada',
      'purchase_sync_across': 'Sincronización entre dispositivos con más funciones',
      'feature_bigger_collections': 'Colecciones más grandes',
      'feature_bigger_collections_sub': 'Organiza tu contenido con más grupos, más contenido por grupo y más espacio de almacenamiento',
      'feature_all_tools': 'Acceso a Todas las Herramientas',
      'feature_all_tools_sub': 'Agrega múltiples etiquetas, más grupos de notificaciones, reconocimiento de texto, acortador de enlaces, recorta imágenes capturadas y más',
      'feature_biometric': 'Bloqueo Biométrico',
      'feature_biometric_sub': 'Mantén tu contenido protegido con un bloqueo de seguridad para evitar que otros curioseen en la aplicación',
      'feature_one_sub': 'Una Suscripción',
      'feature_one_sub_sub': 'Compra en una plataforma y tu membresía premium funcionará en todos tus dispositivos, incluyendo iOS, Android y la web',
      'general_continue': 'Continuar',
      'purchase_sub_notice': 'Las suscripciones se cobrarán en tu tarjeta de crédito a través de tu cuenta de iTunes. Las suscripciones se renovarán automáticamente a menos que se cancelen dentro de las 24 horas previas al final del período actual. Puedes cancelar en cualquier momento desde la configuración de tu cuenta de iTunes. Para obtener más información, consulta los términos y la política de privacidad a continuación.',
      'purchase_learn_more': 'Saber más',
      'purchase_restore': 'Restaurar compra anterior',
      'purchase_month': 'Mes',
      'purchase_months': 'Meses',
      'viewer_no_preview': 'Vista previa no disponible',
      'viewer_no_preview_message': 'El archivo no se reconoce para mostrar una vista previa',
      'scanner_quick_send': 'Envío Rápido',
      'scanner_qr_code': 'Escáner de Código QR',
      'scanner_quick_send_title': 'Ve a {0} para enviar este elemento a tu navegador web',
      'scanner_qr_code_title': 'Escanear un código QR y ver la vista previa aquí',
      'scanner_invalid_qr': 'Código QR no válido',
      'scanner_invalid_qr_message': 'El código escaneado no parece correcto. Asegúrate de escanear el código QR en el sitio web dmystapp.com/quick.',
      'error_something_wrong': 'Algo salió mal',
      'error_something_wrong_message': 'Hubo un error al enviar el contenido. Por favor, inténtalo de nuevo.',
      'toolbox_toolbox': 'Herramientas',
      'biometric_authenticate_test': '¡Autentícate para probarlo!',
      'biometric_authenticate_access': 'Autentícate para acceder a Dmyst',
      'recent_tagline': '¡Ver todos los elementos recientes con premium!',
      'recent_recently_text': 'Elementos de texto agregados recientemente',
      'recent_recently_link': 'Enlaces agregados recientemente',
      'recent_recently_images': 'Imágenes agregadas recientemente',
      'recent_recently_files': 'Archivos agregados recientemente',
      'share_share_to_dmyst': 'Compartir en Dmyst',
      'share_select_group_message': 'Selecciona un grupo de tu cuenta de Dmyst para añadir tu contenido.',
      'share_no_login': '¡Bienvenido a Dmyst!\n\nPara usar la función de compartir, deberás iniciar la aplicación para iniciar sesión o crear una cuenta primero.',
      'share_read_error': '¡Algo salió mal!\n\nLos datos compartidos contenían datos desconocidos o Dmyst no pudo acceder a los datos.',
      'share_non_premium_max_items': '¡Has alcanzado tu límite de elementos!\n\nHas alcanzado tu límite de elementos para este grupo. ¡Actualiza a Dmyst Premium para agregar más!',
      'share_non_premium_max_storage': '¡Has alcanzado tu límite de almacenamiento!\n\nHas alcanzado tu límite de almacenamiento. ¡Actualiza a Dmyst Premium para agregar más!',
      'share_premium_max_storage': '¡Has alcanzado tu límite de almacenamiento!\n\nHas alcanzado tu límite de almacenamiento. Elimina algunos archivos de tus grupos antes de continuar.',
      'share_upload_error': '¡Algo salió mal!\n\nHubo un error al intentar cargar el archivo.',
      'share_sharing': 'Compartiendo',
      'share_sharing_progress': 'Compartiendo {0} de {1}',
      'keyboard_allow_full_access': 'Por favor, permite el acceso completo para ver tu contenido de Dmyst',
      'keyboard_login': 'Por favor, inicia sesión en Dmyst para ver tu contenido',
      'keyboard_allow_full_access2': 'Permitir acceso completo para copiar al portapapeles',
      'menu_export_items': 'Exportar elementos',
      'export_export': 'Exportar',
      'export_export_text_link': 'Exportar {{0}} textos y enlaces',
      'export_export_images_files': 'xportar {{0}} imágenes y archivos',
      'menu_popup_pin': 'Fijar en la parte superior',
      'menu_popup_unpin': 'Desfijar',
      'menu_popup_copy_multiple': 'Copiar varios',
      'toast_nothing_export': 'No hay nada para exportar',
      'limit_items_per_group': 'Los grupos están limitados a {{0}} por grupo',
      'limit_suggest_premium': 'Agrega hasta {{0}} al actualizar a Premium',
      'limit_export_delete_suggest': 'Exporta tus datos si deseas conservarlos y, si necesitas más espacio, elimina algunos elementos',
      'export_clean_up': 'Limpiar',
      'export_bulk_delete': 'Eliminación masiva',
      'settings_screen_security': 'Seguridad de pantalla',
      'settings_security': 'Seguridad',
      'settings_screen_security_desc': 'Ocultar contenidos de Dmyst al cambiar entre aplicaciones',
      'share_premium_max_items': 'Has alcanzado tu límite de elementos para este grupo. Utiliza otro grupo o elimina primero elementos en este grupo.',
      'bulk_delete_delete_items': 'Eliminar elementos',
      'bulk_delete_message': '¿Seguro que quieres eliminar los elementos seleccionados?'  
    }
  },
  de: {
    translation: {
      'file_upload_failed': 'Alle Dateien wurden nicht erfolgreich hochgeladen',
      'five_file_limit': 'Nur 5 Dateien können auf einmal hochgeladen werden',
      'general_save': 'Speichern',
      'text_unsaved': 'Ungesicherter Text',
      'text_unsaved_msg': 'Möchten Sie vor dem Schließen speichern?',
      'text_start_typing': 'Hier tippen',
      'quick_send_download': 'Von file.io herunterladen',
      'quick_send_reset': 'Zurücksetzen',
      'quick_send_footer': 'Dateien werden vorübergehend auf <1>file.io</1> gespeichert. Sie sind sicher und schätzen die Privatsphäre.',
      'quick_send_web_title': 'Senden Sie einen Artikel von Ihrer Dmyst Mobile App',
      'quick_send_step_1': '1. Starten Sie die <1>Dmyst</1>-App auf Ihrem Mobilgerät',
      'quick_send_step_2': '2. Drücken Sie das Element, das Sie senden möchten, lange',
      'quick_send_step_3': '3. Wählen Sie <1>Scan Quick Send Code</1>',
      'quick_send_step_4': '4. Scannen Sie den oben angezeigten QR-Code',
      'toolbox_web_crop': 'Bild auswählen und zuschneiden',
      'notification_why_seeing': 'Warum sehe ich das?',
      'notification_why_seeing_desc': 'Webbrowser-Benachrichtigungen sind in ihrer Funktionalität eingeschränkt, und das Zulassen von Ein-Klick-Kopien erfordert die Aktion des Benutzers aus dem Browserfenster.',
      'general_download': 'Herunterladen',
      'general_copied': 'Kopiert!',
      'button_copy_to_clipboard': 'In Zwischenablage kopieren',
      'settings_one_click_sub': 'Bei einem einzigen Klick wird der Text in die Zwischenablage kopiert und Dateien werden heruntergeladen',
      'settings_one_click': 'Ein-Klick-Kopie/Download',
      'premium_web_description': 'Sie können Premium erhalten, indem Sie die App auf Ihrem iOS- oder Android-Gerät herunterladen und sich in der App anmelden.',
      'logout_deleting_account': 'Konto wird gelöscht',
      'logout_logging_out': 'Abmelden',
      'general_loading': 'Wird geladen',
      'login_apps_mobile_message': 'Laden Sie die Apps herunter, um loszulegen',
      'login_apps_desktop_message': 'Vergessen Sie nicht, die Apps herunterzuladen',
      'login_apple': 'Mit Apple anmelden',
      'push_notifications_not_supported': 'Push-Benachrichtigungen werden für diesen Browser nicht unterstützt',
      'share_to_twitter': 'Auf Twitter teilen',
      'share_to_whatsapp': 'Auf Whatsapp teilen',
      'feedback_sent': 'Feedback gesendet!',
      'general_send': 'Senden',
      'add_group_special_chars': 'Die meisten Sonderzeichen sind nicht erlaubt, außer Bindestrichen (-) und Unterstrichen (_)', 
      'items_add_placeholder': 'Etwas zu dieser Gruppe hinzufügen',
      'menu_delete_group': 'Gruppe löschen',
      'menu_change_group_name': 'Gruppennamen ändern',
      'menu_panel_good_morning': 'Guten Morgen',
      'menu_panel_good_afternoon': 'Guten Tag',
      'menu_panel_good_evening': 'Guten Abend',
      'toolbox_clear_clipboard': 'Zwischenablage leeren',
      'toolbox_scan_document': 'Dokument scannen',
      'toolbox_capture_crop_image': 'Aufnehmen und zuschneiden',
      'toolbox_qr_scanner': 'QR-Code-Scanner',
      'toolbox_browse_copy': 'Durchsuchen und kopieren',
      'toolbox_text_recognizer': 'Texterkennung',
      'toolbox_sub_clear_clipboard': 'Löschen Sie den kopierten Inhalt in Ihrer Zwischenablage',
      'toolbox_sub_scan_document': 'Verwenden Sie Ihre Kamera, um ein Dokument in ein Bild zu scannen',
      'toolbox_sub_capture_crop_image': 'Nehmen Sie ein Foto auf und schneiden Sie es nach Ihren Wünschen zu',
      'toolbox_sub_qr_scanner': 'Scannen Sie einen QR-Code, um dessen Inhalt zu lesen',
      'toolbox_sub_browse_copy': 'Durchsuchen Sie das Web und kopieren Sie Inhalte in eine Gruppe',
      'toolbox_sub_text_recognizer': 'Machen Sie ein Foto und extrahieren Sie den Text',
      'menu_panel_groups': 'Gruppen',
      'menu_panel_more': 'Mehr',
      'menu_panel_storage': 'Speicher',
      'settings_general': 'Allgemein',
      'settings_app_icon': 'App-Symbol',
      'settings_social': 'Sozial',
      'settings_premium': 'Premium',
      'recent_premium_placeholder': 'Mehr sehen mit\nPremium',
      'recent_empty_placeholder': 'Hier gibt es gerade\nnichts zu sehen',
      'media_uploading': 'Hochladen',
      'login_google': 'Mit Google anmelden',
      'progress_sending': 'Senden',
      'menu_popup_view_file': 'Datei anzeigen',
      'menu_popup_view_image': 'Bild anzeigen',
      'menu_popup_edit': 'Bearbeiten',
      'menu_popup_copy': 'Kopieren',
      'menu_popup_tag': 'Kennzeichnen',
      'menu_popup_share': 'Teilen',
      'menu_popup_scan_quick_send_code': 'Schnellsende-Code scannen',
      'menu_popup_copy_image': 'Bild kopieren',
      'menu_popup_save_camera_roll': 'In Kamerarolle speichern',
      'menu_popup_copy_file': 'Datei kopieren',
      'menu_popup_copy_file_name': 'Dateinamen kopieren',
      'menu_popup_save_file': 'Datei speichern',
      'menu_popup_open_link': 'Link öffnen',
      'menu_popup_delete': 'Löschen',
      'menu_popup_text_transform': 'Text umwandeln',
      'menu_popup_link_comps': 'Linkkomponenten',
      'menu_popup_shorten_link': 'Link kürzen',
      'menu_popup_call_number': 'Nummer anrufen',
      'toast_image_added': 'Bild hinzugefügt',
      'toast_file_added': 'Datei hinzugefügt',
      'error_connection_offline': 'Ihre Verbindung scheint offline zu sein',
      'error_file_upload_fail': 'Der Datei-Upload ist fehlgeschlagen',
      'photo_upload_photo': 'Foto hochladen',
      'general_cancel': 'Abbrechen',
      'photo_camera': 'Kamera',
      'photo_gallery': 'Galerie',
      'toast_copied_clipboard': 'In die Zwischenablage kopiert',
      'toast_undo': 'Rückgängig machen',
      'clip_clip': 'Clip',
      'clip_empty_placeholder': 'Nichts in Ihrer Zwischenablage!',
      'clip_preview_file': 'Datei Vorschau',
      'clip_error_placeholder': 'Die Daten aus der Zwischenablage konnten nicht gelesen werden.\n\nLassen Sie uns wissen, was Sie kopiert haben, damit wir es in Zukunft unterstützen können.',
      'feedback_feedback': 'Feedback',
      'feedback_email': 'E-Mail',
      'feedback_optional': 'Optional',
      'feedback_placeholder': 'Schreiben Sie hier Ihr Feedback, Ihre Kommentare und/oder Vorschläge',
      'group_add_group': 'Neue Gruppe hinzufügen',
      'group_edit_group': 'Gruppe bearbeiten',
      'group_enter_name': 'Geben Sie einen Gruppennamen ein',
      'group_invalid_placeholder': 'Der Name enthält ungültige Zeichen',
      'group_name_changed': 'Gruppenname geändert!',
      'settings_settings': 'Einstellungen',
      'settings_enable_link_previews': 'Linkvorschauen aktivieren',
      'settings_enable_bio_lock': 'Biometrische Sperre aktivieren',
      'settings_one_tap': 'Ein-Tippen-Kopie',
      'settings_one_tap_desc': 'Durch Tippen auf einen Eintrag wird er in die Zwischenablage kopiert',
      'settings_keyboard': 'Tastatur',
      'premium_go_premium': 'Premium holen',
      'premium_membership_active': 'Ihre Mitgliedschaft ist aktiv',
      'premium_tagline': 'Fügen Sie mit Premium mehr Speicherplatz, Gruppen und Notizen hinzu! Hier geht\'s los.',
      'settings_rate_dmyst': 'Dmyst bewerten',
      'settings_follow_twitter': 'Folgen Sie @dmyst_app | Twitter',
      'settings_facebook_page': 'Facebook-Seite',
      'settings_delete_account': 'Konto löschen',
      'settings_version': 'Version',
      'premium_bio_tagline': 'Verwenden Sie Biometrie, um Dmyst beim App-Start zu entsperren',
      'settings_are_you_sure': 'Sind Sie sicher?',
      'settings_delete_desc': 'Dadurch wird Ihr Konto und alle damit verbundenen Daten gelöscht.',
      'settings_no_sync_desc': 'Einstellungen werden nicht zwischen Geräten synchronisiert',
      'shorten_shorten_link': 'Link kürzen',
      'shorten_shortening': 'Wird gekürzt',
      'shorten_copy_link': 'Link kopieren',
      'shorten_add_group': 'Zu Gruppe hinzufügen',
      'error_oops': 'Hoppla, etwas ist schiefgelaufen. Versuchen Sie es erneut!',
      'transform_upper_case': 'Großbuchstaben',
      'transform_lower_case': 'Kleinbuchstaben',
      'transform_word_case': 'Wortfall',
      'transform_no_space': 'Wortfall ohne Leerzeichen',
      'transform_reverse_char': 'Zeichen umkehren',
      'transform_reverse_words': 'Satz umkehren',
      'transform_reverse_words_case': 'Satz umkehren, Wortfall',
      'transform_space_claps': 'Leerzeichen durch Klatschen ersetzen',
      'transform_hashtag': 'Hashtag für jedes Wort',
      'transform_hashtag_case': 'Hashtag für jedes Wort, Wortfall',
      'transform_upside_down': 'Text auf den Kopf stellen',
      'transform_trim': 'Leerzeichen an den Enden entfernen',
      'transform_trim_white_break': 'Leerzeichen/Zeilenende entfernen',
      'transform_url_encode': 'URL-kodiert',
      'transform_comma_dash': 'Kommata in Strichliste umwandeln',
      'transform_comma_bullet': 'Kommata in Aufzählungsliste umwandeln',
      'transform_remove_numbers': 'Zahlen entfernen',
      'transform_link_comps': 'Link-Komponenten',
      'transform_text_transform': 'Textumwandlung',
      'transform_scheme': 'Schema',
      'transform_host': 'Host',
      'transform_path': 'Pfad',
      'transform_path_sub': 'Unterkomponente des Pfads',
      'transform_queries': 'Anfragen',
      'transform_query': 'Anfrage',
      'transform_tagline': 'Kopieren Sie diese Elemente mit Premium in Ihre Zwischenablage!',
      'tag_tag_name': 'Tag-Name',
      'tag_tag_line': 'Fügen Sie mit Premium mehr als einen Tag hinzu.',
      'text_text_saved': 'Text gespeichert!',
      'text_link_saved': 'Link gespeichert!',
      'text_count_words': '{{0}} Wörter',
      'text_count_character': '{{0}} Zeichen',
      'text_recognizer_text_recognizer': 'Texterkennung',
      'text_recognizer_choose_image': 'Bild auswählen',
      'text_recognizer_intro': 'Wählen Sie ein Bild, um die Texterkennung zu verwenden!',
      'text_recognizer_processing': 'Verarbeitung',
      'text_recognizer_error': 'Schönes Bild, aber es wurde ein Fehler erkannt',
      'text_recognizer_no_text': 'Schönes Bild, aber kein Text wurde erkannt',
      'push_allow_message': 'Erlauben Sie Push-Benachrichtigungen, um Benachrichtigungen zu erhalten, wenn Inhalte zu <bold>{{0}}</bold> hinzugefügt werden.',
      'push_allow_notifications': 'Benachrichtigungen zulassen',
      'push_push_group_enabled': 'Push-Benachrichtigungen für <bold>{{0}}</bold> sind aktiviert!',
      'push_snooze_notifications': 'Benachrichtigungen stummschalten',
      'push_push_group_snoozed': 'Push-Benachrichtigungen für <bold>{{0}}</bold> sind stummgeschaltet!',
      'push_turn_on_notifications': 'Benachrichtigungen einschalten',
      'push_permission_denied_message': 'Push-Benachrichtigungen wurden auf diesem Gerät verweigert. Schalten Sie sie ein, wenn Sie Benachrichtigungen erhalten möchten.',
      'push_app_settings': 'App-Einstellungen',
      'push_tagline': 'Schalten Sie Benachrichtigungen für mehr als 1 Gruppe mit Premium ein!',
      'app_icon_original': 'Original',
      'app_icon_light': 'Hell',
      'app_icon_dark': 'Dunkel',
      'app_icon_mono_light': 'Mono hell',
      'app_icon_mono_dark': 'Mono dunkel',
      'crop_info': 'Wählen oder erfassen Sie ein Bild\nund schneiden Sie es dann zu!',
      'crop_tagline': 'Hat Ihnen dieses Werkzeug gefallen? Verwenden Sie es jederzeit mit Premium!',
      'keyboard_custom_keyboard': 'Benutzerdefinierte Tastatur',
      'keyboard_custom_keyboard_sub': 'Erhalten Sie Zugriff auf Ihre Dmyst-Inhalte von jeder App aus, wenn Sie die Tastatur aufrufen',
      'keyboard_get_started': 'Erste Schritte',
      'keyboard_get_started_sub': 'Öffnen Sie die App-Einstellungen, aktivieren Sie die Tastatur und gewähren Sie den vollständigen Zugriff',
      'keyboard_open_settings': 'App-Einstellungen öffnen',
      'keyboard_full_access': 'Warum voller Zugriff?',
      'keyboard_full_access_sub': 'Dmyst ruft Ihre Inhalte aus der Cloud ab, daher ist eine Netzwerkverbindung erforderlich',
      'keyboard_no_tracking': 'Keine Verfolgung',
      'keyboard_no_tracking_sub': 'Die Dmyst-Tastatur verfolgt KEINE Aktionen, die Sie mit der Tastatur ausführen, z. B. Tastenanschläge',
      'browser_url_placeholder': 'Geben Sie hier Ihre Webadresse ein',
      'browser_copied_group': 'In Gruppe kopiert!',
      'browser_tagline': 'Kopieren Sie beim Surfen Elemente in Ihre Gruppe!',
      'browser_cta': 'Wenn Sie das 📋-Symbol unten rechts aktivieren, wird alles, was Sie kopieren, zur aktuellen Gruppe hinzugefügt.\n\nWie praktisch!',
      'login_sub_text': 'Melden Sie sich an, um sich selbst Notizen und Dateien zu senden.',
      'login_terms': 'Geschäftsbedingungen',
      'login_privacy': 'Datenschutzbestimmungen',
      'group_tagline': 'Fügen Sie mit Premium mehr als 2 Gruppen hinzu.',
      'menu_panel_logout_warning': 'Möchten Sie sich wirklich abmelden?',
      'menu_panel_logout': 'Ausloggen',
      'menu_panel_recent': 'Kürzlich',
      'menu_panel_give_feedback': 'Feedback geben',
      'menu_panel_settings': 'Einstellungen',
      'items_empty_group_gone': 'Alle Gruppen sind weg!',
      'items_empty_group_sub': 'Genießen Sie in der Zwischenzeit Nyan Cat',
      'tooltip_hold': 'Halten für mehr Optionen!',
      'items_empty_group': 'Diese Gruppe ist im Moment leer',
      'delete_group_confirm': 'Sind Sie sicher, dass Sie diese Gruppe löschen möchten?',
      'toast_clipboard_empty': 'Zwischenablage ist leer',
      'items_tagline': 'Fügen Sie mit Premium mehr Notizen hinzu.',
      'storage_storage_space': 'Speicherplatz',
      'storage_storage_space_warning': 'Sie haben das Speicherplatzlimit erreicht. Löschen Sie einige Dateien, um Speicherplatz freizugeben.',
      'storage_tagline': 'Erhalten Sie mit Premium mehr Speicherplatz.',
      'menu_select_action': 'Wählen Sie eine Aktion aus, die ausgeführt werden soll',
      'clip_clip_saved': 'Clip gespeichert!',
      'clip_clip_failed_message': 'Clipboard-Element konnte nicht gespeichert werden',
      'toast_deleted': 'Gelöscht!',
      'toast_file_copied': 'Datei kopiert!',
      'toast_file_copy_failed': 'Diese Datei konnte nicht kopiert werden',
      'toast_image_copied': 'Bild kopiert!',
      'toast_image_copy_failed': 'Dieses Bild konnte nicht kopiert werden',
      'toast_saved': 'Gespeichert!',
      'shorten_link_tagline': 'Verkürzen Sie Links in der App mit Premium!',
      'text_recognizer_tagline': 'Verwenden Sie den Texterkennungsdienst für Bilder mit Premium!',
      'clip_clipboard_cleared': 'Zwischenablage geleert!',
      'drag_drop_cta': 'Hier ablegen, um hinzuzufügen',
      'purchase_purchase_successful': 'Kauf erfolgreich',
      'purchase_purchase_failed': 'Kauf fehlgeschlagen',
      'purchase_thanks': 'Vielen Dank für den Kauf! Sie verwenden jetzt Dmyst Premium.',
      'purchase_congrats': 'Herzlichen Glückwunsch',
      'purchase_premium_activated': 'Premium-Abonnement aktiviert',
      'purchase_sync_across': 'Synchronisieren Sie über Geräte hinweg mit mehr Funktionen',
      'feature_bigger_collections': 'Größere Sammlungen',
      'feature_bigger_collections_sub': 'Organisieren Sie Ihre Inhalte mit mehr Gruppen, mehr Inhalt pro Gruppe und mehr Speicherplatz',
      'feature_all_tools': 'Auf alle Tools zugreifen',
      'feature_all_tools_sub': 'Fügen Sie mehrere Tags, mehr Benachrichtigungsgruppen, Texterkennung, Link-Verkürzer, zugeschnittene Bilder und mehr hinzu',
      'feature_biometric': 'Biometrische Sperre',
      'feature_biometric_sub': 'Halten Sie Ihre Inhalte hinter einer Sicherheitssperre, um zu verhindern, dass andere in der App herumschnüffeln',
      'feature_one_sub': 'Ein Abonnement',
      'feature_one_sub_sub': 'Kauf auf einer Plattform, und Ihre Premium-Mitgliedschaft funktioniert auf all Ihren Geräten, einschließlich iOS, Android und im Web',
      'general_continue': 'Fortsetzen',
      'purchase_sub_notice': 'Abonnements werden über Ihre Kreditkarte über Ihr iTunes-Konto abgerechnet. Abonnements werden automatisch verlängert, es sei denn, sie werden innerhalb von 24 Stunden vor Ablauf des aktuellen Zeitraums gekündigt. Sie können jederzeit über die Einstellungen Ihres iTunes-Kontos kündigen. Weitere Informationen finden Sie in den unten stehenden Geschäftsbedingungen und der Datenschutzrichtlinie.',
      'purchase_learn_more': 'Erfahren Sie mehr',
      'purchase_restore': 'Kauf wiederherstellen',
      'purchase_month': 'Monat',
      'purchase_months': 'Monate',
      'viewer_no_preview': 'Keine Vorschau verfügbar',
      'viewer_no_preview_message': 'Die Datei wird nicht erkannt, um eine Vorschau anzuzeigen',
      'scanner_quick_send': 'Schnell senden',
      'scanner_qr_code': 'QR-Code-Scanner',
      'scanner_quick_send_title': 'Gehe zu {0}, um dieses Element an deinen Webbrowser zu senden',
      'scanner_qr_code_title': 'Scannen Sie einen QR-Code und sehen Sie hier die Vorschau',
      'scanner_invalid_qr': 'Ungültiger QR-Code',
      'scanner_invalid_qr_message': 'Der gescannte Code sieht nicht richtig aus. Stellen Sie sicher, dass Sie den QR-Code auf der Website dmystapp.com/quick scannen.',
      'error_something_wrong': 'Etwas ist schief gelaufen',
      'error_something_wrong_message': 'Beim Senden des Inhalts ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
      'toolbox_toolbox': 'Werkzeugkasten',
      'biometric_authenticate_test': 'Authentifizieren Sie sich, um es auszuprobieren!',
      'biometric_authenticate_access': 'Authentifizieren Sie sich, um auf Dmyst zuzugreifen',
      'recent_tagline': 'Alle kürzlich hinzugefügten Artikel mit Premium anzeigen!',
      'recent_recently_text': 'Zuletzt hinzugefügte Textelemente',
      'recent_recently_link': 'Zuletzt hinzugefügte Links',
      'recent_recently_images': 'Zuletzt hinzugefügte Bilder',
      'recent_recently_files': 'Zuletzt hinzugefügte Dateien',
      'share_share_to_dmyst': 'Teilen Sie zu Dmyst',
      'share_select_group_message': 'Wählen Sie eine Gruppe aus Ihrem Dmyst-Konto aus, um Ihren Inhalt hinzuzufügen.',
      'share_no_login': 'Willkommen bei Dmyst!\n\nUm die Freigabefunktion zu verwenden, müssen Sie die App starten, um sich anzumelden oder zunächst ein Konto zu erstellen.',
      'share_read_error': 'Etwas ist schief gelaufen!\n\nDie freigegebenen Daten enthielten unbekannte Daten oder Dmyst konnte nicht auf die Daten zugreifen.',
      'share_non_premium_max_items': 'Sie haben Ihr Elementlimit erreicht!\n\nSie haben Ihr Elementlimit für diese Gruppe erreicht. Rüsten Sie auf Dmyst Premium auf, um mehr hinzuzufügen!',
      'share_non_premium_max_storage': 'Sie haben Ihr Speicherlimit erreicht!\n\nSie haben Ihr Speicherlimit erreicht. Rüsten Sie auf Dmyst Premium auf, um mehr hinzuzufügen!',
      'share_premium_max_storage': 'Sie haben Ihr Speicherlimit erreicht!\n\nSie haben Ihr Speicherlimit erreicht. Löschen Sie einige Dateien aus Ihren Gruppen, bevor Sie fortfahren.',
      'share_upload_error': 'Etwas ist schief gelaufen!\n\nEs ist ein Fehler beim Hochladen der Datei aufgetreten.',
      'share_sharing': 'Teilen',
      'share_sharing_progress': 'Teilen von {0} von {1}',
      'keyboard_allow_full_access': 'Bitte erlauben Sie den vollen Zugriff, um Ihre Dmyst-Inhalte anzuzeigen',
      'keyboard_login': 'Bitte melden Sie sich bei Dmyst an, um Ihren Inhalt anzuzeigen',
      'keyboard_allow_full_access2': 'Erlauben Sie den vollen Zugriff, um in die Zwischenablage zu kopieren',
      'menu_export_items': 'Elemente exportieren',
      'export_export': 'Exportieren',
      'export_export_text_link': 'Exportieren von {{0}} Texten und Links',
      'export_export_images_files': 'Exportieren von {{0}} Bildern und Dateien',
      'menu_popup_pin': 'An oberster Stelle anheften',
      'menu_popup_unpin': 'Lösen',
      'menu_popup_copy_multiple': 'Mehrere kopieren',
      'toast_nothing_export': 'Es gibt nichts zu exportieren',
      'limit_items_per_group': 'Gruppen sind auf {{0}} pro Gruppe begrenzt',
      'limit_suggest_premium': 'Fügen Sie bis zu {{0}} hinzu, wenn Sie auf Premium aktualisieren',
      'limit_export_delete_suggest': 'Exportieren Sie Ihre Daten, wenn Sie sie behalten möchten, und löschen Sie bei Bedarf einige Elemente, um mehr Platz zu schaffen',
      'export_clean_up': 'Aufräumen',
      'export_bulk_delete': 'Massenlöschung',
      'settings_screen_security': 'Bildschirmsicherheit',
      'settings_security': 'Sicherheit',
      'settings_screen_security_desc': 'Dmyst-Inhalte beim Wechseln zwischen Apps ausblenden',
      'share_premium_max_items': 'Sie haben Ihr Elementlimit für diese Gruppe erreicht. Verwenden Sie eine andere Gruppe oder löschen Sie zuerst Elemente in dieser Gruppe.',
      'bulk_delete_delete_items': 'Elemente löschen',
      'bulk_delete_message': 'Möchten Sie die ausgewählten Elemente wirklich löschen?'
    }
  },
  ja: {
    translation: {
      'file_upload_failed': 'すべてのファイルが正常にアップロードされませんでした',
      'five_file_limit': '一度にアップロードできるファイルは5つだけです',
      'general_save': '保存',
      'text_unsaved': '未保存のテキスト',
      'text_unsaved_msg': '閉じる前に保存しますか？',
      'text_start_typing': 'ここから入力を開始',
      'quick_send_download': 'file.io からダウンロード',
      'quick_send_reset': 'リセット',
      'quick_send_footer': 'ファイルは一時的に<1>file.io</1>に保存されます。安全でプライバシーを重視しています。',
      'quick_send_web_title': 'Dmystモバイルアプリからアイテムを送信',
      'quick_send_step_1': '1. モバイルデバイスで<Dmyst</1>アプリを起動',
      'quick_send_step_2': '2. 送信したいアイテムを長押し',
      'quick_send_step_3': '3. <1>クイック送信コードをスキャン</1>を選択',
      'quick_send_step_4': '4. 上に表示されるQRコードをスキャン',
      'toolbox_web_crop': '画像を選択して切り取る',
      'notification_why_seeing': 'なぜこれを見ているのですか？',
      'notification_why_seeing_desc': 'Webブラウザの通知機能には限界があり、ユーザーの操作がブラウザウィンドウから要求されるため、1回のクリックでコピーが必要です。',
      'general_download': 'ダウンロード',
      'general_copied': 'コピーしました！',
      'button_copy_to_clipboard': 'クリップボードにコピー',
      'settings_one_click_sub': '1回のクリックでテキストはクリップボードにコピーされ、ファイルはダウンロードされます',
      'settings_one_click': '1クリックでコピー/ダウンロード',
      'premium_web_description': 'iOSまたはAndroidデバイスにアプリをダウンロードし、アプリで購読することでプレミアムを入手できます。',
      'logout_deleting_account': 'アカウントを削除しています',
      'logout_logging_out': 'ログアウト中',
      'general_loading': '読み込み中',
      'login_apps_mobile_message': '開始するにはアプリをダウンロードしてください',
      'login_apps_desktop_message': 'アプリをダウンロードするのを忘れないでください',
      'login_apple': 'Appleでサインイン',
      'push_notifications_not_supported': 'このブラウザではプッシュ通知はサポートされていません',
      'share_to_twitter': 'Twitterに共有',
      'share_to_whatsapp': 'WhatsAppに共有',
      'feedback_sent': 'フィードバックが送信されました！',
      'general_send': '送信',
      'add_group_special_chars': 'ダッシュ（-）とアンダースコア（_）以外の特殊文字は許可されていません',
      'items_add_placeholder': 'このグループに何かを追加',
      'menu_delete_group': 'グループを削除',
      'menu_change_group_name': 'グループ名の変更',
      'menu_panel_good_morning': 'おはようございます',
      'menu_panel_good_afternoon': 'こんにちは',
      'menu_panel_good_evening': 'こんばんは',
      'toolbox_clear_clipboard': 'クリップボードをクリア',
      'toolbox_scan_document': '文書をスキャン',
      'toolbox_capture_crop_image': '画像を撮影して切り抜く',
      'toolbox_qr_scanner': 'QRコードスキャナ',
      'toolbox_browse_copy': 'ブラウズしてコピー',
      'toolbox_text_recognizer': 'テキスト認識',
      'toolbox_sub_clear_clipboard': 'クリップボードにコピーされた内容をクリアします',
      'toolbox_sub_scan_document': 'カメラを使用して文書を画像にスキャン',
      'toolbox_sub_capture_crop_image': '写真を撮影して好きなように切り抜く',
      'toolbox_sub_qr_scanner': 'QRコードをスキャンしてその内容を読み取る',
      'toolbox_sub_browse_copy': 'ウェブを閲覧してコンテンツをグループにコピー',
      'toolbox_sub_text_recognizer': '写真を撮影してテキストを抽出',
      'menu_panel_groups': 'グループ',
      'menu_panel_more': 'その他',
      'menu_panel_storage': 'ストレージ',
      'settings_general': '一般',
      'settings_app_icon': 'アプリアイコン',
      'settings_social': 'ソーシャル',
      'settings_premium': 'プレミアム',
      'recent_premium_placeholder': 'プレミアムでさらに見る',
      'recent_empty_placeholder': 'ここには何もありません',
      'media_uploading': 'アップロード中',
      'login_google': 'Googleアカウントでサインイン',
      'progress_sending': '送信中',
      'menu_popup_view_file': 'ファイルを表示',
      'menu_popup_view_image': '画像を表示',
      'menu_popup_edit': '編集',
      'menu_popup_copy': 'コピー',
      'menu_popup_tag': 'タグ',
      'menu_popup_share': '共有',
      'menu_popup_scan_quick_send_code': 'クイック送信コードをスキャン',
      'menu_popup_copy_image': '画像をコピー',
      'menu_popup_save_camera_roll': 'カメラロールに保存',
      'menu_popup_copy_file': 'ファイルをコピー',
      'menu_popup_copy_file_name': 'ファイル名をコピー',
      'menu_popup_save_file': 'ファイルを保存',
      'menu_popup_open_link': 'リンクを開く',
      'menu_popup_delete': '削除',
      'menu_popup_text_transform': 'テキスト変換',
      'menu_popup_link_comps': 'リンクの構成要素',
      'menu_popup_shorten_link': 'リンクを短縮',
      'menu_popup_call_number': '番号を呼び出す',
      'toast_image_added': '画像が追加されました',
      'toast_file_added': 'ファイルが追加されました',
      'error_connection_offline': '接続がオフラインになっています',
      'error_file_upload_fail': 'ファイルのアップロードに失敗しました',
      'photo_upload_photo': '写真をアップロード',
      'general_cancel': 'キャンセル',
      'photo_camera': 'カメラ',
      'photo_gallery': 'ギャラリー',
      'toast_copied_clipboard': 'クリップボードにコピーしました',
      'toast_undo': '元に戻す',
      'clip_clip': 'クリップ',
      'clip_empty_placeholder': 'クリップボードには何もありません！',
      'clip_preview_file': 'ファイルのプレビュー',
      'clip_error_placeholder': 'クリップボードのデータを読み取れませんでした。\n\nフィードバックでコピーした内容をお知らせください。将来サポートされるようにします。',
      'feedback_feedback': 'フィードバック',
      'feedback_email': 'メール',
      'feedback_optional': '任意',
      'feedback_placeholder': 'ここにフィードバック、コメント、および/または提案を記入してください',
      'group_add_group': 'グループを追加',
      'group_edit_group': 'グループを編集',
      'group_enter_name': 'グループ名を入力',
      'group_invalid_placeholder': '名前に無効な文字が含まれています',
      'group_name_changed': 'グループ名が変更されました！',
      'settings_settings': '設定',
      'settings_enable_link_previews': 'リンクプレビューを有効にする',
      'settings_enable_bio_lock': '生体認証ロックを有効にする',
      'settings_one_tap': 'ワンタップコピー',
      'settings_one_tap_desc': 'アイテムをタップするとクリップボードにコピーされます',
      'settings_keyboard': 'キーボード',
      'premium_go_premium': 'プレミアムにアップグレード',
      'premium_membership_active': 'メンバーシップが有効です',
      'premium_tagline': 'プレミアムでストレージ、グループ、ノートを追加！ ここから始めましょう。',
      'settings_rate_dmyst': 'Dmystを評価する',
      'settings_follow_twitter': '@dmyst_appをフォローする | Twitter',
      'settings_facebook_page': 'Facebookページ',
      'settings_delete_account': 'アカウントを削除',
      'settings_version': 'バージョン',
      'premium_bio_tagline': 'アプリ起動時に生体認証を使用してDmystをロック解除する',
      'settings_are_you_sure': 'よろしいですか？',
      'settings_delete_desc': 'これにより、アカウントとそれに関連するすべてのデータが削除されます。',
      'settings_no_sync_desc': '設定はデバイス間で同期されません',
      'shorten_shorten_link': 'リンクを短縮',
      'shorten_shortening': '短縮中',
      'shorten_copy_link': 'リンクをコピー',
      'shorten_add_group': 'グループに追加',
      'error_oops': 'おっと、何かがうまくいきませんでした。 もう一度やり直してください！',
      'transform_upper_case': '大文字',
      'transform_lower_case': '小文字',
      'transform_word_case': '単語の大文字化',
      'transform_no_space': '単語の大文字化（スペースなし）',
      'transform_reverse_char': '文字列を逆順に',
      'transform_reverse_words': '文を逆順に',
      'transform_reverse_words_case': '文を逆順に（単語の大文字化）',
      'transform_space_claps': 'スペースを拍手で置換',
      'transform_hashtag': '各単語にハッシュタグを追加',
      'transform_hashtag_case': '各単語にハッシュタグを追加（単語の大文字化）',
      'transform_upside_down': '上下逆さまのテキスト',
      'transform_trim': '両端の空白を削除',
      'transform_trim_white_break': '両端の空白/改行を削除',
      'transform_url_encode': 'URLエンコード',
      'transform_comma_dash': 'コンマをダッシュ付きリストに',
      'transform_comma_bullet': 'コンマを箇条書きリストに',
      'transform_remove_numbers': '数字を削除',
      'transform_link_comps': 'リンクの構成要素',
      'transform_text_transform': 'テキスト変換',
      'transform_scheme': 'スキーム',
      'transform_host': 'ホスト',
      'transform_path': 'パス',
      'transform_path_sub': 'サブコンポーネントのパス',
      'transform_queries': 'クエリ',
      'transform_query': 'クエリ',
      'transform_tagline': 'これらのアイテムをプレミアムでクリップボードにコピー！',
      'tag_tag_name': 'タグ名',
      'tag_tag_line': 'プレミアムで1つ以上のタグを追加します。',
      'text_text_saved': 'テキストが保存されました！',
      'text_link_saved': 'リンクが保存されました！',
      'text_count_words': '{{0}} 単語',
      'text_count_character': '{{0}} 文字',
      'text_recognizer_text_recognizer': 'テキスト認識',
      'text_recognizer_choose_image': '画像を選択',
      'text_recognizer_intro': 'テキスト認識を使用する画像を選択してください！',
      'text_recognizer_processing': '処理中',
      'text_recognizer_error': '素敵な画像ですが、エラーが検出されました',
      'text_recognizer_no_text': '素敵な画像ですが、テキストは検出されませんでした',
      'push_allow_message': '<bold>{{0}}</bold> にコンテンツが追加されたときにアラートを受け取るためにプッシュ通知を許可します',
      'push_allow_notifications': '通知を許可',
      'push_push_group_enabled': '<bold>{{0}}</bold> へのプッシュ通知が有効になっています！',
      'push_snooze_notifications': '通知の一時停止',
      'push_push_group_snoozed': '<bold>{{0}}</bold> へのプッシュ通知が一時停止されています！',
      'push_turn_on_notifications': '通知を有効にする',
      'push_permission_denied_message': 'このデバイスでプッシュ通知の許可が拒否されました。通知を受け取りたい場合は有効にしてください。',
      'push_app_settings': 'アプリ設定',
      'push_tagline': 'プレミアムで1つ以上のグループ用に通知を有効にします！',
      'app_icon_original': 'オリジナル',
      'app_icon_light': 'ライト',
      'app_icon_dark': 'ダーク',
      'app_icon_mono_light': 'モノクロ ライト',
      'app_icon_mono_dark': 'モノクロ ダーク',
      'crop_info': '画像を選択またはキャプチャしてから切り抜いてください！',
      'crop_tagline': 'このツールが気に入ったら、プレミアムでいつでも使用できます！',
      'keyboard_custom_keyboard': 'カスタムキーボード',
      'keyboard_custom_keyboard_sub': 'キーボードを表示すると、どのアプリからでもDmystコンテンツにアクセスできます',
      'keyboard_get_started': 'はじめに進む',
      'keyboard_get_started_sub': 'アプリ設定を開き、キーボードを有効にし、フルアクセスを許可してください',
      'keyboard_open_settings': 'アプリ設定を開く',
      'keyboard_full_access': 'フルアクセスとは？',
      'keyboard_full_access_sub': 'Dmystはクラウドからコンテンツを取得するため、ネットワーク接続が必要です',
      'keyboard_no_tracking': '追跡なし',
      'keyboard_no_tracking_sub': 'Dmystキーボードは、キーストロークなどのキーボードで行うアクションを追跡しません',
      'browser_url_placeholder': 'ここにウェブアドレスを入力してください',
      'browser_copied_group': 'グループにコピーしました！',
      'browser_tagline': 'ブラウジング中にアイテムをグループにコピーしましょう！',
      'browser_cta': '右下の📋アイコンを有効にすると、コピーした内容が現在のグループに追加されます。\n\n便利ですね！',
      'login_sub_text': 'ログインして、自分にノートとファイルを直接メッセージングし始めます。',
      'login_terms': '利用規約',
      'login_privacy': 'プライバシーポリシー',
      'group_tagline': 'プレミアムで2つ以上のグループを追加します。',
      'menu_panel_logout_warning': 'ログアウトしてもよろしいですか？',
      'menu_panel_logout': 'ログアウト',
      'menu_panel_recent': '最近',
      'menu_panel_give_feedback': 'フィードバックを送る',
      'menu_panel_settings': '設定',
      'items_empty_group_gone': 'すべてのグループが消えました！',
      'items_empty_group_sub': 'その間、にゃん猫をお楽しみください',
      'tooltip_hold': 'さらなるオプションを表示するには長押し！',
      'items_empty_group': 'このグループは現在空です',
      'delete_group_confirm': 'このグループを削除してもよろしいですか？',
      'toast_clipboard_empty': 'クリップボードは空です',
      'items_tagline': 'プレミアムでさらにノートを追加します。',
      'storage_storage_space': 'ストレージスペース',
      'storage_storage_space_warning': 'ストレージスペースの制限に達しました。いくつかのファイルを削除してスペースを解放してください。',
      'storage_tagline': 'プレミアムでより多くのストレージスペースを取得します。',
      'menu_select_action': '実行するアクションを選択',
      'clip_clip_saved': 'クリップ保存済み！',
      'clip_clip_failed_message': 'クリップボードアイテムを保存できませんでした',
      'toast_deleted': '削除しました！',
      'toast_file_copied': 'ファイルがコピーされました！',
      'toast_file_copy_failed': 'このファイルはコピーできませんでした',
      'toast_image_copied': '画像がコピーされました！',
      'toast_image_copy_failed': 'この画像はコピーできませんでした',
      'toast_saved': '保存しました！',
      'shorten_link_tagline': 'プレミアムでアプリ内のリンクを短縮します！',
      'text_recognizer_tagline': 'プレミアムで画像のテキスト認識を使用します！',
      'clip_clipboard_cleared': 'クリップボードがクリアされました！',
      'drag_drop_cta': 'ここにドロップして追加します',
      'purchase_purchase_successful': '購入が成功しました',
      'purchase_purchase_failed': '購入に失敗しました',
      'purchase_thanks': '購入ありがとうございます！Dmystプレミアムをご利用いただけます。',
      'purchase_congrats': 'おめでとうございます',
      'purchase_premium_activated': 'プレミアムサブスクリプションがアクティブになりました',
      'purchase_sync_across': 'より多くの機能でデバイス間の同期',
      'feature_bigger_collections': 'より大きなコレクション',
      'feature_bigger_collections_sub': 'より多くのグループ、グループごとのコンテンツ、ストレージスペースでコンテンツを整理します',
      'feature_all_tools': 'すべてのツールにアクセス',
      'feature_all_tools_sub': '複数のタグ、より多くの通知グループ、テキスト認識、リンクの短縮、キャプチャした画像のトリミングなどを追加します',
      'feature_biometric': '生体認証ロック',
      'feature_biometric_sub': '他の人がアプリをのぞき見できないようにコンテンツをセキュリティロックの背後に保持します',
      'feature_one_sub': '1つのサブスクリプション',
      'feature_one_sub_sub': '1つのプラットフォームで購入し、プレミアムメンバーシップはiOS、Android、ウェブを含むすべてのデバイスで機能します',
      'general_continue': '続行',
      'purchase_sub_notice': '定期購読はiTunesアカウントを通じてクレジットカードに請求されます。定期購読は現在の期間の終了前に24時間以内にキャンセルしない限り、自動的に更新されます。いつでもiTunesアカウントの設定でキャンセルできます。詳細については、以下の条件とプライバシーポリシーをご覧ください。',
      'purchase_learn_more': '詳細を見る',
      'purchase_restore': '以前の購入を復元',
      'purchase_month': '月',
      'purchase_months': 'ヶ月',
      'viewer_no_preview': 'プレビューは利用できません',
      'viewer_no_preview_message': 'ファイルはプレビューを表示するために認識されません',
      'scanner_quick_send': 'クイック送信',
      'scanner_qr_code': 'QRコードスキャナー',
      'scanner_quick_send_title': 'このアイテムをウェブブラウザーに送信するには{0}に移動してください',
      'scanner_qr_code_title': 'QRコードをスキャンしてここでプレビューを表示します',
      'scanner_invalid_qr': '無効なQRコード',
      'scanner_invalid_qr_message': 'スキャンされたコードが正しくありません。dmystapp.com/quickウェブサイトのQRコードをスキャンすることを確認してください。',
      'error_something_wrong': '何かが間違っていました',
      'error_something_wrong_message': 'コンテンツを送信する際にエラーが発生しました。もう一度お試しください。',
      'toolbox_toolbox': 'ツールボックス',
      'biometric_authenticate_test': 'テストするには認証してください！',
      'biometric_authenticate_access': 'Dmystにアクセスするには認証してください',
      'recent_tagline': 'プレミアムですべての最近追加されたアイテムを表示します！',
      'recent_recently_text': '最近追加されたテキストアイテム',
      'recent_recently_link': '最近追加されたリンク',
      'recent_recently_images': '最近追加された画像',
      'recent_recently_files': '最近追加されたファイル',
      'share_share_to_dmyst': 'Dmystに共有する',
      'share_select_group_message': 'Dmystアカウントからグループを選択してコンテンツを追加してください。',
      'share_no_login': 'Dmystへようこそ！\n\n共有機能を使用するには、まずアプリを起動してログインするかアカウントを作成する必要があります。',
      'share_read_error': '何かが間違っていました！\n\n共有されたデータには不明なデータが含まれているか、Dmystがデータにアクセスできませんでした。',
      'share_non_premium_max_items': 'アイテムの制限に達しました！\n\nこのグループのアイテムの制限に達しました。さらに追加するにはDmystプレミアムにアップグレードしてください！',
      'share_non_premium_max_storage': 'ストレージの制限に達しました！\n\nストレージの制限に達しました。さらに追加するにはDmystプレミアムにアップグレードしてください！',
      'share_premium_max_storage': 'ストレージの制限に達しました！\n\n続行する前に、グループからいくつかのファイルを削除してください。',
      'share_upload_error': '何かが間違っていました！\n\nファイルをアップロードしようとする際にエラーが発生しました。',
      'share_sharing': '共有中',
      'share_sharing_progress': '{1}の{0}を共有中',
      'keyboard_allow_full_access': 'Dmystコンテンツを表示するためには、フルアクセスを許可してください。',
      'keyboard_login': 'コンテンツを表示するには、Dmystにログインしてください。',
      'keyboard_allow_full_access2': 'クリップボードにコピーするためにフルアクセスを許可します。',
      'menu_export_items': 'アイテムをエクスポート',
      'export_export': 'エクスポート',
      'export_export_text_link': '{{0}} テキストとリンクをエクスポートする',
      'export_export_images_files': '{{0}} 画像とファイルをエクスポートする',
      'menu_popup_pin': 'トップに固定',
      'menu_popup_unpin': 'ピン留め解除',
      'menu_popup_copy_multiple': '複数コピー',
      'toast_nothing_export': 'エクスポートするものはありません',
      'limit_items_per_group': 'グループごとの制限は{{0}}です',
      'limit_suggest_premium': 'プレミアムにアップグレードすると最大{{0}}まで追加できます',
      'limit_export_delete_suggest': 'データをエクスポートして保持し、必要に応じてアイテムを削除してスペースを増やします',
      'export_clean_up': 'クリーンアップ',
      'export_bulk_delete': '一括削除',
      'settings_screen_security': '画面セキュリティ',
      'settings_security': 'セキュリティ',
      'settings_screen_security_desc': 'アプリ間を切り替える際にDmystのコンテンツを非表示にする',
      'share_premium_max_items': 'このグループのアイテム制限に達しました。別のグループを使用するか、まずこのグループのアイテムを削除してください。',
      'bulk_delete_delete_items': 'アイテムを削除',
      'bulk_delete_message': '選択したアイテムを削除してもよろしいですか？'
  
    }
    
  }
};

i18n.use(detector).use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
