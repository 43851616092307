import { useEffect, useRef } from 'react';
import SideBar from '../components/sideBar';
import ItemsView from '../components/itemsView';
import { Stack } from '@fluentui/react';
import { ToastType, useStyles } from '../assets/styles';
import { useLightSwitch } from 'use-light-switch'
import RecentView from '../components/recentView';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { usePremiumDialogState, usePremiumState, usePushGroupsState, useRecentSelectedState, useRootDisplayState, useStorageSizeState, useTagsState, useToast } from '../shared/stores/stores';
import { container } from 'tsyringe';
import { CollectionKey, DeleteToastState, ITag, RootDisplayType} from '../shared/stores/types';
import { PremiumGuardDialog } from '../dialogs/premiumGuardDialog';
import { AuthService } from '../shared/services/authService';
import { FirestoreService } from '../shared/services/firestoreService';
import { ConsoleLoggerService } from '../shared/services/consoleLoggerService';

const Dashboard = () => {

    const { t } = useTranslation()
    const styles = useStyles()
    const mode = useLightSwitch()
    const timeoutIdRef = useRef<number | undefined>(undefined)

    const consoleLoggerService = container.resolve(ConsoleLoggerService)
    const firestoreService = container.resolve(FirestoreService)
    const authService = container.resolve(AuthService)
    const { setTags } = useTagsState()
    const { setPushGroups } = usePushGroupsState()
    const { toastSetup, showToast, setDeleteToastState } = useToast()
    const { setPremium } = usePremiumState()
    const { setStorageSize } = useStorageSizeState()
    const { show: showPremiumDialog  } = usePremiumDialogState()
    const { isRecentSelected } = useRecentSelectedState()
    const { setRootDisplayType } = useRootDisplayState();

    useEffect(() => {
        const listener = firestoreService.observeStorageSize((size: number) => {
            consoleLoggerService.log('Storage size listener fired')
            setStorageSize(size)
        })
        return listener
    }, [])

    useEffect(() => {
        const userId = authService.userId
        if (userId == null) {
            return
        }

        const listener = firestoreService.observeUser(userId, (userIsAlive: boolean) => {
            consoleLoggerService.log('User listener fired')
            if (!userIsAlive) {
                setRootDisplayType(RootDisplayType.logout)
            }
        })
        return listener
    }, [])

    useEffect(() => {
        if (Notification.permission === 'granted') {
            firestoreService.requestTokenAndSend()
        }

        const listener = firestoreService.observePushGroup((list: string[]) => {
            consoleLoggerService.log('Push groups listener fired')
            setPushGroups(list)
        })
        return listener
    }, [])

    useEffect(() => {
        const userId = authService.userId
        if (userId == null) {
            return
        }

        const listener = firestoreService.observe<ITag>(CollectionKey.Tags, 'owner', userId, (list: ITag[]) => {
            consoleLoggerService.log('Tags listener fired')
            setTags(list)
        })
        return listener
    }, [])

    useEffect(() => {
        if (toastSetup != null) {
            if (timeoutIdRef.current != null) {
                window.clearTimeout(timeoutIdRef.current)
                timeoutIdRef.current = undefined
            }
            toast.dismiss()
            const bgColor = styles.getToastBgColor(toastSetup.type, mode)
            const textColor = styles.getToastTextColor(toastSetup.type, mode)
            if (toastSetup.type == ToastType.Danger) {
                toast(
                    <Stack horizontal horizontalAlign={'space-between'} verticalAlign={'center'} style={{width: '300px'}}>
                        <div>{toastSetup.message}</div>
                        <button style={{fontWeight: 'bold', backgroundColor: '#00000000', color: textColor, border: 0, padding: 8, fontSize: 18, cursor: 'pointer'}} onClick={onUndoClicked}>{t('toast_undo')}</button>
                    </Stack>
                , { style: { backgroundColor: bgColor, fontSize: 22, fontWeight: 'medium', color: textColor } })
                const toId = window.setTimeout(() => {
                    timeoutIdRef.current = undefined
                    setDeleteToastState(DeleteToastState.AnimateDone)
                }, 4000)
                timeoutIdRef.current = toId
            } else {
                toast(toastSetup.message, { style: { backgroundColor: bgColor, fontSize: 22, fontWeight: 'medium', color: textColor } })
            }
            showToast(null)
        }
    }, [toastSetup])

    useEffect(() => {
        const userId = authService.userId
        if (userId == null) {
            return
        }
        getPurchaserInfo(userId)
    }, [])

    const onUndoClicked = () => {
        toast.dismiss()
        window.clearTimeout(timeoutIdRef.current)
        timeoutIdRef.current = undefined
        setDeleteToastState(DeleteToastState.UndoAction)
    }

    const apiKey = 'ZXjXoilRezWSrhPWKzWdkctbPYWtiWgE'

    const getPurchaserInfo = (userId: string) => {
        const headers = {'authorization': 'Bearer ' + apiKey, 'content-type': 'application/json'}
        fetch('https://api.revenuecat.com/v1/subscribers/' + userId, { headers })
            .then(response => response.json())
            .then(data => {
                if (data.subscriber !== undefined) {
                    if (data.subscriber.entitlements !== undefined) {
                        if (data.subscriber.entitlements.pro !== undefined) {
                            
                            const requestDate = Date.parse(data['request_date'])
                            const expire = Date.parse(data.subscriber.entitlements.pro['expires_date'])
                            setPremium(requestDate < expire)
                            return
                        }
                    }
                }
                setPremium(false)
            })
    }


    return (
        <div>
            <Toaster position={'bottom-center'} toastOptions={{style: {marginBottom: '80px', minWidth: '320px', minHeight: '44px'}}} />
            <Stack horizontal style={{ height: '100vh', overflow: 'hidden'}}>
                <Stack.Item disableShrink styles={{root: {width: '280px'}}}>
                    <SideBar />
                </Stack.Item>
                <Stack.Item grow>
                    {isRecentSelected ? <RecentView /> : <ItemsView />}
                </Stack.Item>
            </Stack>

            { showPremiumDialog && <PremiumGuardDialog />}
        </div>
    )
}

export default Dashboard