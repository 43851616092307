import {container, singleton} from 'tsyringe';
import { FirebaseService } from './firebaseService';
import { Auth, GoogleAuthProvider, OAuthProvider, User, browserLocalPersistence, getAuth, setPersistence, signInAnonymously, signInWithPopup } from 'firebase/auth';

@singleton()
export class AuthService {

    private readonly firebaseService: FirebaseService;
    public auth: Auth

    constructor() {
        this.firebaseService = container.resolve(FirebaseService);
        this.auth = getAuth(this.firebaseService.firebaseApp)
        setPersistence(this.auth, browserLocalPersistence)
    }

    public async loginWithGoogle(): Promise<void> {
        const googleProvider = new GoogleAuthProvider()
        await signInWithPopup(this.auth, googleProvider)
    }

    public async loginWithApple(): Promise<void> {
        const appleProvider = new OAuthProvider('apple.com');
        await signInWithPopup(this.auth, appleProvider)
    }

    public async loginAnnonymously(): Promise<void> {
        await signInAnonymously(this.auth);
    }

    public get user(): User | null {
        return this.auth.currentUser
    }

    public get userId(): string | null {
        if (this.user != null) {
            return this.user.uid
        }
        return null
    }
}