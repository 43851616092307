import { container, singleton } from 'tsyringe'
import { AuthService } from './authService'
import { FirebaseStorage, SettableMetadata, StorageReference, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { FirebaseService } from './firebaseService'
import { FirestoreService } from './firestoreService'
import { MissingUserIdError } from '../utils/errors'
import { IItem } from '../stores/types'

@singleton()
export class StorageService {

    private readonly authService: AuthService
    private readonly firestoreService: FirestoreService
    private readonly storage: FirebaseStorage

    constructor() {
        const firebaseService = container.resolve(FirebaseService)
        this.authService = container.resolve(AuthService)
        this.firestoreService = container.resolve(FirestoreService)
        this.storage = getStorage(firebaseService.firebaseApp)
    }

    public async getItemDownloadUrl(item: IItem): Promise<string> {
        return await getDownloadURL(this.storageRef(item))
    }

    private storageRef(item: IItem): StorageReference {
        const userId = this.authService.userId
        if (userId == null) {
            throw new MissingUserIdError()
        }
        return ref(this.storage, `${userId}/${item.owner}/${item.uid}/${item.content}`)
    }

    public async uploadFile(file: File, item: IItem): Promise<void> {
        const meta = {
            contentDisposition: `attachment; filename="${item.content}"`
        } as SettableMetadata
        const storeRef = this.storageRef(item)
        const result = await uploadBytes(storeRef, file, meta)
        item.fileSize = result.metadata.size
        await this.firestoreService.addItem(item)
    }
}