import { IItem } from '../stores/types';

export const useUtils = () => {
    const currentTimestamp = (): number => {
        return Math.round(new Date().getTime() / 1000);
    }

    const fireId = (): string => {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (let i = 20; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }


    const hasNoSpecialChars = (text: string): boolean => {
        // var re = /^[a-zA-Z0-9 _-]+$/; // or /^\w+$/ as mentioned
        const regex = /[^a-zA-Z0-9\s\u4E00-\u9FFF\u3040-\u309F\u30A0-\u30FF-_]/
        return !regex.test(text) //&& text.search(/[a-zA-Z]/) !== -1
    }

    const validURL = (str: string): boolean => {
        const lowerVersion = str.toLowerCase()
        if (lowerVersion.startsWith('http:') || lowerVersion.startsWith('https:') || lowerVersion.startsWith('www.')) {
            return true;
        }
        if (lowerVersion.indexOf(' ') >= 0 || lowerVersion.indexOf('\n') >= 0) {
            return false
        }
        const validDomainExts: string[] = ['com', 'int', 'net', 'org', 'edu', 'gov', 'ca', 'uk', 'nl', 'au']
        const splits = lowerVersion.split(/[./]/)
        for (const ext of validDomainExts) {
            for (const split of splits) {
                if (ext === split) {
                    return true;
                }
            }
        }
        return false;
        /*
        Might be better but make uniform across platorms for now
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
        */
    }


    const isValidHex = (item: IItem): boolean => {
        return item.content.search(/^#(?:[0-9a-fA-F]{3}){1,2}$/) >= 0
    }

    const delay = (ms: number) => {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    return { currentTimestamp, fireId, hasNoSpecialChars, validURL, isValidHex, delay }
}