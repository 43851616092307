import { DialogType, Stack } from '@fluentui/react'
import { Image, Text } from '@fluentui/react';
import PRDialog, { IDialogButtonType } from '../components/prDialog'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../assets/styles'
import { usePremiumDialogState, usePremiumState } from '../shared/stores/stores'
import appStoreIcon from '../assets/app_store_icon.png'
import playStoreIcon from '../assets/play_store_icon.png'

export const PremiumGuardDialog = () => {

    const { t } = useTranslation()
    const styles = useStyles()
    const { isPremium } = usePremiumState()
    const { message, hidePremiumDialog } = usePremiumDialogState()

    const onLearnMorePremiumDialog = () => {
        window.open('https://dmystapp.com', '_blank')
    }
    
    const onDismiss = () => {
        hidePremiumDialog()
    }

    const onGooglePlayLink = () => {
        window.open('https://play.google.com/store/apps/details?id=com.cmak.dmyst', '_blank') 
    }

    const onAppStoreLink = () => {
        window.open('https://apps.apple.com/us/app/dmyst/id1508792094', '_blank')
    }

    const premiumMessage = () => {
        const message1 = t('premium_tagline')
        const message2 = t('feature_one_sub_sub')
        if (message != null && message.length > 0) {
            return (
                <div>
                    <Text style={{fontSize: 14, fontWeight: 600, lineHeight: 1.5, color: styles.prColors.primaryColor.color()}}>{message}</Text>
                    <br></br><br></br>
                    <Text style={{fontSize: 14, fontWeight: 400, lineHeight: 1.5, color: styles.prColors.textColor.color()}}>{message1}</Text>
                    <br></br><br></br>
                    <Text style={{fontSize: 14, fontWeight: 400, lineHeight: 1.5, color: styles.prColors.textColor.color()}}>{message2}</Text>
                </div>
            )
        }
        return (
            <div>
                <Text style={{fontSize: 14, fontWeight: 400, lineHeight: 1.5, color: styles.prColors.textColor.color()}}>{message1}</Text>
                <br></br><br></br>
                <Text style={{fontSize: 14, fontWeight: 400, lineHeight: 1.5, color: styles.prColors.textColor.color()}}>{message2}</Text>
            </div>
        )
    }

    return (
        <PRDialog hidden={false} type={DialogType.normal} title={'Dmyst Premium'} subText={''} onDismiss={onDismiss} cancelButton={{text: t('general_cancel'), onClick: onDismiss}} actionButton={{text: t('purchase_learn_more'), onClick: onLearnMorePremiumDialog, type: IDialogButtonType.Primary}}>
                {premiumMessage()}
                <br></br><br></br>
                <Text style={{fontSize: 14, fontWeight: 400, lineHeight: 1.5, color: styles.prColors.textColor.color()}}>{t('premium_web_description')}</Text>
                <Stack style={{alignItems: 'center', justifyContent: 'center', marginBottom: 40, marginTop: 40}} horizontal={true} tokens={{childrenGap: 30}}>
                    <Image onClick={onAppStoreLink} src={appStoreIcon} style={{cursor: 'pointer', width: 60, height: 60}}/>
                    <Image onClick={onGooglePlayLink} src={playStoreIcon} style={{cursor: 'pointer', width: 60, height: 60}}/>
                </Stack>
                {isPremium ? 
                <div style={{backgroundColor: styles.colors.themePrimary, textAlign: 'center', padding: 3}}><Text style={{color: '#ffffff'}}>{t('purchase_premium_activated')}</Text></div> : null}
            </PRDialog> 
    )
}