import { CSSProperties, useEffect, useState } from 'react';
import app_logo from '../assets/app_logo.png';
import appStoreIcon from '../assets/app_store_icon.png'
import playStoreIcon from '../assets/play_store_icon.png'
import { Text, Stack, Image, Link } from '@fluentui/react'
import { Styles } from '../assets/styles'
import LoginButton from '../components/loginButton'
import {isMobile} from 'react-device-detect';
import { Mode, useLightSwitch } from 'use-light-switch'
import { useTranslation } from 'react-i18next';
import FullScreenLoadingView from './fullScreenLoadingView';
import { container } from 'tsyringe';
import { LoadingType, RootDisplayType } from '../shared/stores/types';
import { useRootDisplayState } from '../shared/stores/stores';
import { useUtils } from '../shared/utils/utils';
import { AuthService } from '../shared/services/authService';
import { useLogin } from '../shared/viewModel/useLogin';
import { ConsoleLoggerService } from '../shared/services/consoleLoggerService';

const Login = () => {

    const { t } = useTranslation()
    const consoleLogger = container.resolve(ConsoleLoggerService)
    const authService = container.resolve(AuthService)
    const [isCheckingUser, setIsCheckingUser] = useState(true)
    const setRootDisplayType = useRootDisplayState(state => state.setRootDisplayType);
    const { loginWithGoogle, loginWithApple, finalizeLogin } = useLogin()
    const { delay } = useUtils()

    useEffect(() => {
        restoreLogin()
    }, [])

    const restoreLogin = async () => {
        try {
            await delay(1000) // for drama
            if (authService.user == null) {
                setIsCheckingUser(false)
            } else {
                await finalizeLogin()
                setRootDisplayType(RootDisplayType.dashboard)
            }
        } catch (e) {
            consoleLogger.error('Restore login error', e)
            setIsCheckingUser(false)
        }
    }

    const googleLoginAction = async () => {
        setIsCheckingUser(true)
        try {
            await loginWithGoogle()
            setRootDisplayType(RootDisplayType.dashboard)
        }
        catch (e) {
            consoleLogger.error('Google login error', e)
            setIsCheckingUser(false)
        }
    }

    const appleLoginAction = async () => {
        setIsCheckingUser(true)
        try {
            await loginWithApple()
            setRootDisplayType(RootDisplayType.dashboard)
        }
        catch (e) {
            consoleLogger.error('Apple login error', e)
            setIsCheckingUser(false)
        }
    }

    const onGooglePlayLink = () => {
        window.open('https://play.google.com/store/apps/details?id=com.cmak.dmyst', '_blank') 
    }

    const onAppStoreLink = () => {
        window.open('https://apps.apple.com/us/app/dmyst/id1508792094', '_blank')
    }

    const mode = useLightSwitch()
    const useDark = mode === Mode.Dark
    const styles = new Styles(useDark)
    const textColor = styles.prColors.textColor.color()
    const titleStyle: CSSProperties = {...{color: styles.colors.white}, ...title, ...{fontFamily: 'Sansation'}}
    const descriptionStyle: CSSProperties = {...styles.whiteTextColor, fontSize: 18, maxWidth: '380px', textAlign: 'center'}
    const termsStyle: CSSProperties = {...{color: styles.colors.neutralLight, fontSize: 16}}
    const getStartesStyle: CSSProperties = {...{color: textColor}, ...getStarted}
    const dontForgetStyle: CSSProperties = {...{color: textColor}, ...{fontSize: 18}}
    const rightContainer: CSSProperties = {
        height: '100vh',
        backgroundColor: styles.prColors.menuBackgroundColor.color()
    };

    const renderLogin = () => {
        return (
            <div className='ms-Grid' dir='ltr'>
                <div className='ms-Grid-row'>
                    <div className="ms-Grid-col ms-sm12 ms-lg6" style={zeroPadding}>
                        <div style={leftContainer}>
                            <div style={stack}>
                                <Stack horizontalAlign={'center'} style={stack} tokens={{childrenGap: 50}}>
                                    <Stack horizontalAlign={'center'} tokens={{childrenGap: 15}}>
                                        <Image src={app_logo} width={100} height={100}/>
                                        <Text style={titleStyle}>
                                            Dmyst
                                        </Text>
                                    </Stack>
                                    <Stack horizontalAlign={'center'} tokens={{childrenGap: 5}}>
                                        <Text style={descriptionStyle}>
                                            {t('login_sub_text')}
                                        </Text>
                                    </Stack>
                                </Stack>
                            </div>
                            <div className='ms-Grid-row' style={alignBottom}>
                                <div className="ms-Grid-col ms-sm12 ms-lg6">
                                    <div style={terms}>
                                        <Link style={termsStyle} href={'../terms.html'} target={'_blank'}>
                                            {t('login_terms')}
                                        </Link>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-lg6">
                                    <div style={privacy}>
                                        <Link style={termsStyle} href={'../privacy.html'} target={'_blank'}>
                                            {t('login_privacy')}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-lg6" style={zeroPadding}>
                        <div style={rightContainer}>
                            <div>
                                <Stack style={loginStack} tokens={{childrenGap: 80}}>
                                    {isMobile ? undefined : (
                                        <Stack style={{alignItems: 'center'}} tokens={{childrenGap: 20}}>
                                            <Text style={getStartesStyle}>
                                                {t('keyboard_get_started')}
                                            </Text>
                                            <LoginButton type={'google'} onClick={googleLoginAction} />
                                            <LoginButton type={'apple'} onClick={appleLoginAction} />
                                        </Stack>
                                    )}
                                    <Stack style={{alignItems: 'center'}} tokens={{childrenGap: 20}}>
                                        <Text style={dontForgetStyle}>
                                            {isMobile ? t('login_apps_mobile_message') : t('login_apps_desktop_message')}
                                            
                                        </Text>
                                        <Stack style={{alignItems: 'center', justifyContent: 'center'}} horizontal={true} tokens={{childrenGap: 30}}>
                                            <Image onClick={onAppStoreLink} src={appStoreIcon} style={{width: 60, height: 60, cursor: 'pointer'}}/>
                                            <Image onClick={onGooglePlayLink} src={playStoreIcon} style={{width: 60, height: 60, cursor: 'pointer'}}/>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            {isCheckingUser && <FullScreenLoadingView title={`${t('general_loading')}...`} type={LoadingType.Login} />}
            {!isCheckingUser && renderLogin()}
        </div>
    );
}

export default Login

const loginStack: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90vh'
};

const stack: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '60vh'
};

const zeroPadding: CSSProperties = {
    padding: '0px'
};

const leftContainer: CSSProperties = {
    height: '100vh',
    background: 'linear-gradient(to bottom right, rgba(62,140,255,1) 0%, rgba(129,49,244,1) 70%, rgba(129,49,244,1) 100%)'
};

const alignBottom: CSSProperties = {
    position: 'absolute',
    width: '100%',
    bottom: 120,
    left: 20
};

const terms: CSSProperties = {
    position: 'absolute',
    right: 20
};

const privacy: CSSProperties = {
    position: 'absolute',
    left: 20
};

const getStarted: CSSProperties = {
    fontSize: '32px',
    fontWeight: 400
}

const title: CSSProperties = {
    fontSize: '42px',
    fontWeight: 500
};